import React from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';

const withRouterMatch = (WrappedComponent) => (props) => {
  const params = useParams();
  const match = { params };
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <WrappedComponent match={match} {...props} />;
};

const connectWithRouterMatch = (mapStateToProps, mapDispatchToProps, mergeProps, options) => (
  (WrappedComponent) => {
    const ConnectedComponent = connect(
      mapStateToProps,
      mapDispatchToProps,
      mergeProps,
      options,
    )(WrappedComponent);
    return withRouterMatch(ConnectedComponent);
  }
);

export default connectWithRouterMatch;
