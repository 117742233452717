import React from 'react';
import { ReactComponent as Student } from '../images/student.svg';
import './StudentAvatar.scss';

const getNum = () => Math.floor(Math.random() * 3) + 1;

const getClasses = () => `student-avatar boy-${getNum()} girl-${getNum()}`;

const StudentAvatar = () => <Student className={getClasses()} />;

export default StudentAvatar;
