import { Map } from 'immutable';

const getTokenFromUrl = () => {
  const searchString = window.location.search;
  if (searchString) {
    const allParts = searchString
      .slice(1)
      .split('&')
      .map((pair) => pair.split('='));
    const tokenParts = allParts.find((parts) => parts[0] === 'token');

    return tokenParts && tokenParts[1];
  }
  return undefined;
};

export const getAuthToken = ({ authentication }) => (
  getTokenFromUrl() || authentication.get('authToken') || ''
);

export const getSiteToken = ({ authentication }, siteId) => (
  authentication.getIn(['siteTokens', siteId, 'token'])
);

export const getSiteTokens = ({ authentication }) => (
  authentication.get('siteTokens')
    .map((site) => site.get('token'))
    .values()
);

export const getAllTokens = (state) => {
  const authToken = getAuthToken(state);
  const siteTokens = getSiteTokens(state);
  return [authToken, ...siteTokens];
};

export const getSiteError = ({ authentication }, siteId) => (
  authentication.getIn(['siteTokens', siteId, 'error'])
);

export const getActiveCourse = ({ authentication }) => (
  authentication.get('activeCourse') || Map()
);

export const getPreferences = ({ authentication }) => (
  authentication.getIn(['user', 'preferences']) || Map()
);

export const getName = ({ authentication }) => {
  const firstName = authentication.getIn(['user', 'first_name']);
  const lastName = authentication.getIn(['user', 'last_name']);
  return firstName
    ? `${firstName} ${lastName}`
    : '';
};

export const isTileView = ({ authentication }) => (
  authentication.getIn(['user', 'preferences', 'mod_tiles']) !== false
);

export const isViewAsStudent = ({ authentication }) => !!authentication.get('viewAsStudent');

export const isStudent = ({ authentication }) => !!getAuthToken({ authentication })
  && authentication.get('userRole') === 'student'
  && !authentication.get('user').isEmpty();

export const isTeacher = ({ authentication }) => !!getAuthToken({ authentication })
  && authentication.get('userRole') === 'teacher';

export const isLinkedToICEV = ({ authentication }) => (
  !!(authentication.getIn(['user', 'icev_account_id']) || authentication.get('teacher_context'))
);

export const icevOrgExists = ({ authentication }) => !!authentication.get('icev_org_exists');

export const isNewICEVTeacher = ({ authentication }) => (
  !!authentication.get('is_new_icev_teacher')
);

export const isActiveSince = ({ authentication }, timeToCompare) => {
  const lastActiveTime = authentication.getIn(['lastActivity', 'time']) || 0;
  return lastActiveTime > timeToCompare;
};

export const isActiveSession = (state) => {
  const oneHourAgo = Date.now() - 3600000;

  return isActiveSince(state, oneHourAgo);
};

export const hideScreenOrientation = ({ authentication }) => (
  authentication.getIn(['user', 'preferences', 'warn_orient'])
);

export const hideScreenOrientationTemporary = ({ authentication }) => (
  authentication.get('hideScreenOrientationWarning')
);

export const hideScreenOrientationWarning = (state) => (
  !isStudent(state)
  || hideScreenOrientation(state)
  || hideScreenOrientationTemporary(state)
);

export const getTeacherContext = ({ authentication }) => (
  authentication.get('teacher_context') || ''
);
