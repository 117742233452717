import React from 'react';
import './GettingStartedVideo.scss';

const GettingStartedVideo = () => (
  <span
    className="wistia_embed wistia_async_bi71tt2svv popover=true popoverAnimateThumbnail=true videoThumbnail"
  />

);

export default GettingStartedVideo;
