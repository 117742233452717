import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Map } from 'immutable';
import Icon from './Icon';
import { formatAssessmentDate } from '../utils/date';

const CourseListItem = ({ courseData }) => {
  const courseId = courseData.get('course_id');
  const siteId = courseData.get('site_id');
  const title = courseData.get('course_title');
  const instructor = courseData.get('faculty_name');
  const avatar = courseData.get('faculty_avatar_url');
  const enrolled = courseData.get('enrolled_date');
  const lastLogon = courseData.get('last_logon');
  return (
    <article className="course" key={`course_${courseId}`}>
      <Link to={`/site/${siteId}/course/${courseId}/`}>
        {title}
      </Link>
      <h2>{title}</h2>
      <div className="course-images" aria-hidden>
        <div className="background" />
        <div className="avatar">
          {avatar && avatar.indexOf('/') > 0 // the 'missing' avatar has an absolute path
            ? <img src={avatar} alt={instructor} />
            : <Icon text="avatar" />}
        </div>
      </div>
      <div className="details">
        <div>
          <span className="label">Instructor</span>
          <p>{instructor}</p>
        </div>
        <div>
          <span className="label">Last Sign in</span>
          <p>{lastLogon ? formatAssessmentDate(lastLogon) : 'Never'}</p>
        </div>
        <div>
          <span className="label">Enrolled</span>
          <p>{formatAssessmentDate(enrolled)}</p>
        </div>
      </div>
    </article>
  );
};

CourseListItem.propTypes = {
  courseData: PropTypes.instanceOf(Map).isRequired,
};

export default CourseListItem;
