import React from 'react';
import PropTypes from 'prop-types';
import './ProgressBar.scss';

const ProgressBar = ({ max, value }) => {
  // calculate width of progress bar
  const percent = value ? `${((value / max) * 100).toFixed(1)}%` : '0';

  return (
    <div className="progressBar">
      <div className="bar" style={{ width: percent }} />
    </div>
  );
};

ProgressBar.propTypes = {
  max: PropTypes.number,
  value: PropTypes.number,
};

ProgressBar.defaultProps = {
  max: 100,
  value: 0,
};

export default ProgressBar;
