import React from 'react';
import PropTypes from 'prop-types';
import { OrderedMap } from 'immutable';
import UnitListItem from './UnitListItem';
import './ItemLists.scss';
import { getUnitCloseReason, getUnitIsClosed } from '../utils/pacing';

const UnitList = ({
  baseUrl,
  moduleIsClosed,
  units,
}) => (
  <>
    <div className="itemContainer">
      <table className="units">
        <thead>
          <tr className="header">
            <th><h3>Units</h3></th>
            <th><h3>Progress</h3></th>
            <th><h3>Grade</h3></th>
          </tr>
        </thead>
        <tbody>
          {units.map((data, key) => {
            const isClosed = getUnitIsClosed(data, moduleIsClosed);
            const closeReason = getUnitCloseReason(data);
            return (
              <UnitListItem
                /* eslint-disable-next-line react/no-array-index-key */
                key={`module_${key}`}
                baseUrl={baseUrl}
                closeReason={closeReason}
                data={data}
                hasIndex={!!data.get('index')}
                isClosed={isClosed}
              />
            );
          }).toList()}
        </tbody>
      </table>
    </div>
  </>
);

UnitList.propTypes = {
  baseUrl: PropTypes.string,
  moduleIsClosed: PropTypes.bool,
  units: PropTypes.instanceOf(OrderedMap).isRequired,
};

UnitList.defaultProps = {
  baseUrl: '',
  moduleIsClosed: false,
};

export default UnitList;
