import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Prompt from './Prompt';
import Icon from './Icon';
import Checkbox from './Checkbox';
import './ScreenWarningModal.scss';

const ScreenWarningModal = ({
  show, closeModal, closeModalPreferences,
}) => {
  const [checked, setChecked] = useState();
  if (show) {
    const onClickOk = checked ? closeModalPreferences : closeModal;
    return (
      <Prompt title="Heads up!" type="orientation-warning" onClickClose={() => closeModal()}>
        <div className="content">
          <Icon text="rotation" className="rotation" />
          <p className="description">
            For the best experience we recommend viewing this application in landscape mode.
          </p>
          <Checkbox
            name="hide"
            label="Don’t show this again"
            checked={checked}
            onChange={({ target }) => setChecked(target.checked)}
          />
          <button type="button" onClick={onClickOk} className="primary">OK</button>
        </div>
      </Prompt>
    );
  }
  return null;
};

ScreenWarningModal.propTypes = {
  show: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  closeModalPreferences: PropTypes.func.isRequired,
};

export default ScreenWarningModal;
