import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { differenceInMilliseconds } from 'date-fns';
import { replaceEventTokens } from '../utils/formatting';
import unplannedImage from '../images/event-unplanned.svg';
import plannedImage from '../images/event-planned.svg';
import AESLogo from '../components/AESLogo';
import '../components/LandingMessage.scss';
import { requestEventData } from '../redux/PlatformStatus/actions';

const LandingMessage = ({ data, reloadEvents }) => {
  const {
    landingTitle = '',
    landingBody = '',
    learnMoreUrl = '',
    startDate,
    endDate,
    isUnexpected,
  } = data;
  const bodyHTML = replaceEventTokens(landingBody, startDate, endDate);
  const linkText = isUnexpected
    ? 'Read full status updates'
    : 'Read the full announcement';
  const imgSrc = isUnexpected
    ? unplannedImage
    : plannedImage;

  useEffect(() => {
    const delta = differenceInMilliseconds(endDate, Date.now());
    if (delta > 0) {
      const timeoutId = setTimeout(reloadEvents, delta);
      return () => clearTimeout(timeoutId);
    }
    return () => {
      // do nothing if no timeout set
    };
  }, [endDate]);

  return (
    <div className={`landing-message ${isUnexpected ? 'unexpected' : ''}`}>
      <AESLogo />
      <img className="event-type" src={imgSrc} alt="" />
      <h1>{landingTitle}</h1>
      {/* eslint-disable-next-line react/no-danger */}
      <p dangerouslySetInnerHTML={{ __html: bodyHTML }} />
      { learnMoreUrl
        ? (
          <a href={learnMoreUrl} target="_blank" rel="noopener noreferrer">
            {linkText}
          </a>
        )
        : null }
    </div>
  );
};

LandingMessage.propTypes = {
  data: PropTypes.shape({
    landingTitle: PropTypes.string,
    landingBody: PropTypes.string,
    learnMoreUrl: PropTypes.string,
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
    isUnexpected: PropTypes.bool,
  }).isRequired,
  reloadEvents: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({ reloadEvents: () => dispatch(requestEventData()) });

export default connect(undefined, mapDispatchToProps)(LandingMessage);
