import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import './Prompt.scss';
import Icon from './Icon';
import FocusTrap from './FocusTrap';

const Prompt = ({
  children,
  title,
  onClickClose,
  type,
  primaryBtnText,
  primaryBtnCB,
  secondaryBtnText,
  secondaryBtnCB,
}) => {
  const modalRoot = document.getElementById('modal_root');

  return createPortal(
    <FocusTrap>
      <div
        aria-labelledby="modal_title"
        aria-modal
        className={`modal prompt ${type}`}
      >
        <div className={`modal-body ${type}`}>
          <section>
            <h3 id="modal_title">{title}</h3>
            {children}
            <div className="actions">
              {primaryBtnCB && (
              <button
                type="button"
                className="button primary"
                onClick={primaryBtnCB}
              >
                {primaryBtnText}
              </button>
              )}
              {secondaryBtnCB && (
                <button
                  type="button"
                  className="cancel"
                  onClick={secondaryBtnCB}
                >
                  {secondaryBtnText}
                </button>
              )}
            </div>
            {onClickClose && (
              <button
                type="button"
                className="icon close"
                onClick={onClickClose}
              >
                <Icon text="close" />
              </button>
            )}
          </section>
        </div>
      </div>
    </FocusTrap>,
    modalRoot,
  );
};

Prompt.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClickClose: PropTypes.func,
  primaryBtnText: PropTypes.string,
  secondaryBtnText: PropTypes.string,
  primaryBtnCB: PropTypes.func,
  secondaryBtnCB: PropTypes.func,
};

Prompt.defaultProps = {
  type: '',
  title: '',
  onClickClose: undefined,
  primaryBtnText: null,
  secondaryBtnText: null,
  primaryBtnCB: null,
  secondaryBtnCB: null,
};

export default Prompt;
