import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { passwordResetRequest } from '../redux/Authentication/actions';
import Input from '../components/Input';
import { findError } from '../utils/errors';
import AESLogo from '../components/AESLogo';

class TeacherHelp extends Component {
  constructor(props) {
    super(props);
    this.state = { email: '' };
  }

  reset(e) {
    const { passwordReset } = this.props;
    const { email } = this.state;
    e.preventDefault();
    passwordReset(email);
  }

  render() {
    const { email } = this.state;
    const { error, done } = this.props;

    return (
      <>
        <AESLogo />
        <div className="sign-in-form recover-password">
          { done
            ? (
              <p className="helptext">
                You have been sent a password reset email. Check
                your SPAM folder if you don&apos;t get the email.
              </p>
            )
            : (
              <form onSubmit={(e) => this.reset(e)}>
                <h1>Forgot your Password?</h1>
                <h2>
                  We’ll send you an email so you can create a new password.
                </h2>
                <p className="helptext">
                  Please check your SPAM folder if you don’t see the email in your inbox.
                </p>
                <Input
                  name="email"
                  id="email"
                  error={error.message}
                  value={email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                  placeholder="Your Email"
                />
                <button id="sign-in" type="submit" className="primary">
                  Reset my Password
                </button>
                <Link className="link" to="/teacher">Return to Sign In</Link>
              </form>
            )}
        </div>
      </>
    );
  }
}

TeacherHelp.propTypes = {
  passwordReset: PropTypes.func.isRequired,
  done: PropTypes.bool,
  error: PropTypes.string,
};

TeacherHelp.defaultProps = {
  error: null,
  done: false,
};

const mapDispatchToProps = (dispatch) => ({
  passwordReset: (email) => dispatch(passwordResetRequest(email)),
});

const mapStateToProps = ({ authentication }) => ({
  error: findError(authentication.get('resetError')),
  done: authentication.get('resetSuccess'),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeacherHelp);
