import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Icon from './Icon';
import Tooltip from './Tooltip';
import './ModuleListItem.scss';

const ModuleListItemRow = ({
  baseUrl,
  closeReason,
  completed,
  due,
  dueDate,
  gradeInProgress,
  gradeFinal,
  icon,
  isClosed,
  moduleKey,
  moduleTitle,
  statusClass,
  tipColor,
  type,
}) => (
  <tr className={`moduleItem ${statusClass}`}>
    <td className="unitTitle">
      {isClosed && <Tooltip copy={closeReason} height="40px" />}
      <div className="icon">
        <Icon text={icon} isButton />
      </div>
      {isClosed
        ? (
          <>
            <span className="title">
              {moduleTitle}
            </span>
            <span className={`tip ${tipColor}`}>
              {closeReason}
            </span>
          </>
        )
        : (
          <h3>
            <Link to={`${baseUrl}/${type}/${moduleKey}/`}>{moduleTitle}</Link>
            { statusClass === 'due-passed'
              ? (<span className={`tip ${tipColor}`}>{closeReason}</span>)
              : null }
          </h3>
        )}
    </td>
    <td className="completed">
      <p>{completed}</p>
    </td>
    <td className="due-date">
      <p>{due ? dueDate : 'None'}</p>
    </td>
    <td className="in-progress">
      <p>{gradeInProgress}</p>
    </td>
    <td className="final">
      <p>{gradeFinal}</p>
    </td>
  </tr>
);

ModuleListItemRow.propTypes = {
  baseUrl: PropTypes.string,
  closeReason: PropTypes.string,
  completed: PropTypes.string,
  due: PropTypes.string,
  dueDate: PropTypes.string.isRequired,
  gradeInProgress: PropTypes.string.isRequired,
  gradeFinal: PropTypes.string.isRequired,
  icon: PropTypes.string,
  isClosed: PropTypes.bool,
  moduleKey: PropTypes.string.isRequired,
  moduleTitle: PropTypes.string.isRequired,
  statusClass: PropTypes.string,
  tipColor: PropTypes.string,
  type: PropTypes.string.isRequired,
};

ModuleListItemRow.defaultProps = {
  baseUrl: '.',
  closeReason: '',
  completed: '',
  due: '',
  icon: '',
  isClosed: false,
  statusClass: '',
  tipColor: '',
};

export default ModuleListItemRow;
