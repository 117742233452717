import {
  all,
  call,
  cancel,
  delay,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLeading,
} from 'redux-saga/effects';
import { List, Map } from 'immutable';
import { PURGE } from 'redux-persist';
import {
  AUTHENTICATION_AUTHENTICATE_REQUEST,
  AUTHENTICATION_AUTHENTICATE_SUCCESS,
  AUTHENTICATION_SITE_DATA_REQUEST,
  AUTHENTICATION_SET_ACTIVE_COURSE,
  AUTHENTICATION_TEACHER_SITE_DATA_REQUEST,
  AUTHENTICATION_ENROLL_STUDENT,
  AUTHENTICATION_ENROLL_SUCCESS,
  AUTHENTICATION_VERIFY_EMAIL_REQUEST,
  AUTHENTICATION_VERIFY_ENROLLMENT_REQUEST,
  AUTHENTICATION_LOGOUT,
  CREATE_NEW_STUDENT_REQUEST,
  UPDATE_STUDENT_REQUEST,
  CHANGE_STUDENT_PREFERENCES_REQUEST,
  PASSWORD_RESET_REQUEST,
  PASSWORD_SET_REQUEST,
  TEACHER_INVITE_REQUEST,
  LOAD_TEACHER_COURSES,
  authenticate as authenticateAction,
  authenticateFailed,
  authenticateSucceeded,
  authenticateSiteFailed,
  authenticateSiteSucceeded,
  authenticateTeacherSiteFailed,
  authenticateVerifyEmailSuccess,
  authenticateVerifyEmailError,
  authenticateVerifyEnrollmentCodeSuccess,
  authenticateVerifyEnrollmentCodeError,
  authenticateEnrollSuccess,
  authenticateEnrollError,
  createNewStudentSuccess,
  createNewStudentError,
  updateStudentError,
  updateStudentSuccess,
  passwordResetError,
  passwordResetSuccess,
  PasswordSetErrors,
  passwordSetSuccess,
  acceptTeacherInviteError,
  updateStudentPreferencesSuccess,
  updateStudentPreferencesError,
  authenticateEnrollReset,
  authenticateEnrollStudent,
  setActiveCourse,
  setActiveCourseSucceeded,
  logout,
  finishLogout,
  loadTeacherCourses,
  loadTeacherCoursesError, setIcevOrgExists,
} from '../Authentication/actions';
import {
  clearData,
  logEvent,
  receivedSites,
  requestCourseData,
  requestCourseDataSucceeded,
  requestLockedAssessmentData,
  teacherCoursesSucceeded,
} from '../Data/actions';
import {
  acceptInvite,
  authenticate,
  authenticateSite,
  authenticateSiteTeacher,
  changeStudentPreferences,
  checkEmailAvailable,
  createNewStudent,
  deleteToken,
  enrollVerify,
  enrollStudent,
  refreshToken,
  resetPassword,
  setPassword,
  updateStudent,
  loadTeacherCoursesData,
} from '../../services/API';
import {
  getAuthToken,
  isViewAsStudent,
  getActiveCourse,
  getSiteToken,
  getSiteTokens,
  getAllTokens,
  isActiveSession,
  isActiveSince,
  getPreferences,
} from '../Authentication/selectors';
import { snakeToCamel } from '../../utils/formatting';
import { isValidUsername, invalidEmailChars } from '../../utils/errors';
import { clearView, redirectTo } from '../View/actions';
import { launchTeacherUI } from '../../services/launch';
import { getAssessmentMetadata } from '../View/selectors';

export function* doAuthenticateViewAsStudent(authToken, siteId, courseId) {
  if (siteId) {
    yield put(authenticateSiteSucceeded(siteId, authToken));
    yield put(requestCourseData(siteId, courseId));
  } else {
    yield put(authenticateSucceeded(authToken, Map({ viewAsStudent: true }), 'teacher-as-student'));
  }
}

export function* doLoadTeacherCourses({ siteIds }) {
  let siteCourses = Map();
  for (let i = 0; i < siteIds.size; i += 1) {
    const siteId = siteIds.get(i);
    const siteToken = yield select(getSiteToken, siteId);
    try {
      const courses = yield call(loadTeacherCoursesData, siteToken);
      siteCourses = siteCourses.set(siteId, Map({ teacherCourses: courses }));
    } catch (exception) {
      yield put(loadTeacherCoursesError('Error contacting authentication server'));
    }
  }
  yield put(teacherCoursesSucceeded(siteCourses));
}

export function* doAuthenticate({
  email,
  password,
  userRole,
  provider,
  providerToken,
  authToken: token,
  enrollmentCode,
  courseMigration,
}) {
  const viewAsStudent = yield select(isViewAsStudent);
  if (viewAsStudent) {
    yield doAuthenticateViewAsStudent(token);
    return;
  }

  if (!token && !providerToken) {
    if (!isValidUsername(email, userRole)) {
      yield put(authenticateFailed('Please enter a valid e-mail or username.', 403));
      return;
    }
    if (!password) {
      yield put(authenticateFailed('Please enter a valid password.', 403));
      return;
    }
  }

  try {
    const result = yield call(
      authenticate,
      email,
      password,
      userRole,
      provider,
      providerToken,
      token,
    );
    const authToken = result.get('auth_token');
    const error = result.get('error');
    const user = result.get('user');
    const resultRole = result.get('user_role') === 'STUDENT'
      ? 'student'
      : 'teacher';

    if (user) {
      // eslint-disable-next-line camelcase
      window.Rollbar.configure({ payload: { person: { id: user.get('id') } } });
    }

    const roles = (result.get('roles') || List()).filter((role) => (
      role.get('site_name') !== 'System Administration' && role.get('site_active')));

    const expiredRoles = (result.get('roles') || List()).filter(
      (role) => (role.get('site_name') !== 'System Administration' && !role.get('site_active')),
    ).map((r) => r.get('role_type'));

    if (error) {
      yield put(authenticateFailed(error, result.get('code')));
    } else if (!authToken) {
      yield put(authenticateFailed('Authentication Failed', result.get('code')));
    } else if (
      roles.size === 1
      && roles.getIn([0, 'role_type']) === 'teacher'
      && !courseMigration
    ) {
      // Immediate redirect if there is only one role
      // AND it's a teacher role not attempting Course Migration
      const siteId = roles.getIn([0, 'site_id']);
      const siteResult = yield call(authenticateSiteTeacher, siteId, authToken);
      const siteToken = siteResult.get('auth_token');
      const beta = siteResult.getIn(['user', 'beta']);
      launchTeacherUI(siteToken, beta);
    } else {
      const sites = roles.reduce((acc, role) => {
        const roleName = role.get('role_type');

        if (roleName !== resultRole) {
          return acc;
        }

        const id = role.get('site_id').toString();
        const name = role.get('site_name');
        const lastLogon = role.get('last_logon');
        const product = role.get('product');
        const courseId = role.get('course_id');
        const trial = role.get('trial');
        const site = acc.get(id) || Map({
          id, lastLogon, product, name, trial, courses: Map(),
        });
        const roleSite = site
          .update('courses', (courses) => (
            roleName === 'student'
              ? courses.set(`${courseId}`, role)
              : courses
          ));

        return acc.set(`${id}`, roleSite);
      }, Map());

      const teacherRoles = roles.filter((role) => role.get('role_type') === 'teacher');

      yield put(authenticateSucceeded(authToken, user, resultRole));
      yield put(receivedSites(sites, expiredRoles));
      // Course Migration - course data loading
      if (teacherRoles.size > 0 && courseMigration) {
        const siteIds = sites.toList()
          .map((site) => site.get('id'));

        for (let i = 0; i < siteIds.size; i += 1) {
          const siteId = siteIds.get(i);
          const siteResult = yield call(authenticateSiteTeacher, siteId, authToken);
          const siteToken = siteResult.get('auth_token');
          const orgMatch = siteResult.getIn(['site', 'icev_organization_id']);
          yield put(authenticateSiteSucceeded(siteId, siteToken));
          if (orgMatch) {
            yield put(setIcevOrgExists());
          }
        }
        yield put(loadTeacherCourses(siteIds));
      }
      if (enrollmentCode) {
        yield put(authenticateEnrollStudent({ enrollmentCode }));
      }
    }
  } catch (exception) {
    yield put(authenticateFailed('Error contacting authentication server'));
  }
}

export function* doAuthenticateSite({ siteId, courseId }) {
  try {
    const authToken = yield select(getAuthToken);
    if (!authToken) {
      yield put(authenticateFailed('No Authentication Token', 403));
    } else {
      const viewAsStudent = yield select(isViewAsStudent);

      if (viewAsStudent) {
        yield doAuthenticateViewAsStudent(authToken, siteId, courseId);
        return;
      }

      const result = yield call(authenticateSite, siteId, authToken);
      const error = result.get('error');
      const siteToken = result.get('auth_token');
      const lockedAssessmentId = result.getIn(['user', 'locked_quiz_id']);

      if (error) {
        yield put(authenticateSiteFailed(siteId, error, result.get('code')));
      } else {
        // TODO: conditionally initialize DataDog based on some flag from the auth response, TBD
        // DataDog.initialize(result.getIn(['user', 'id']));
        // Actions for the New Student UI
        yield put(authenticateSiteSucceeded(siteId, siteToken));
        yield put(setActiveCourse(siteId, courseId));
        yield put(requestCourseData(siteId, courseId));
        if (lockedAssessmentId) {
          yield put(requestLockedAssessmentData(siteId, lockedAssessmentId));
        }
      }
    }
  } catch (exception) {
    yield put(authenticateFailed('Error contacting authentication server'));
  }
}

export function* doAuthenticateEnroll({ siteId, courseId }) {
  try {
    const authToken = yield select(getAuthToken);
    if (!authToken) {
      yield put(authenticateFailed('No Authentication Token', 403));
    } else {
      const result = yield call(authenticateSite, siteId, authToken);
      const error = result.get('error');
      const siteToken = result.get('auth_token');
      const roles = result.get('roles') || List();
      const course = roles.find((role) => role.get('course_id') === courseId);

      if (error) {
        yield put(authenticateSiteFailed(siteId, error, result.get('code')));
      } else {
        yield put(requestCourseDataSucceeded(`${siteId}`, `${courseId}`, course));
        yield put(authenticateSiteSucceeded(siteId, siteToken));
        yield delay(3000);
        yield put(authenticateEnrollReset());
        yield put(redirectTo(`/site/${siteId}/course/${courseId}/`));
      }
    }
  } catch (exception) {
    yield put(authenticateFailed('Error contacting authentication server'));
  }
}

export function* doAuthenticateTeacherSite({ siteId, courseId }) {
  try {
    const authToken = yield select(getAuthToken);
    if (!authToken) {
      yield put(authenticateFailed('No Authentication Token', 403));
    } else {
      const result = yield call(authenticateSiteTeacher, siteId, authToken);
      const error = result.get('error');
      const siteAuthToken = result.get('auth_token');
      const beta = result.getIn(['user', 'beta']);
      launchTeacherUI(siteAuthToken, beta, courseId);
      if (error) {
        yield put(authenticateTeacherSiteFailed(siteId, error, result.get('code')));
      }
    }
  } catch (exception) {
    yield put(authenticateFailed('Error contacting authentication server'));
  }
}

export function* doEmailVerify({ email }) {
  const invalidChars = invalidEmailChars(email);

  if (invalidChars) {
    yield put(authenticateVerifyEmailError(`The following characters are not allowed: ${invalidChars}`));
    return;
  }

  try {
    const available = yield call(checkEmailAvailable, email);
    if (!email) {
      yield put(authenticateVerifyEmailError('Please enter a valid e-mail or username'));
      return;
    }
    yield put(authenticateVerifyEmailSuccess(available));
  } catch (exception) {
    yield put(authenticateVerifyEmailError('Error contacting server'));
  }
}

const isValidEnrollmentCode = (enrollmentCode) => (
  !!enrollmentCode && !enrollmentCode.match(/[^a-zA-Z\d]/)
);

export function* doEnrollVerify({ enrollmentCode }) {
  if (!isValidEnrollmentCode(enrollmentCode)) {
    yield put(authenticateVerifyEnrollmentCodeError('Please enter an enrollment code.'));
    return;
  }

  try {
    const result = yield call(enrollVerify, enrollmentCode);
    const errors = result.get('errors');
    const ssoGoogle = result.get('sso_google');
    if (errors) {
      const error = errors.get('enrollment');
      yield put(authenticateVerifyEnrollmentCodeError(error));
    } else {
      yield put(authenticateVerifyEnrollmentCodeSuccess(enrollmentCode, ssoGoogle));
    }
  } catch (exception) {
    yield put(authenticateFailed('Error contacting authentication server'));
  }
}

export function* doEnrollStudent({ enrollmentCode, ssoEnroll }) {
  if (!isValidEnrollmentCode(enrollmentCode)) {
    yield put(authenticateVerifyEnrollmentCodeError('Please enter an enrollment code.'));
    return;
  }

  try {
    const authToken = yield select(getAuthToken);
    const result = yield call(enrollStudent, { authToken, enrollmentCode, ssoEnroll });
    const errors = result.get('errors');
    if (errors) {
      yield put(authenticateEnrollError(errors.get('enrollment')));
    } else {
      const siteId = result.getIn(['enrollment', 'site_id']);
      const courseId = result.getIn(['enrollment', 'course_id']);
      const enrollmentId = result.getIn(['enrollment', 'enrollment_id']);
      const token = result.get('token');
      if (token) {
        yield put(authenticateSucceeded(token));
      }
      yield put(authenticateEnrollSuccess(siteId, courseId, enrollmentId));
    }
  } catch (exception) {
    yield put(authenticateFailed('Error contacting authentication server'));
  }
}

const errorsListToMap = (errors) => (
  errors.mapKeys(snakeToCamel)
    .map((errorList) => (
      errorList.reduce((acc, errorMap) => {
        const {
          error: errorKey,
          value,
        } = errorMap.toJS();
        return acc.set(errorKey, value);
      }, Map())
    ))
);

const returnField = (error, errorKey) => {
  if (error.has('blank')) { return { type: 'blank' }; }
  if (error.has('too_short')) { return { type: 'too_short' }; }
  if (error.has('invalid')) {
    const invalidChars = error.get('invalid')
      .match(/[^\u0020-\u007e\u00a0-\u00ff]/g);

    return { type: 'invalid', value: invalidChars.join('') };
  }
  if (errorKey === 'passwordConfirmation') { return { type: 'confirmation' }; }
  return { type: '', value: '' };
};

const standardizingErrors = (errors) => {
  const errorsMap = errorsListToMap(errors);
  return errorsMap.map((err, errorKey) => (
    returnField(err, errorKey)
  ));
};

export function* doCreateNewStudent(params) {
  try {
    const result = yield call(createNewStudent, params);
    const errors = result.get('errors');
    if (errors) {
      if (errors.has('enrollment')) {
        yield put(authenticateEnrollError(errors.get('enrollment')));
        yield put(authenticateAction({ ...params, userRole: 'student' }));
      } else {
        const errorsStandardized = standardizingErrors(errors);
        if (params.password === '') {
          yield put(createNewStudentError(errorsStandardized.setIn(['password'], { type: 'blank' })));
          return;
        }
        yield put(createNewStudentError(errorsStandardized));
      }
    } else {
      const siteId = result.getIn(['enrollment', 'site_id']);
      const courseId = result.getIn(['enrollment', 'course_id']);
      const token = result.get('token');

      yield put(createNewStudentSuccess(token, siteId, courseId));
    }
  } catch (exception) {
    yield put(authenticateFailed('Error contacting authentication server'));
  }
}

export function* doUpdateStudent(params) {
  try {
    const token = yield select(getAuthToken);
    const result = yield call(updateStudent, { ...params, token });
    const errors = result.get('errors');
    if (errors) {
      const errorsStandardized = standardizingErrors(errors);
      yield put(updateStudentError(errorsStandardized));
    } else {
      yield put(updateStudentSuccess());
    }
  } catch (exception) {
    yield put(updateStudentError(Map({
      exception: 'Error updating',
    })));
  }
}

export function* doUpdateStudentPreferences(params) {
  const viewAsStudent = yield select(isViewAsStudent);
  if (viewAsStudent) {
    const { preference: { key, value } } = params;
    const preferences = yield select(getPreferences);
    yield put(updateStudentPreferencesSuccess(preferences.set(key, value)));
  } else {
    try {
      const token = yield select(getAuthToken);
      const result = yield call(changeStudentPreferences, params, token);
      const error = result.get('error');
      const preferences = result.get('preferences');
      if (!error) {
        yield put(updateStudentPreferencesSuccess(preferences));
      } else {
        yield put(updateStudentPreferencesError(error));
      }
    } catch (exception) {
      yield put(updateStudentPreferencesError(Map({
        exception: 'Error updating',
      })));
    }
  }
}

export function* doPasswordReset({ email }) {
  if (!isValidUsername(email, 'teacher')) {
    yield put(passwordResetError('Please enter a valid email address'));
    return;
  }

  try {
    const result = yield call(resetPassword, email);
    const error = result.get('error');
    if (!error) {
      yield put(passwordResetSuccess());
    } else {
      yield put(passwordResetError(error));
    }
  } catch (exception) {
    yield put(passwordResetError('Error contacting authentication server'));
  }
}

export function* doPasswordSet({
  code,
  email,
  password,
  passwordConfirmation,
}) {
  try {
    const result = yield call(setPassword, code, email, password, passwordConfirmation);
    const errors = result.get('error');
    if (errors) {
      if (typeof errors === 'string') {
        // String Error (Bad Parameter)
        yield put(PasswordSetErrors(Map({
          error: 'Illegal Parameter',
        })));
      } else {
        yield put(PasswordSetErrors(errors.map((v) => v.getIn([0, 'error']))));
      }
    } else {
      yield put(passwordSetSuccess());
    }
  } catch (exception) {
    yield put(PasswordSetErrors(Map({
      error: 'Error Contacting Authentication Server',
    })));
  }
}

export function* doTeacherInviteRequest({ code, password, passwordConfirmation }) {
  try {
    const result = yield call(acceptInvite, code, password, passwordConfirmation);
    const token = result.get('token');
    const errors = result.get('errors');
    if (token) {
      launchTeacherUI(token);
      return;
    }
    // API response is structured as list of errors for each field instead of single error
    // but there will only be one item in the list; re-mapping to match other API responses
    const flattenedErrors = errors.map((list) => list.first().get('error'));
    yield put(acceptTeacherInviteError(flattenedErrors));
  } catch (exception) {
    const errors = Map({ error: 'Illegal Parameter' });
    yield put(acceptTeacherInviteError(errors));
  }
}

export function* doPostCourseExitEvent({ siteId, courseId }) {
  const siteToken = yield select(getSiteToken, siteId);

  // skip if we don't actually have a token
  if (siteToken) {
    yield put(logEvent(siteId, courseId, 'course_exit'));
  }
}
export function* doSetActiveCourse({ siteId, courseId }) {
  const activeCourse = yield select(getActiveCourse);
  const siteToken = yield select(getSiteToken, siteId);
  const activeCourseId = activeCourse.get('courseId');

  // if courseId has changed, log events
  if (siteToken && courseId !== activeCourseId) {
    // If switching courses, first post exit event for previous course
    if (activeCourseId) {
      yield* doPostCourseExitEvent(activeCourse.toJS());
    }
    yield put(setActiveCourseSucceeded(siteId, courseId));
    yield delay(600); // delay momentarily to ensure logon/exit event is logged before enter event
    yield put(logEvent(siteId, courseId, 'course_enter'));
  }
}

export function* doLogout({ message }) {
  const authToken = yield select(getAuthToken);

  if (authToken) { // if already logged out, skip tasks
    const isVaS = yield select(isViewAsStudent);
    const tokensSelector = isVaS ? getSiteTokens : getAllTokens;
    const tokens = yield select(tokensSelector);

    try {
      const apiCalls = tokens.map((token) => call(deleteToken, token));
      yield all(apiCalls);
    } catch (e) {
      // do nothing, continue with destroying local data
    }

    yield put(clearView());
    yield put(clearData());
    yield put(finishLogout(message));
    yield put({ type: PURGE, result: () => {} });
    if (isVaS) {
      window.close();
    }
  }
}

export function* doRefreshTokens() {
  const ticksBeforeRefresh = 30; // refresh every 15 minutes
  let lastRefresh = yield Date.now();
  let ticks = yield 0;
  while (true) {
    yield delay(30000); // tick every 30 seconds

    const active = yield select(isActiveSession);
    const activeSinceRefresh = yield select(isActiveSince, lastRefresh);
    const shouldRefresh = yield ticks >= ticksBeforeRefresh && activeSinceRefresh;

    if (active) {
      if (shouldRefresh) {
        const assessmentMetadata = yield select(getAssessmentMetadata);
        const refreshAssessment = !assessmentMetadata.get('isComplete')
          && assessmentMetadata.get('lessonWorkId');
        const tokens = yield select(getAllTokens);
        const refreshCalls = tokens.map((token) => call(refreshToken, token, refreshAssessment));

        try {
          yield all(refreshCalls);
          lastRefresh = Date.now();
          ticks = 0;
        } catch (e) {
          yield put(logout('Authentication error. Please sign in again.'));
        }
      }
      ticks += 1;
    } else {
      yield put(logout('Logged out due to inactivity.'));
    }
  }
}

export function* watchAuthenticateRequest() {
  yield takeLeading(AUTHENTICATION_AUTHENTICATE_REQUEST, doAuthenticate);
}

export function* watchAuthenticateSiteRequest() {
  yield takeLeading(AUTHENTICATION_SITE_DATA_REQUEST, doAuthenticateSite);
}

export function* watchAuthenticateTeacherSiteRequest() {
  yield takeLeading(AUTHENTICATION_TEACHER_SITE_DATA_REQUEST, doAuthenticateTeacherSite);
}

export function* watchAuthenticationEmailVerify() {
  yield takeLeading(AUTHENTICATION_VERIFY_EMAIL_REQUEST, doEmailVerify);
}

export function* watchAuthenticationEnrollVerify() {
  yield takeLeading(AUTHENTICATION_VERIFY_ENROLLMENT_REQUEST, doEnrollVerify);
}

export function* watchAuthenticationEnrollStudent() {
  yield takeLeading(AUTHENTICATION_ENROLL_STUDENT, doEnrollStudent);
}

export function* watchAuthenticationEnrollSuccess() {
  yield takeLeading(AUTHENTICATION_ENROLL_SUCCESS, doAuthenticateEnroll);
}

export function* watchCreateNewStudentRequest() {
  yield takeLeading(CREATE_NEW_STUDENT_REQUEST, doCreateNewStudent);
}

export function* watchUpdateStudentRequest() {
  yield takeLeading(UPDATE_STUDENT_REQUEST, doUpdateStudent);
}

export function* watchPasswordResetRequest() {
  yield takeLeading(PASSWORD_RESET_REQUEST, doPasswordReset);
}

export function* watchPasswordSetRequest() {
  yield takeLeading(PASSWORD_SET_REQUEST, doPasswordSet);
}

export function* watchTeacherInviteRequest() {
  yield takeLeading(TEACHER_INVITE_REQUEST, doTeacherInviteRequest);
}

export function* watchLogout() {
  yield takeLeading(AUTHENTICATION_LOGOUT, doLogout);
}

export function* watchChangeModuleView() {
  yield takeLeading(CHANGE_STUDENT_PREFERENCES_REQUEST, doUpdateStudentPreferences);
}

export function* watchSetActiveCourse() {
  yield takeEvery(AUTHENTICATION_SET_ACTIVE_COURSE, doSetActiveCourse);
}

export function* watchLoadTeacherCourses() {
  yield takeLeading(LOAD_TEACHER_COURSES, doLoadTeacherCourses);
}

export function* watchActiveSession() {
  // runs refresh loop continually from login until a logout action occurs, then cancels.
  while (yield take(AUTHENTICATION_AUTHENTICATE_SUCCESS)) {
    const refreshTask = yield fork(doRefreshTokens);
    yield take(AUTHENTICATION_LOGOUT);
    yield cancel(refreshTask);
  }
}
