import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List, Map } from 'immutable';
import Checkbox from '../components/Checkbox';

import { updateMigrationCoursesSelected } from '../redux/View/actions';

const MigrateCourseItemList = ({
  siteName,
  courses,
  dispatchUpdateCoursesSelected,
  migrationCoursesSelected,
  allSelected,
}) => {
  const selectedIds = migrationCoursesSelected || List();

  const updateCoursesSelected = (checked, id) => {
    const courseId = Number.parseInt(id, 10);
    const course = courses.find((data) => data.get('id') === courseId);

    if (course) {
      if (checked) {
        dispatchUpdateCoursesSelected(
          selectedIds.push(Map({
            id: courseId,
            site_id: course.get('site_id'),
          })),
        );
      } else {
        dispatchUpdateCoursesSelected(
          selectedIds.filter((data) => data.get('id') !== courseId),
        );
      }
    }
  };

  const handleOnChange = (event) => {
    const { target: { id, checked } } = event;
    updateCoursesSelected(checked, id);
  };

  const isChecked = (id, notSupported) => (
    notSupported ? false
      : !!selectedIds.find((data) => data.get('id') === id) || allSelected
  );

  const DISABLED_STATUSES = [0, 1, 2, 4];

  return (
    <>
      <p className="site-name">{siteName}</p>
      {courses.map((course) => {
        const migrationStatus = course.get('icev_migration_status');
        const migrated = migrationStatus === 2 || migrationStatus === 3;
        const hasExamsOutstanding = migrationStatus === 3;
        const notSupported = migrationStatus === 0
          || migrationStatus === 1
          || migrationStatus === 4;
        /* Until exams migration is ready,
           anything with a value in migration status shouldn't be chosen to migrate again.

           When exam migration is ready will need to update the code below so that
           only items with migrate status of 2 cannot be migrated again. */
        const disableCheckbox = DISABLED_STATUSES.includes(migrationStatus);
        const id = course.get('id');
        const title = course.get('title');
        return (
          <div className="checklist-course" key={`course_${id}`}>
            <Checkbox
              id={`${id}`}
              name={title}
              label={title}
              checked={isChecked(id, notSupported)}
              onChange={handleOnChange}
              disableCheckbox={disableCheckbox}
              migrated={migrated}
              examsOutstanding={hasExamsOutstanding}
              notSupported={notSupported}
            />

          </div>
        );
      })}
    </>
  );
};

MigrateCourseItemList.propTypes = {
  siteName: PropTypes.string,
  courses: PropTypes.instanceOf(List),
  dispatchUpdateCoursesSelected: PropTypes.func.isRequired,
  migrationCoursesSelected: PropTypes.instanceOf(List).isRequired,
  allSelected: PropTypes.bool.isRequired,
};

MigrateCourseItemList.defaultProps = {
  siteName: '',
  courses: List(),
};

const mapStateToProps = ({ view }) => ({
  migrationCoursesSelected: view.get('migrationCoursesSelected') || List(),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchUpdateCoursesSelected: (selectedIds) => (
    dispatch(updateMigrationCoursesSelected(selectedIds))
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(MigrateCourseItemList);
