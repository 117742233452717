import {
  call,
  put,
  select,
  takeEvery, takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import { List, Map, OrderedMap } from 'immutable';
import {
  DATA_COURSE_REQUEST,
  DATA_LESSON_REQUEST,
  DATA_MODULE_REQUEST,
  DATA_LESSON_RESET_REQUEST,
  DATA_LESSON_WORK_UPDATED,
  DATA_LOG_EVENT,
  requestCourseDataFailed,
  requestCourseDataSucceeded,
  requestLesssonDataFailed,
  requestLesssonDataSucceeded,
  updateLessonWork,
  resetLessonFailed,
  requestChallengeData,
  updateAssessmentWork,
  requestLockedAssessmentData,
  updateLockedAssessmentId,
} from '../Data/actions';
import { getSiteToken, isViewAsStudent } from '../Authentication/selectors';
import {
  getCourse,
  getModule,
  getUnit,
  getLessonWork,
  getModuleBySequence,
  isErrorCountExceeded,
} from '../Data/selectors';
import {
  getCourseData,
  getCourseDataViewAsStudent,
  getLessonData,
  getLessonDataViewAsStudent,
  getModuleData,
  getWorksheetData,
  logEvent,
  resetLesson,
  resetLessonViewAsStudent,
} from '../../services/API';
import { authenticateSite } from '../Authentication/actions';
import { setRequestFinished } from '../View/actions';

export const processCourseData = (result) => {
  const assignments = result.get('assignments') || List();
  const modules = assignments.reduce((moduleMap, assignment) => {
    const entries = assignment.get('assignment_entries') || List();
    const moduleKey = assignment.get('module_key') || `EXAM_${assignment.get('id')}`;
    const unitWorks = assignment.get('unit_works') || List();
    const units = entries
      .filter((entry) => entry.get('type') === 'unit')
      .reduce((unitMap, unit) => {
        const key = unit.get('unit_key');
        const work = unitWorks.find((unitWork) => unitWork.get('unit_key') === key);
        return unitMap.set(key, unit.set('work', work));
      }, OrderedMap());
    const testKey = `MT_${moduleKey}`;
    const pretestKey = `PRE${testKey}`;
    const testEntry = entries.find((entry) => entry.get('type') === 'test');
    const testWork = unitWorks.find((unitWork) => (unitWork.get('unit_key') === testKey));
    const pretestWork = unitWorks.find((unitWork) => (unitWork.get('unit_key') === pretestKey)) || Map();
    const tests = testEntry
      ? OrderedMap({
        [testKey]: testEntry
          .set('work', testWork)
          .set('unit_key', testKey),
        [pretestKey]: Map({
          type: 'pre-test',
          work: pretestWork,
          assessmentWork: Map({ loaded: true }),
          // pre-flag as 'loaded' so lesson request isn't fired for pre-test
        }),
      })
      : undefined;

    const module = assignment
      .set('module_key', moduleKey)
      .set('units', units.merge(tests))
      .deleteAll(['assignment_entries', 'unit_works'])
      .mapKeys((prop) => (
        prop === 'exam_lesson_work'
          ? 'assessmentWork'
          : prop
      ));

    return moduleMap.set(moduleKey, module);
  }, OrderedMap());
  const courseData = result
    .delete('assignments')
    .set('modules', modules);
  return courseData;
};

export function* doRequestCourseData({ courseId, siteId }) {
  const siteToken = yield select(getSiteToken, siteId);
  if (siteToken) {
    const viewAsStudent = yield select(isViewAsStudent);
    const apiMethod = viewAsStudent
      ? getCourseDataViewAsStudent
      : getCourseData;

    try {
      const result = yield call(apiMethod, courseId, siteToken);
      const error = result.get('error');
      if (error) {
        yield put(requestCourseDataFailed(siteId, courseId, error, result.get('code')));
      } else {
        const lockedAssessmentId = result.get('locked_quiz_id');
        const courseData = processCourseData(result);
        const enableQuizChallenge = !viewAsStudent
          && courseData.getIn(['course_settings', 'enable_quiz_challenge']);
        yield put(requestCourseDataSucceeded(siteId, courseId, courseData));
        yield put(updateLockedAssessmentId(lockedAssessmentId));
        if (lockedAssessmentId) {
          yield put(requestLockedAssessmentData(siteId, lockedAssessmentId));
        } else if (enableQuizChallenge) {
          yield put(requestChallengeData(siteId, courseId));
        }
      }
    } catch (error) {
      yield put(requestCourseDataFailed(siteId, courseId, 'Error contacting API server'));
    }
  } else {
    yield put(authenticateSite(siteId, courseId));
  }
}

export function* doRequestModuleData({
  siteId, courseId, moduleKey,
}) {
  const siteToken = yield select(getSiteToken, siteId);
  const courseData = yield select(getCourse, siteId, courseId);
  const moduleData = yield select(getModule, siteId, courseId, moduleKey);
  const viewAsStudent = yield select(isViewAsStudent);
  const errorsExceeded = yield select(isErrorCountExceeded);
  const assignmentId = moduleData.get('id');

  if (!viewAsStudent && !errorsExceeded && assignmentId && siteToken) {
    const sequence = moduleData.get('sequence');
    const nextModuleData = yield select(getModuleBySequence, siteId, courseId, sequence + 1);
    const includeNextModule = nextModuleData.get('status') === 'Closed';

    const moduleOne = yield call(getModuleData, courseId, assignmentId, siteToken);
    let newModuleData;
    if (includeNextModule) {
      const nextAssignmentId = nextModuleData.get('id');
      const moduleTwo = yield call(getModuleData, courseId, nextAssignmentId, siteToken);
      newModuleData = moduleOne.mergeDeep(moduleTwo);
    } else {
      newModuleData = moduleOne;
    }
    // const error = result.get('error');
    const newCourseData = yield call(processCourseData, newModuleData);

    yield put(requestCourseDataSucceeded(siteId, courseId, courseData.mergeDeep(newCourseData)));
  }
}

export function* doRequestLessonData({
  courseId,
  siteId,
  moduleKey,
  unitKey,
}) {
  const siteToken = yield select(getSiteToken, siteId);
  const unitData = yield select(getUnit, siteId, courseId, moduleKey, unitKey);
  const assignmentEntryId = unitData.get('id');
  const unitWorkId = unitData.getIn(['work', 'id']);

  if (!assignmentEntryId) {
    yield put(setRequestFinished('lesson'));
  } else if (siteToken) {
    const viewAsStudent = yield select(isViewAsStudent);
    const apiMethod = viewAsStudent
      ? getLessonDataViewAsStudent
      : getLessonData;
    try {
      const result = yield call(apiMethod, courseId, assignmentEntryId, siteToken);
      const error = result.get('error');

      if (error) {
        yield put(requestLesssonDataFailed(siteId, courseId, moduleKey, unitKey, error, result.get('code')));
      } else {
        const worksheets = result.get('worksheets') || Map();
        const worksheetResult = yield (unitWorkId && worksheets.get('type') === 'electronic')
          ? call(getWorksheetData, courseId, unitWorkId, siteToken)
          : Map();
        const pageSetList = result.get('page_sets') || List();
        const lessonWorks = result.get('lesson_works') || List();
        const quizAttempts = result.get('quiz_attempts');
        const resourceList = result.get('resources') || List();
        const quizWork = lessonWorks.find((data) => {
          const pageSetKey = data.get('page_set_key');
          return data.get('unit_key') === unitKey
           && (pageSetKey === 'post-quiz' || pageSetKey === 'module-test');
        }) || Map();
        const assessmentWork = quizWork
          .merge(quizAttempts)
          .set('loaded', true);
        const pageSets = pageSetList.reduce((pageSetMap, pageSet) => {
          const pageSetKey = pageSet.get('page_set_key');
          const work = lessonWorks.find((lesson) => lesson.get('page_set_key') === pageSetKey);
          const lesson = pageSet.set('work', work);

          return pageSetMap.set(pageSetKey, lesson);
        }, OrderedMap());
        const resources = resourceList.reduce((resourceMap, resource) => {
          const resourceType = resource.get('resource_type');
          return resourceMap.update(resourceType, (list = List()) => list.push(resource));
        }, OrderedMap());

        const worksheetStatus = worksheetResult.reduce((status, lessonStatus) => {
          // eslint-disable-next-line no-unused-vars
          const available = lessonStatus.get('available');
          const completed = lessonStatus.get('completed');
          const lessonComplete = completed === available;
          const lessonStarted = !!completed;

          if (status === 'in-progress') {
            return status;
          }

          if (status === 'completed' && !lessonComplete) {
            return 'in-progress';
          }

          if (status === 'not-started' && lessonStarted) {
            return 'in-progress';
          }

          if (lessonComplete) {
            return 'completed';
          }

          if (lessonStarted) {
            return 'in-progress';
          }

          return 'not-started';
        }, '');
        const lessonData = Map({
          pageSets,
          worksheets,
          worksheetStatus,
          resources,
        });
        yield put(requestLesssonDataSucceeded(siteId, courseId, moduleKey, unitKey, lessonData));
        // NB: assessment work is split out here so that assessment saga is triggered by the action
        yield put(updateAssessmentWork(siteId, courseId, moduleKey, unitKey, assessmentWork));
      }
    } catch (error) {
      yield put(requestLesssonDataFailed(siteId, courseId, moduleKey, unitKey, 'Error contacting API server'));
    }
  }
}

export function* doResetLesson({
  siteId,
  courseId,
  moduleKey,
  unitKey,
  pageSetKey,
}) {
  const siteToken = yield select(getSiteToken, siteId);
  const lessonWork = yield select(getLessonWork, siteId, courseId, moduleKey, unitKey, pageSetKey);
  const lessonWorkId = lessonWork.get('id');

  try {
    if (siteToken && lessonWorkId) {
      const viewAsStudent = yield select(isViewAsStudent);
      const apiMethod = viewAsStudent
        ? resetLessonViewAsStudent
        : resetLesson;
      const result = yield call(apiMethod, courseId, lessonWorkId, siteToken);
      const newLessonWork = result.get('new_lesson_work');
      if (newLessonWork) {
        yield put(
          updateLessonWork(siteId, courseId, moduleKey, unitKey, pageSetKey, newLessonWork),
        );
      }
    }
  } catch (error) {
    yield put(resetLessonFailed(siteId, courseId, moduleKey, unitKey, pageSetKey, error));
  }
}

export function* doHandleLessonWorkChange({
  siteId,
  courseId,
  moduleKey,
  lessonWorkData,
}) {
  const status = lessonWorkData.get('status');
  // Only refresh for completion or reset
  if (status % 2 === 0) {
    yield call(doRequestModuleData, { siteId, courseId, moduleKey });
  }
}

export function* doLogEvent({
  actionType, siteId, courseId, params = {},
}) {
  const viewAsStudent = yield select(isViewAsStudent);
  const errorsExceeded = yield select(isErrorCountExceeded);
  if (!viewAsStudent && !errorsExceeded) {
    const siteToken = yield select(getSiteToken, siteId);
    const course = yield select(getCourse, siteId, courseId);
    const enrollmentId = course.get('enrollment_id');
    if (siteToken) {
      try {
        const result = yield call(logEvent, enrollmentId, actionType, params, siteToken);
        if (result.has('error')) {
          window.Rollbar.error(`Error logging ${actionType} event`, {
            message: result.get('error'),
            actionType,
            siteId,
            courseId,
            enrollmentId,
          });
        }
      } catch (error) {
        window.Rollbar.error(`Error logging ${actionType} event`, {
          message: error.message,
          actionType,
          siteId,
          courseId,
          enrollmentId,
        });
      }
    }
  }
}

export function* watchCourseDataRequest() {
  yield takeLeading(DATA_COURSE_REQUEST, doRequestCourseData);
}

export function* watchModuleDataRequest() {
  yield takeEvery(DATA_MODULE_REQUEST, doRequestModuleData);
}

export function* watchLessonDataRequest() {
  yield takeLeading(DATA_LESSON_REQUEST, doRequestLessonData);
}

export function* watchLogEvent() {
  yield takeEvery(DATA_LOG_EVENT, doLogEvent);
}

export function* watchResetLesson() {
  yield takeLeading(DATA_LESSON_RESET_REQUEST, doResetLesson);
}

export function* watchUpdateLessonWork() {
  yield takeLatest(DATA_LESSON_WORK_UPDATED, doHandleLessonWorkChange);
}
