import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import ResourceListItem from './ResourceListItem';
import './ItemLists.scss';

const ModuleResourceList = ({
  resources,
}) => (
  <div className="itemContainer customModule">
    <table className="resources">
      <thead>
        <tr className="header">
          <th><h3>Title</h3></th>
          <th><h3>Description</h3></th>
          <th><h3>Download Link</h3></th>
        </tr>
      </thead>
      <tbody>
        {resources.map((data) => (
          <ResourceListItem
            key={data.get('path')}
            data={data}
          />
        )).toList()}
      </tbody>
    </table>
  </div>
);

ModuleResourceList.propTypes = {
  resources: PropTypes.instanceOf(List).isRequired,
};

export default ModuleResourceList;
