import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import Icon from '@mdi/react';
import { mdiEarHearing } from '@mdi/js';

const AssessmentTts = ({ question, ttsData, requestTts }) => {
  const questionDiv = document.getElementById(question.key);
  const data = ttsData.get(question.key) || Map();
  const ttsUrl = data.get('ttsUrl');
  const ttsJob = data.get('ttsJobId');
  const onClick = () => requestTts(question);

  return createPortal(
    <div className="question-tts">
      {ttsUrl // eslint-disable-next-line jsx-a11y/media-has-caption
        ? <audio src={ttsUrl} autoPlay controls />
        : (
          <button type="button" className="link" onClick={onClick} disabled={!!ttsJob}>
            <Icon path={mdiEarHearing} />
            Read Question
          </button>
        )}
    </div>,
    questionDiv,
  );
};

AssessmentTts.propTypes = {
  question: PropTypes.shape({
    key: PropTypes.string,
    stimulus: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })),
  }).isRequired,
  ttsData: PropTypes.instanceOf(Map).isRequired,
  requestTts: PropTypes.func.isRequired,
};

export default AssessmentTts;
