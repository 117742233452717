import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, Navigate } from 'react-router-dom';
import { authenticateEnrollStudentWithLogin } from '../redux/Authentication/actions';
import { findError } from '../utils/errors';
import Input from '../components/Input';
import './SignIn.scss';

class EnrollExistingStudent extends Component {
  constructor(props) {
    super(props);
    this.state = { password: '' };
  }

  onChangeValue({ target: { value } }) {
    this.setState({ password: value });
  }

  onSubmit(event) {
    const { email, enrollmentCode, loginAndEnroll } = this.props;
    const { password } = this.state;
    event.preventDefault();
    loginAndEnroll(enrollmentCode, email, password);
  }

  render() {
    const {
      password,
    } = this.state;

    const {
      authToken,
      email,
      enrollmentCode,
      loginError,
    } = this.props;

    if (authToken) {
      return <Navigate to="/courses" />;
    }

    if (!email || !enrollmentCode) {
      return <Navigate to="/enroll" />;
    }

    return (
      <div className="sign-in-form">
        <form onSubmit={(event) => this.onSubmit(event)}>
          <h3 className="enrollment">Please Enter your Password</h3>
          <Input name="email" value={email} disabled />
          <Input
            type="password"
            name="password"
            value={password}
            onChange={(event) => this.onChangeValue(event)}
            placeholder="Password"
            error={loginError}
          />
          <button
            type="submit"
            className="primary"
          >
            Sign In
          </button>
          <p className="center">
            <Link to="/student/help">Forgot Password?</Link>
          </p>
        </form>
      </div>
    );
  }
}

EnrollExistingStudent.propTypes = {
  authToken: PropTypes.string,
  email: PropTypes.string.isRequired,
  enrollmentCode: PropTypes.string.isRequired,
  loginError: PropTypes.string,
  loginAndEnroll: PropTypes.func.isRequired,
};

EnrollExistingStudent.defaultProps = {
  authToken: '',
  loginError: '',
};

const mapStateToProps = ({ authentication }) => ({
  authToken: authentication.get('authToken'),
  email: authentication.get('emailVerified'),
  enrollmentCode: authentication.get('enrollmentCode'),
  loginError: findError(authentication.get('error')).message,
});

const mapDispatchToProps = (dispatch) => ({
  loginAndEnroll: (enrollmentCode, email, password) => dispatch(
    authenticateEnrollStudentWithLogin(enrollmentCode, email, password),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(EnrollExistingStudent);
