import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

const ResourceListItem = ({
  data,
}) => {
  const title = data.get('title');
  const filename = data.get('filename');
  const path = data.get('path');
  const description = data.get('description');

  return (
    <tr className="resourceListItem">
      <td className="unitTitle">
        <h3>
          {title}
        </h3>
      </td>
      <td className="details">
        {description}
      </td>
      <td className="details">
        <a href={path} target="_blank" rel="noreferrer">{filename}</a>
      </td>
    </tr>
  );
};

ResourceListItem.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired,
};

export default ResourceListItem;
