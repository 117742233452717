export const AUTHENTICATION_AUTHENTICATE_REQUEST = 'AUTHENTICATION_AUTHENTICATE_REQUEST';
export const AUTHENTICATION_AUTHENTICATE_SUCCESS = 'AUTHENTICATION_AUTHENTICATE_SUCCESS';
export const AUTHENTICATION_AUTHENTICATE_ERROR = 'AUTHENTICATION_AUTHENTICATE_ERROR';

export const AUTHENTICATION_SITE_DATA_REQUEST = 'AUTHENTICATION_SITE_DATA_REQUEST';
export const AUTHENTICATION_SITE_DATA_SUCCESS = 'AUTHENTICATION_SITE_DATA_SUCCESS';
export const AUTHENTICATION_SITE_DATA_ERROR = 'AUTHENTICATION_SITE_DATA_ERROR';

export const AUTHENTICATION_SET_ACTIVE_COURSE = 'AUTHENTICATION_SET_ACTIVE_COURSE';
export const AUTHENTICATION_SET_ACTIVE_COURSE_SUCCESS = 'AUTHENTICATION_SET_ACTIVE_COURSE_SUCCESS';

export const AUTHENTICATION_SET_LAST_ACTIVITY = 'AUTHENTICATION_SET_LAST_ACTIVITY';

export const AUTHENTICATION_TEACHER_SITE_DATA_REQUEST = 'AUTHENTICATION_TEACHER_SITE_DATA_REQUEST';
export const AUTHENTICATION_TEACHER_SITE_DATA_ERROR = 'AUTHENTICATION_TEACHER_SITE_DATA_ERROR';

export const AUTHENTICATION_ENROLL_STUDENT = 'AUTHENTICATION_ENROLL_STUDENT';
export const AUTHENTICATION_ENROLL_STUDENT_LOGIN = 'AUTHENTICATION_ENROLL_STUDENT_LOGIN';
export const AUTHENTICATION_ENROLL_SUCCESS = 'AUTHENTICATION_ENROLL_SUCCESS';
export const AUTHENTICATION_ENROLL_RESET = 'AUTHENTICATION_ENROLL_RESET';
export const AUTHENTICATION_ENROLL_ERROR = 'AUTHENTICATION_ENROLL_ERROR';

export const AUTHENTICATION_VERIFY_EMAIL_REQUEST = 'AUTHENTICATION_VERIFY_EMAIL_REQUEST';
export const AUTHENTICATION_VERIFY_EMAIL_SUCCESS = 'AUTHENTICATION_VERIFY_EMAIL_SUCCESS';
export const AUTHENTICATION_VERIFY_EMAIL_ERROR = 'AUTHENTICATION_VERIFY_EMAIL_ERROR';
export const AUTHENTICATION_VERIFY_EMAIL_CLEAR = 'AUTHENTICATION_VERIFY_EMAIL_CLEAR';

export const AUTHENTICATION_VERIFY_ENROLLMENT_REQUEST = 'AUTHENTICATION_VERIFY_ENROLLMENT_REQUEST';
export const AUTHENTICATION_VERIFY_ENROLLMENT_SUCCESS = 'AUTHENTICATION_VERIFY_ENROLLMENT_SUCCESS';
export const AUTHENTICATION_VERIFY_ENROLLMENT_ERROR = 'AUTHENTICATION_VERIFY_ENROLLMENT_ERROR';

export const AUTHENTICATION_LOGOUT = 'AUTHENTICATION_LOGOUT';
export const AUTHENTICATION_LOGOUT_SUCCESS = 'AUTHENTICATION_LOGOUT_SUCCESS';

export const CREATE_NEW_STUDENT_REQUEST = 'CREATE_NEW_STUDENT_REQUEST';
export const CREATE_NEW_STUDENT_SUCCESS = 'CREATE_NEW_STUDENT_SUCCESS';
export const CREATE_NEW_STUDENT_ERROR = 'CREATE_NEW_STUDENT_ERROR';

export const UPDATE_STUDENT_REQUEST = 'UPDATE_STUDENT_REQUEST';
export const UPDATE_STUDENT_SUCCESS = 'UPDATE_STUDENT_SUCCESS';
export const UPDATE_STUDENT_ERROR = 'UPDATE_STUDENT_ERROR';
export const UPDATE_STUDENT_CLEAR = 'UPDATE_STUDENT_CLEAR';

export const PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_ERROR = 'PASSWORD_RESET_ERROR';

export const PASSWORD_SET_REQUEST = 'PASSWORD_SET_REQUEST';
export const PASSWORD_SET_SUCCESS = 'PASSWORD_SET_SUCCESS';
export const PASSWORD_SET_ERROR = 'PASSWORD_SET_ERROR';
export const PASSWORD_SET_FINISH = 'PASSWORD_SET_FINISH';

export const TEACHER_INVITE_REQUEST = 'TEACHER_INVITE_REQUEST';
export const TEACHER_INVITE_ERROR = 'TEACHER_INVITE_ERROR';
export const TEACHER_COURSE_MIGRATION = 'TEACHER_COURSE_MIGRATION';

export const CHANGE_STUDENT_PREFERENCES_REQUEST = 'CHANGE_STUDENT_PREFERENCES_REQUEST';
export const CHANGE_STUDENT_PREFERENCES_SUCCESS = 'CHANGE_STUDENT_PREFERENCES_SUCCESS';
export const CHANGE_STUDENT_PREFERENCES_ERROR = 'CHANGE_STUDENT_PREFERENCES_ERROR';

export const CLOSE_SCREEN_ORIENTATION_WARNING = 'CLOSE_SCREEN_ORIENTATION_WARNING';

export const LOAD_TEACHER_COURSES = 'LOAD_TEACHER_COURSES';

export const LOAD_TEACHER_COURSES_ERROR = 'LOAD_TEACHER_COURSES_ERROR';

export const CREATE_ICEV_ACCOUNT_REQUEST = 'CREATE_ICEV_ACCOUNT_REQUEST';
export const CREATE_ICEV_ACCOUNT_SUCCESS = 'CREATE_ICEV_ACCOUNT_SUCCESS';
export const CREATE_ICEV_ACCOUNT_JOB_REQUEST = 'CREATE_ICEV_ACCOUNT_JOB_REQUEST';

export const SET_ICEV_ORG_EXISTS = 'SET_ICEV_ORG_EXISTS';

export const authenticate = ({
  email, password, provider, providerToken, authToken, userRole, courseMigration,
}) => ({
  type: AUTHENTICATION_AUTHENTICATE_REQUEST,
  email: email?.trim(),
  password,
  provider,
  providerToken,
  authToken,
  userRole,
  courseMigration,
});

export const authenticateSucceeded = (authToken, user, userRole) => ({
  type: AUTHENTICATION_AUTHENTICATE_SUCCESS,
  authToken,
  user,
  userRole,
});

export const authenticateFailed = (error, code) => ({
  type: AUTHENTICATION_AUTHENTICATE_ERROR,
  error,
  code,
});

export const authenticateSite = (siteId, courseId) => ({
  type: AUTHENTICATION_SITE_DATA_REQUEST,
  siteId,
  courseId,
});

export const authenticateSiteSucceeded = (siteId, siteToken) => ({
  type: AUTHENTICATION_SITE_DATA_SUCCESS,
  siteId,
  siteToken,
});

export const authenticateSiteFailed = (siteId, error, code) => ({
  type: AUTHENTICATION_SITE_DATA_ERROR,
  siteId,
  error,
  code,
});

export const authenticateTeacherSite = (siteId, courseId) => ({
  type: AUTHENTICATION_TEACHER_SITE_DATA_REQUEST,
  siteId,
  courseId,
});

export const authenticateTeacherSiteFailed = (siteId, error) => ({
  type: AUTHENTICATION_TEACHER_SITE_DATA_ERROR,
  siteId,
  error,
});

export const authenticateEnrollStudent = ({ enrollmentCode, ssoEnroll }) => ({
  type: AUTHENTICATION_ENROLL_STUDENT,
  enrollmentCode,
  ssoEnroll,
});

export const authenticateEnrollStudentWithLogin = (enrollmentCode, email, password) => ({
  type: AUTHENTICATION_AUTHENTICATE_REQUEST,
  enrollmentCode,
  email,
  password,
  userRole: 'student',
});

export const authenticateEnrollReset = () => ({
  type: AUTHENTICATION_ENROLL_RESET,
});

export const authenticateEnrollSuccess = (siteId, courseId, enrollmentId) => ({
  type: AUTHENTICATION_ENROLL_SUCCESS,
  siteId,
  courseId,
  enrollmentId,
});

export const authenticateEnrollError = (error) => ({
  type: AUTHENTICATION_ENROLL_ERROR,
  error,
});

export const authenticateVerifyEmail = (email) => ({
  type: AUTHENTICATION_VERIFY_EMAIL_REQUEST,
  email,
});

export const updateStudentPreferences = ({ preference }) => ({
  type: CHANGE_STUDENT_PREFERENCES_REQUEST,
  preference,
});

export const updateStudentPreferencesSuccess = (preferences) => ({
  type: CHANGE_STUDENT_PREFERENCES_SUCCESS,
  preferences,
});

export const updateStudentPreferencesError = (errors) => ({
  type: CHANGE_STUDENT_PREFERENCES_ERROR,
  errors,
});

export const authenticateVerifyEmailSuccess = (available) => ({
  type: AUTHENTICATION_VERIFY_EMAIL_SUCCESS,
  available,
});

export const authenticateVerifyEmailError = (error) => ({
  type: AUTHENTICATION_VERIFY_EMAIL_ERROR,
  error,
});

export const authenticateClearEmailVerification = () => ({
  type: AUTHENTICATION_VERIFY_EMAIL_CLEAR,
});

export const authenticateVerifyEnrollmentCode = (enrollmentCode) => ({
  type: AUTHENTICATION_VERIFY_ENROLLMENT_REQUEST,
  enrollmentCode,
});

export const authenticateVerifyEnrollmentCodeSuccess = (enrollmentCode, ssoGoogle) => ({
  type: AUTHENTICATION_VERIFY_ENROLLMENT_SUCCESS,
  enrollmentCode,
  ssoGoogle,
});

export const authenticateVerifyEnrollmentCodeError = (error) => ({
  type: AUTHENTICATION_VERIFY_ENROLLMENT_ERROR,
  error,
});

export const createNewStudent = ({
  firstName,
  lastName,
  email,
  password,
  passwordConfirmation,
  enrollmentCode,
  provider,
  providerToken,
}) => ({
  type: CREATE_NEW_STUDENT_REQUEST,
  firstName,
  lastName,
  email,
  password,
  passwordConfirmation,
  enrollmentCode,
  provider,
  providerToken,
});

export const updateStudent = ({
  firstName, lastName, email, password, passwordConfirmation,
}) => ({
  type: UPDATE_STUDENT_REQUEST,
  firstName,
  lastName,
  email,
  password,
  passwordConfirmation,
});

export const updateStudentError = (errors) => ({
  type: UPDATE_STUDENT_ERROR,
  errors,
});

export const updateStudentSuccess = () => ({
  type: UPDATE_STUDENT_SUCCESS,
});

export const updateStudentClear = () => ({
  type: UPDATE_STUDENT_CLEAR,
});

export const createNewStudentSuccess = (token, siteId, courseId) => ({
  type: CREATE_NEW_STUDENT_SUCCESS,
  token,
  siteId,
  courseId,
});

export const createNewStudentError = (errors) => ({
  type: CREATE_NEW_STUDENT_ERROR,
  errors,
});

export const passwordResetRequest = (email) => ({
  type: PASSWORD_RESET_REQUEST,
  email,
});

export const passwordResetSuccess = () => ({
  type: PASSWORD_RESET_SUCCESS,
});

export const passwordResetError = (error) => ({
  type: PASSWORD_RESET_ERROR,
  error,
});

export const passwordSetRequest = (code, email, password, passwordConfirmation) => ({
  type: PASSWORD_SET_REQUEST,
  code,
  email,
  password,
  passwordConfirmation,
});

export const passwordSetSuccess = () => ({
  type: PASSWORD_SET_SUCCESS,
});

export const passwordSetFinish = () => ({
  type: PASSWORD_SET_FINISH,
});

export const PasswordSetErrors = (errors) => ({
  type: PASSWORD_SET_ERROR,
  errors,
});

export const acceptTeacherInvite = (code, password, passwordConfirmation) => ({
  type: TEACHER_INVITE_REQUEST,
  code,
  password,
  passwordConfirmation,
});

export const acceptTeacherInviteError = (error) => ({
  type: TEACHER_INVITE_ERROR,
  error,
});

export const setTeacherCourseMigration = () => ({
  type: TEACHER_COURSE_MIGRATION,
});

export const logout = (message) => ({
  type: AUTHENTICATION_LOGOUT,
  message,
});

export const finishLogout = (message) => ({
  type: AUTHENTICATION_LOGOUT_SUCCESS,
  message,
});

export const closeScreenOrientationWarning = () => ({
  type: CLOSE_SCREEN_ORIENTATION_WARNING,
});

export const setActiveCourse = (siteId, courseId) => ({
  type: AUTHENTICATION_SET_ACTIVE_COURSE,
  siteId,
  courseId,
});

export const setActiveCourseSucceeded = (siteId, courseId) => ({
  type: AUTHENTICATION_SET_ACTIVE_COURSE_SUCCESS,
  siteId,
  courseId,
});

export const setLastActivity = (event) => ({
  type: AUTHENTICATION_SET_LAST_ACTIVITY,
  event,
});

export const loadTeacherCourses = (siteIds) => ({
  type: LOAD_TEACHER_COURSES,
  siteIds,
});

export const loadTeacherCoursesError = (error, code) => ({
  type: LOAD_TEACHER_COURSES_ERROR,
  error,
  code,
});

export const createIcevAccount = () => ({
  type: CREATE_ICEV_ACCOUNT_REQUEST,
});

export const createIcevAccountSucceeded = (icevAccountId) => ({
  type: CREATE_ICEV_ACCOUNT_SUCCESS,
  icevAccountId,
});

export const getIcevAccountJob = (jobId) => ({
  type: CREATE_ICEV_ACCOUNT_JOB_REQUEST,
  jobId,
});

export const setIcevOrgExists = () => ({ type: SET_ICEV_ORG_EXISTS });
