import { fromJS } from 'immutable';

const OVERAGE = 'Your site is out of licenses';

const ERROR_TEXT = fromJS({
  firstName: {
    blank: 'Please enter your first name',
    invalid: 'The following character(s) are not allowed: ',
  },
  lastName: {
    invalid: 'The following characters are not allowed: ',
    blank: 'Please enter your last name',
  },
  password: {
    invalid: 'The following character(s) are not allowed: ',
    too_short: 'Your password must contain at least 6 characters',
    blank: 'Please create a password',
  },
  passwordConfirmation: {
    blank: 'Your password confirmation needs to match the password field',
    confirmation: 'Your password confirmation needs to match the password field',
    invalid: 'The following character(s) are not allowed: ',
  },
  enrollment: {
    'Overages Blocked': `${OVERAGE}. Please contact your teacher for assistance.`,
    'Warning Period Over': `${OVERAGE}. Please contact your teacher for assistance.`,
  },
  siteToken: {
    'Overages Blocked': OVERAGE,
    'Warning Period Over': OVERAGE,
  },
});

export const isValidEmail = (email) => (
  // regex from html5 spec https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
    .test(email)
);

export const invalidEmailChars = (email) => {
  const invalidChars = email.match(/[^\u0020-\u007e\u00a0-\u00ff]/g);
  return invalidChars ? invalidChars.join('') : null;
};

export const isValidUsername = (email, userRole) => (
  userRole === 'teacher' ? isValidEmail(email) : !!email
);

export const getErrorText = (field, key, values) => {
  const errorText = ERROR_TEXT.getIn([field, key]) || key;

  if (values) { return `${errorText} ${values}`; }

  return errorText;
};

export const findError = (error) => {
  switch (error) {
    case 'confirmation':
      return ({
        field: 'passwordConfirmation',
        message: getErrorText('passwordConfirmation', error, ''),
      });
    case 'too_short':
      return ({
        field: 'password',
        message: getErrorText('password', error, ''),
      });
    case 'blank':
      return ({
        field: 'password',
        message: getErrorText('password', error, ''),
      });
    case 'The e-mail address that you entered is not registered.':
      return ({
        field: 'email',
        message: 'The e-mail address or username that you entered is not registered',
      });
    case 'Please enter a valid e-mail or username.':
      return ({
        field: 'email',
        message: error.slice(0, -1),
      });
    case 'Please enter a valid password.':
    case 'The password that you entered is incorrect.':
      return ({
        field: 'password',
        message: error.slice(0, -1),
      });
    case 'Email not found':
      return ({
        field: 'email',
        message: 'The e-mail address that you entered is not registered',
      });
    case 'Invalid enrollment code':
      return ({
        field: 'enrollmentCode',
        message: 'The enrollment code is invalid, or enrollment is closed',
      });
    case 'Please enter an enrollment code':
      return ({
        field: 'enrollmentCode',
        message: error,
      });
    case 'The single sign on token is not valid':
    case 'The single sign on account is not registered':
      return ({
        field: 'sso',
        message: error,
      });
    case 'Illegal Parameter':
      return ({
        field: 'message',
        message: 'The link you followed is expired or invalid.',
      });
    default:
      return ({
        field: 'message',
        message: error,
      });
  }
};

export const getError = (name, errors) => {
  const error = errors.get(name);
  if (error) return error.message;
  return '';
};

export const mapErrorsToTypes = (errors) => errors.map((error) => findError(error));
