import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import Alert from './Alert';
import Input from './Input';
import './StudentProfile.scss';
import { getErrorText } from '../utils/errors';

const StudentProfileForm = ({
  toggleEditPassword,
  editPassword,
  data,
  editData,
  submit,
  errors,
  updateSuccess,
}) => {
  const firstName = data.get('first_name');
  const lastName = data.get('last_name');
  const email = data.get('email');
  const password = data.get('password');
  const passwordConfirmation = data.get('passwordConfirmation');
  const passwordErr = errors.get('password') || {};
  const confirmErr = errors.get('passwordConfirmation') || {};

  return (
    <>
      <div className="profile">
        <h1>Profile Settings</h1>
        <form>
          <Input name="firstName" onChange={editData} value={firstName} label="First Name" disabled />
          <Input name="lastName" onChange={editData} value={lastName} label="Last Name" disabled />
          <Input name="email" onChange={editData} value={email} label="Email or Username" disabled />
        </form>
      </div>
      <div className="profile password">
        <h2>Change your Password</h2>
        {updateSuccess ? <Alert message="Password Updated Successfully" icon="check" type="success" /> : null}
        {editPassword ? (
          <form onSubmit={submit}>
            <Input
              autoComplete="new-password"
              type="password"
              name="password"
              label="New Password"
              value={password}
              error={getErrorText('password', passwordErr.type, passwordErr.value)}
              onChange={editData}
            />
            <Input
              autoComplete="new-password"
              type="password"
              name="passwordConfirmation"
              label="Confirm New Password"
              value={passwordConfirmation}
              error={getErrorText('passwordConfirmation', confirmErr.type, confirmErr.value)}
              onChange={editData}
            />
            <p className="hint">
              <strong>Hint: </strong>
              Use a word or combination of words you will remember.
            </p>
            <p className="hint">Your password must contain at least 6 characters or more.</p>
            <div className="actions">
              <button type="submit" className="primary">Save New Password</button>
              <button type="button" onClick={toggleEditPassword}>Cancel</button>
            </div>
          </form>
        ) : null}
        {!editPassword ? <button type="button" onClick={toggleEditPassword}>Change your Password</button> : null}
      </div>
    </>
  );
};

StudentProfileForm.propTypes = {
  toggleEditPassword: PropTypes.func.isRequired,
  editData: PropTypes.func.isRequired,
  editPassword: PropTypes.bool.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  submit: PropTypes.func.isRequired,
  errors: PropTypes.instanceOf(Map).isRequired,
  updateSuccess: PropTypes.bool.isRequired,
};

export default StudentProfileForm;
