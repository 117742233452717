export const getAssessmentMetadata = ({ view }) => view.get('activeAssessmentMetadata');

export const getRedirect = ({ view }) => view.get('redirect') || '';

export const isRequestActive = ({ view }, key) => view.getIn(['activeRequests', key]) || false;

export const isSiteLoading = (state) => isRequestActive(state, 'site');

export const isCourseLoading = (state) => isRequestActive(state, 'course');

export const isLessonLoading = (state) => isRequestActive(state, 'lesson');

export const getLockedAssessmentPath = (state) => {
  const assessmentMeta = getAssessmentMetadata(state);
  if (assessmentMeta.has('assignmentId') && !assessmentMeta.get('isComplete')) {
    const type = assessmentMeta.get('assessmentType');
    const siteId = assessmentMeta.get('siteId');
    const courseId = assessmentMeta.get('courseId');
    const moduleKey = assessmentMeta.get('moduleKey');
    const unitKey = assessmentMeta.get('unitKey');

    switch (type) {
      case 'exam':
        return `site/${siteId}/course/${courseId}/exam/${moduleKey}`;
      case 'quiz':
        return `site/${siteId}/course/${courseId}/module/${moduleKey}/unit/${unitKey}/quiz/post-quiz`;
      default:
        return `site/${siteId}/course/${courseId}/module/${moduleKey}/test/${unitKey}`;
    }
  }
  return '';
};

export const isVasAlertDismissed = ({ view }) => view.get('vasAlertDismissed') || false;

export const isEventBannerDismissed = ({ view }) => view.get('eventBannerDismissed') || false;

export const getMigrationCoursesSelected = ({ view }) => view.get('migrationCoursesSelected');

export const getMigrationError = ({ view }) => view.get('migrationError');
