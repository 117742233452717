import {
  format,
  isAfter,
  isToday,
  parse,
  parseISO,
  startOfToday,
} from 'date-fns';

export const formatAssessmentDate = (dateString) => {
  if (dateString) {
    const date = parseISO(dateString);
    const formatString = isToday(date) ? "'Today' hh:mm a" : 'M/d/yyyy hh:mm a';
    return format(date, formatString);
  }
  return '';
};

export const formatDueDate = (date) => format(date, 'M/d/yyyy');

export const formatEventDate = (date) => {
  const formatString = isToday(date)
    ? "'at' h:mm aa"
    : "'on' EEEE, MMMM d, 'at' h:mm aa";
  return format(date, formatString);
};

export const parseDueDate = (dateString) => (
  dateString
    ? parse(dateString, 'yyyy-M-d', startOfToday())
    : startOfToday()
);

export const isPastDue = (dateString) => isAfter(startOfToday(), parseDueDate(dateString));
