import { isAfter, isBefore } from 'date-fns';

export const getNextBannerEvent = ({ platformStatus }) => platformStatus.get('events')
  .find((event) => isAfter(event.get('endDate'), Date.now()));

export const getCurrentLandingMessage = (state) => {
  const event = getNextBannerEvent(state);
  const logoutDate = event?.get('logoutDate');
  return logoutDate && isBefore(logoutDate, Date.now()) ? event : null;
};
