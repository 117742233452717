import { htmlToText } from '../utils/formatting';

let hasInitError = false;

const errorListener = (error, json, onError) => {
  window.Rollbar.error(`Learnosity Error ${error.code}`, { error: error.message, json });
  hasInitError = true;
  onError(error);
  return false;
};

const getQuestionsforTTS = (itemsApp) => Object.entries(itemsApp.questions())
  .map(([key, question]) => {
    const { type, stimulus, options } = question.getQuestion();
    return ({
      key,
      type,
      stimulus: htmlToText(stimulus),
      options: type === 'mcq' ? question.getProcessedOptions() : options,
    });
  });

const initializeQuestionCallbacks = (itemsApp, { onChange }) => {
  Object.values(itemsApp.questions())
    .forEach((question) => {
      let attempts = 0;
      question.on('change', () => {
        attempts += 1;
        onChange(question, attempts);
      });
    });
};

const initialize = (configJSON, selector, callbacks) => {
  const {
    onGoto = () => {},
    onReady = () => {},
    onSetAttempt = () => {},
    onSubmit = () => {},
    onError = () => {},
  } = callbacks;
  const config = JSON.parse(configJSON);
  const { request: { session_id: sessionId } } = config;

  try {
    const itemsApp = window.LearnosityItems.init(config, selector, {
      errorListener: (error) => errorListener(error, configJSON, onError),
      customUnload: () => undefined, // Prevents Alert from blocking window close/refresh
    });

    itemsApp.on('test:ready', () => {
      const questions = getQuestionsforTTS(itemsApp);
      initializeQuestionCallbacks(itemsApp, {
        onChange: (question, attempts) => {
          onSetAttempt(question, attempts, itemsApp);
        },
      });
      onReady({ questions }, itemsApp);
    });
    itemsApp.on('test:finished:submit', () => onSubmit(sessionId, itemsApp));
    itemsApp.on('item:goto', () => onGoto(sessionId, itemsApp));
  } catch (error) {
    errorListener(error, configJSON, onError);
  }
};

const reset = () => window.LearnosityItems?.reset();

const hasInitializationErrors = () => (
  hasInitError
  || !window.LearnosityItems
  || typeof window.LearnosityItems.init !== 'function'
);

export default { hasInitializationErrors, initialize, reset };
