import { fromJS, Set } from 'immutable';
import { createStore, applyMiddleware } from 'redux';
import { createTransform, persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import logger from './logger';
import reducers from './reducers';
import sagas from './sagas';
import { initialState } from './Authentication/reducer';

const whitelist = ['authentication'];

const immutableTransform = createTransform(
  (state) => state.toJS && state.toJS(),
  (state) => {
    const { location: { pathname } } = window;
    const isRoot = pathname.length < 2;
    const hasToken = !!initialState.get('authToken');
    const isMigrate = !!initialState.get('cevOrgName');
    // don't rehydrate if we're at root, or have a token in the url, or initiated a migration;
    if (isRoot || hasToken || isMigrate) {
      return initialState;
    }

    const rehydrate = Set([
      'authToken',
      'activeCourse',
      'settings',
      'userRole',
      'viewAsStudent',
      'cevOrgID',
      'cevOrgName',
      'cevOrgUser',
      'teacher_context',
      'courseMigration',
    ]);
    return initialState.merge(fromJS(state).filter((v, key) => rehydrate.has(key)));
  },
  whitelist,
);

const persistConfig = {
  key: 'root',
  storage,
  whitelist,
  transforms: [immutableTransform],
};

const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const allMiddleware = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'staging'
    ? [sagaMiddleware, logger]
    : [sagaMiddleware];
  const store = createStore(
    persistReducer(persistConfig, reducers),
    applyMiddleware(...allMiddleware),
  );

  sagaMiddleware.run(sagas);

  // Clear Query Params - They are pulled into initialState by reducers
  const baseLocation = window.location.href.split('?')[0];
  window.history.replaceState({}, 'Applied Educational Systems', baseLocation);

  const persistor = persistStore(store);

  return { store, persistor };
};

export default configureStore;
