import React from 'react';
import PropTypes from 'prop-types';
import './Alert.scss';
import Icon from '@mdi/react';
import { mdiAlert, mdiAlertCircleOutline, mdiCheck } from '@mdi/js';
import Icons from './Icon';

const getIconPath = (type = '') => {
  if (type.includes('success')) {
    return mdiCheck;
  }
  if (type.includes('warning')) {
    return mdiAlert;
  }
  return mdiAlertCircleOutline;
};

const Alert = ({
  children,
  message,
  type,
  onClickCloseAlert,
  actions,
}) => (
  <div className={`alert ${type}`} role="alert">
    <Icon path={getIconPath(type)} />
    <p>{children || message}</p>
    { actions
      ? <div className="alert-actions">{ actions }</div>
      : null }
    { onClickCloseAlert ? (
      <button
        type="button"
        className="icon close"
        onClick={onClickCloseAlert}
      >
        <Icons text="close" />
      </button>
    ) : null }
  </div>
);

Alert.propTypes = {
  actions: PropTypes.node,
  children: PropTypes.node,
  message: PropTypes.string,
  type: PropTypes.string,
  onClickCloseAlert: PropTypes.func,
};

Alert.defaultProps = {
  actions: null,
  children: null,
  message: '',
  type: 'error',
  onClickCloseAlert: undefined,
};

export default Alert;
