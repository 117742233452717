import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List } from 'immutable';
import ActivationEmail from '../images/activation-email@2x.png';
import '../components/Migration.scss';
import ButtonLink from '../components/ButtonLink';

const { REACT_APP_ICEV_LOGIN } = process.env;

const MigrationEmailReceived = ({ courseCount }) => (
  <div className="confirm-email-received">
    <div className="confirm-email">
      <h1>Activated Your Account Yet?</h1>
      <p>
        Please check your inbox for an email from iCEV and click on the
        {' '}
        “Getting Started” link to activate your iCEV account!
      </p>
      <img src={ActivationEmail} alt="screenshot of email activation" />
      <ButtonLink
        classname="submit"
        href={`${REACT_APP_ICEV_LOGIN}/mycourses/added?newCoursesAdded=${courseCount}`}
        text="Yes, Continue to iCEV"
      />
    </div>
  </div>
);

MigrationEmailReceived.propTypes = { courseCount: PropTypes.number.isRequired };

const mapStateToProps = ({ view }) => {
  const migrationCoursesSelected = view.get('migrationCoursesSelected') || List();
  const courseCount = migrationCoursesSelected.size;

  return { courseCount };
};

export default connect(mapStateToProps)(MigrationEmailReceived);
