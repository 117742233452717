import React from 'react';
import PropType from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import ModuleIcon from './ModuleIcon';
import Icon from './Icon';
import './Header.scss';

const Header = ({ title, moduleKey, noControls }) => {
  const { pathname } = useLocation();
  const backSegments = pathname.split('/').slice(0, -3);
  const backTo = `${backSegments.join('/')}/`;

  return (
    <div className="module-overview">
      <div className="container">
        <ModuleIcon className="icon" moduleKey={moduleKey} />
        {!noControls ? (
          <Link to={backTo} relative="path" className="button icon">
            <Icon text="back" />
            <span className="tooltip">Back</span>
          </Link>
        ) : null}
        <h1 className={`${noControls ? 'space' : ''}`}>{title}</h1>
      </div>
    </div>
  );
};

Header.propTypes = {
  moduleKey: PropType.string.isRequired,
  title: PropType.string.isRequired,
  noControls: PropType.bool,
};

Header.defaultProps = {
  noControls: false,
};

export default Header;
