import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import logo from '../images/aes-logo-reverse.svg';
import Icon from './Icon';
import './Menu.scss';
import FocusTrap from './FocusTrap';
import useRouteMatch from '../hooks/useRouteMatch';

const Menu = ({
  name, courses, show, isStudent, viewAsStudent, toggleVisible,
}) => {
  const navigate = useNavigate();
  const match = useRouteMatch('/site/:siteId/course/:courseId/:moduleOrExam?/:moduleKey?/:unitOrTest?/:unitKey?/:lessonOrQuiz?/:pageSetKey?');
  let returnUrl = isStudent
    ? '/courses/'
    : '/';

  if (match) {
    const { params: { siteId, courseId, lessonOrQuiz } } = match;

    if (lessonOrQuiz === 'lesson') {
      return null;
    }

    if (viewAsStudent) {
      returnUrl = `/site/${siteId}/course/${courseId}/`;
    }
  }

  const signOutHandler = () => {
    toggleVisible();
    navigate('/signout');
  };

  return (
    <header className="App-header">
      <button type="button" onClick={toggleVisible} className="icon menuIcon">
        <Icon text="menu" />
      </button>
      <div className={`menuContainer ${show ? 'show' : ''}`}>
        <FocusTrap updateElements={show}>
          <div className="menu">
            {isStudent
              ? (
                <>
                  <h1>My Classes</h1>
                  <ul>
                    {courses.map((course) => {
                      const title = course.get('course_title');
                      const siteId = course.get('site_id');
                      const id = course.get('course_id');
                      const firstItem = courses.keySeq().first();
                      return Number(firstItem) === Number(id) ? (
                        <li key={id}>
                          <Link
                            onClick={toggleVisible}
                            to={`/site/${siteId}/course/${id}/`}
                          >
                            <p>{title}</p>
                          </Link>
                        </li>

                      )
                        : (
                          <li key={id}>
                            <Link
                              onClick={toggleVisible}
                              to={`/site/${siteId}/course/${id}/`}
                            >
                              <p>{title}</p>
                            </Link>
                          </li>
                        );
                    })
                      .toList()}
                  </ul>
                  <span className="line" />
                  <ul className="second">
                    <li><Link onClick={toggleVisible} to="/settings"><p>Settings</p></Link></li>
                  </ul>
                </>
              )
              : null}
            <button type="button" onClick={signOutHandler} className="sign-out">
              Sign Out
            </button>
            <button type="button" onClick={toggleVisible} className="icon close">
              <Icon text="close" />
            </button>
          </div>
        </FocusTrap>
      </div>
      <Link to={returnUrl} className="home-link">
        <img src={logo} className="App-logo" alt="Applied Educational Systems" />
      </Link>
      <Link to="/settings" className="user-name">
        {name}
      </Link>
    </header>
  );
};

Menu.propTypes = {
  name: PropTypes.string.isRequired,
  courses: PropTypes.instanceOf(Map).isRequired,
  show: PropTypes.bool.isRequired,
  isStudent: PropTypes.bool.isRequired,
  viewAsStudent: PropTypes.bool.isRequired,
  toggleVisible: PropTypes.func.isRequired,
};

export default Menu;
