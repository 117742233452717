import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Icon from './Icon';
import { getClosedType } from '../utils/pacing';
import './MessageSection.scss';

const ClosedMessage = ({
  reasonCopy,
  classInfo,
  messageType,
}) => {
  const { icon, statusClass } = getClosedType(reasonCopy, true);

  const copyHandler = () => {
    switch (statusClass) {
      case 'closed':
        return `Looks like this ${messageType} was closed by the teacher`;

      case 'closed due-passed':
        return `Looks like this ${messageType} reached it’s due date`;

      case 'blocked':
        return `Looks like you need to complete another ${messageType} before this one`;

      default:
        return '';
    }
  };

  return (
    <section className="message-section">
      <div className={`message-inner ${statusClass}`}>
        <Icon text={icon} size="48px" />
        <h1>Uh-oh!</h1>
        <p>{copyHandler()}</p>
        <Link to="../../" relative="path">
          Back to
          {' '}
          {classInfo}
        </Link>
      </div>
    </section>
  );
};

ClosedMessage.propTypes = {
  messageType: PropTypes.oneOf(['module', 'unit', 'quiz', 'test', 'exam']).isRequired,
  reasonCopy: PropTypes.string.isRequired,
  classInfo: PropTypes.string.isRequired,
};

export default ClosedMessage;
