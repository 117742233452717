import React from 'react';
import PropTypes from 'prop-types';
import Input from './Input';

const EnrollmentSingleSignOnForm = ({
  enrollmentCode,
  setEnrollmentCode,
  onSubmitEnrollment,
  enrollmentError,
}) => (
  <div className="sign-in-form">
    <h2>Student Enrollment</h2>
    <p>
      Please enter the enrollment code
      <br />
      provided by your teacher.
    </p>
    <form onSubmit={onSubmitEnrollment} autoComplete="off">
      <Input
        name="enrollmentCode"
        placeholder="Enter Enrollment Code"
        value={enrollmentCode}
        error={enrollmentError ? 'The enrollment code is closed or invalid.' : ''}
        onChange={({ target: { value } }) => setEnrollmentCode(value)}
      />
      <button type="submit" className="primary">Enroll</button>
    </form>
  </div>
);

EnrollmentSingleSignOnForm.propTypes = {
  enrollmentError: PropTypes.string,
  enrollmentCode: PropTypes.string,
  setEnrollmentCode: PropTypes.func.isRequired,
  onSubmitEnrollment: PropTypes.func.isRequired,
};

EnrollmentSingleSignOnForm.defaultProps = {
  enrollmentError: null,
  enrollmentCode: '',
};

export default EnrollmentSingleSignOnForm;
