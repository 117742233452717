import React from 'react';
import PropTypes from 'prop-types';
import Input from './Input';

const RegisterEmailTaken = ({ email, onClickLogin, onClickChange }) => (
  <>
    <h3 className="enrollment">Oops!</h3>
    <form onSubmit={() => false}>
      <Input
        name="email"
        value={email}
        error="This username is already registered"
        disabled
      />
    </form>
    <h3>How would you like to continue?</h3>
    <button className="primary" type="button" onClick={onClickLogin}>
      {`Continue with ${email}`}
    </button>
    <div className="separator gutter">OR</div>
    <button className="secondary" type="button" onClick={onClickChange}>
      Use a different username or email
    </button>
  </>
);

RegisterEmailTaken.propTypes = {
  email: PropTypes.string.isRequired,
  onClickLogin: PropTypes.func.isRequired,
  onClickChange: PropTypes.func.isRequired,
};

export default RegisterEmailTaken;
