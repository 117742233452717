export const DATA_ASSESSMENT_REQUEST = 'DATA_ASSESSMENT_REQUEST';
export const DATA_ASSESSMENT_FINISH = 'DATA_ASSESSMENT_FINISH';
export const DATA_ASSESSMENT_FINISH_JOB = 'DATA_ASSESSMENT_FINISH_JOB';
export const DATA_ASSESSMENT_SUCCESS = 'DATA_ASSESSMENT_SUCCESS';
export const DATA_ASSESSMENT_ERROR = 'DATA_ASSESSMENT_ERROR';
export const DATA_ASSESSMENT_SAVE = 'DATA_ASSESSMENT_SAVE';
export const DATA_ASSESSMENT_SAVE_SUCCESS = 'DATA_ASSESSMENT_SAVE_SUCCESS';
export const DATA_ASSESSMENT_RESULTS_CLEAR = 'DATA_ASSESSMENT_RESULTS_CLEAR';
export const DATA_ASSESSMENT_RESULTS_REQUEST = 'DATA_ASSESSMENT_RESULTS_REQUEST';
export const DATA_ASSESSMENT_RESULTS_SUCCESS = 'DATA_ASSESSMENT_RESULTS_SUCCESS';
export const DATA_ASSESSMENT_RESULTS_ERROR = 'DATA_ASSESSMENT_RESULTS_ERROR';
export const DATA_ASSESSMENT_WORK_UPDATED = 'DATA_ASSESSMENT_WORK_UPDATED';
export const DATA_ASSESSMENT_TIMER_UPDATED = 'DATA_ASSESSMENT_TIMER_UPDATED';
export const DATA_ASSESSMENT_REQUEST_TTS = 'DATA_ASSESSMENT_REQUEST_TTS';
export const DATA_ASSESSMENT_REQUEST_TTS_JOB = 'DATA_ASSESSMENT_REQUEST_TTS_JOB';
export const DATA_ASSESSMENT_REQUEST_TTS_SUCCESS = 'DATA_ASSESSMENT_REQUEST_TTS_SUCCESS';
export const DATA_ASSESSMENT_REQUEST_TTS_ERROR = 'DATA_ASSESSMENT_REQUEST_TTS_ERROR';
export const DATA_ASSESSMENT_LOCKED_ASSESSMENT_REQUEST = 'DATA_ASSESSMENT_LOCKED_ASSESSMENT_REQUEST';
export const DATA_ASSESSMENT_LOCKED_ASSESSMENT_ERROR = 'DATA_ASSESSMENT_LOCKED_ASSESSMENT_ERROR';
export const DATA_CHALLENGE_REQUEST = 'DATA_CHALLENGE_REQUEST';
export const DATA_CHALLENGE_SUCCESS = 'DATA_CHALLENGE_SUCCESS';
export const DATA_CHALLENGE_ERROR = 'DATA_CHALLENGE_ERROR';
export const DATA_CHALLENGE_ANSWER_REQUEST = 'DATA_CHALLENGE_ANSWER_REQUEST';
export const DATA_CHALLENGE_ANSWER_ERROR = 'DATA_CHALLENGE_ANSWER_ERROR';
export const DATA_CHALLENGE_SKIP = 'DATA_CHALLENGE_SKIP';
export const DATA_CHALLENGE_COMPLETE = 'DATA_CHALLENGE_COMPLETE';
export const DATA_CHALLENGE_RESULTS_REQUEST = 'DATA_CHALLENGE_RESULTS_REQUEST';
export const DATA_CHALLENGE_RESULTS_SUCCESS = 'DATA_CHALLENGE_RESULTS_SUCCESS';
export const DATA_COURSE_REQUEST = 'DATA_COURSE_REQUEST';
export const DATA_COURSE_SUCCESS = 'DATA_COURSE_SUCCESS';
export const DATA_COURSE_ERROR = 'DATA_COURSE_ERROR';
export const DATA_SITE_DATA_RECEIVED = 'DATA_SITE_DATA_RECEIVED';
export const DATA_SITE_LIST_RECEIVED = 'DATA_SITE_LIST_RECEIVED';
export const DATA_KEYBOARDING_RESULTS_REQUEST = 'DATA_KEYBOARDING_RESULTS_REQUEST';
export const DATA_KEYBOARDING_RESULTS_SUCCESS = 'DATA_KEYBOARDING_RESULTS_SUCCESS';
export const DATA_KEYBOARDING_RESULTS_ERROR = 'DATA_KEYBOARDING_RESULTS_ERROR';
export const DATA_LESSON_REQUEST = 'DATA_LESSON_REQUEST';
export const DATA_LESSON_RESET_REQUEST = 'DATA_LESSON_RESET_REQUEST';
export const DATA_LESSON_RESET_ERROR = 'DATA_LESSON_RESET_ERROR';
export const DATA_LESSON_SUCCESS = 'DATA_LESSON_SUCCESS';
export const DATA_LESSON_ERROR = 'DATA_LESSON_ERROR';
export const DATA_LESSON_WORK_UPDATED = 'DATA_LESSON_WORK_UPDATED';
export const DATA_LESSON_WORK_ERROR = 'DATA_LESSON_WORK_ERROR';
export const DATA_LOCKED_ASSESSMENT_ID_UPDATED = 'DATA_LOCKED_ASSESSMENT_ID_UPDATED';
export const DATA_MODULE_REQUEST = 'DATA_MODULE_REQUEST';
export const DATA_LOG_EVENT = 'DATA_LOG_EVENT';
export const DATA_CLEAR = 'DATA_CLEAR';
export const DATA_ERROR_INCREMENT = 'DATA_ERROR_INCREMENT';
export const DATA_ERROR_CLEAR = 'DATA_ERROR_CLEAR';
export const DATA_TEACHER_COURSES_SUCCESS = 'DATA_TEACHER_COURSES_SUCCESS';
export const DATA_MIGRATION_REQUEST = 'DATA_MIGRATION_REQUEST';
export const DATA_MIGRATION_REQUEST_ERROR = 'DATA_MIGRATION_REQUEST_ERROR';
export const DATA_MIGRATION_REQUEST_JOB = 'DATA_MIGRATION_REQUEST_JOB';
export const DATA_MIGRATION_REQUEST_STATUS = 'DATA_MIGRATION_REQUEST_STATUS';

export const requestAssessmentData = (siteId, courseId, moduleKey, unitKey) => ({
  type: DATA_ASSESSMENT_REQUEST,
  siteId,
  courseId,
  moduleKey,
  unitKey,
});

export const requestAssessmentDataSucceeded = (
  assignmentId,
  assignmentEntryId,
  pageSet,
) => ({
  type: DATA_ASSESSMENT_SUCCESS,
  assignmentId,
  assignmentEntryId,
  pageSet,
});

export const requestAssessmentDataFailed = (assignmentId, assignmentEntryId, error, code) => ({
  type: DATA_ASSESSMENT_ERROR,
  assignmentId,
  assignmentEntryId,
  error,
  code,
});

export const clearAssessmentResults = (
  assignmentId,
  assignmentEntryId,
) => ({
  type: DATA_ASSESSMENT_RESULTS_CLEAR,
  assignmentId,
  assignmentEntryId,
});

export const requestAssessmentResults = (siteId, courseId, moduleKey, unitKey) => ({
  type: DATA_ASSESSMENT_RESULTS_REQUEST,
  courseId,
  moduleKey,
  siteId,
  unitKey,
});

export const requestAssessmentResultsSucceeded = (assignmentId, assignmentEntryId, results) => ({
  type: DATA_ASSESSMENT_RESULTS_SUCCESS,
  assignmentId,
  assignmentEntryId,
  results,
});

export const requestAssessmentResultsFailed = (assignmentId, assignmentEntryId, error, code) => ({
  type: DATA_ASSESSMENT_RESULTS_ERROR,
  assignmentId,
  assignmentEntryId,
  error,
  code,
});

export const requestQuestionTts = (siteId, question) => ({
  type: DATA_ASSESSMENT_REQUEST_TTS,
  siteId,
  question,
});

export const requestQuestionTtsJob = (siteId, question, ttsJobId) => ({
  type: DATA_ASSESSMENT_REQUEST_TTS_JOB,
  siteId,
  question,
  ttsJobId,
});

export const requestQuestionTtsSucceeded = (key, ttsUrl) => ({
  type: DATA_ASSESSMENT_REQUEST_TTS_SUCCESS,
  key,
  ttsUrl,
});

export const requestQuestionTtsFailed = (key, error, code) => ({
  type: DATA_ASSESSMENT_REQUEST_TTS_ERROR,
  key,
  error,
  code,
});

export const requestLockedAssessmentData = (siteId, lessonWorkId) => ({
  type: DATA_ASSESSMENT_LOCKED_ASSESSMENT_REQUEST,
  siteId,
  lessonWorkId,
});

export const requestLockedAssessmentDataFailed = (siteId, lessonWorkId, error, code) => ({
  type: DATA_ASSESSMENT_LOCKED_ASSESSMENT_ERROR,
  siteId,
  lessonWorkId,
  error,
  code,
});

export const requestChallengeData = (siteId, courseId, reset = false) => ({
  type: DATA_CHALLENGE_REQUEST,
  siteId,
  courseId,
  reset,
});

export const requestChallengeDataSucceeded = (siteId, courseId, challengeData) => ({
  type: DATA_CHALLENGE_SUCCESS,
  siteId,
  courseId,
  challengeData,
});

export const requestChallengeDataFailed = (siteId, courseId, error, code) => ({
  type: DATA_CHALLENGE_ERROR,
  siteId,
  courseId,
  error,
  code,
});

export const requestChallengeResults = (siteId, courseId) => ({
  type: DATA_CHALLENGE_RESULTS_REQUEST,
  siteId,
  courseId,
});

export const requestChallengeResultsSucceeded = (siteId, courseId, results) => ({
  type: DATA_CHALLENGE_RESULTS_SUCCESS,
  siteId,
  courseId,
  results,
});

export const setChallengeAttempt = (siteId, courseId, itemKey, attempts, correct) => ({
  type: DATA_CHALLENGE_ANSWER_REQUEST,
  siteId,
  courseId,
  itemKey,
  attempts,
  correct,
});

export const setChallengeAnswerFailed = (siteId, courseId, error, code) => ({
  type: DATA_CHALLENGE_ANSWER_ERROR,
  siteId,
  courseId,
  error,
  code,
});

export const setChallengeSkipped = (siteId, courseId) => ({
  type: DATA_CHALLENGE_SKIP,
  siteId,
  courseId,
});

export const setChallengeComplete = (siteId, courseId) => ({
  type: DATA_CHALLENGE_COMPLETE,
  siteId,
  courseId,
});

export const requestCourseData = (siteId, courseId, viewAsStudent) => ({
  type: DATA_COURSE_REQUEST,
  siteId,
  courseId,
  viewAsStudent,
});

export const requestCourseDataSucceeded = (siteId, courseId, courseData) => ({
  type: DATA_COURSE_SUCCESS,
  siteId,
  courseId,
  courseData,
});

export const requestCourseDataFailed = (siteId, courseId, error, code) => ({
  type: DATA_COURSE_ERROR,
  siteId,
  courseId,
  error,
  code,
});

export const requestModuleData = (siteId, courseId, moduleKey) => ({
  type: DATA_MODULE_REQUEST,
  siteId,
  courseId,
  moduleKey,
});

export const requestLessonData = (siteId, courseId, moduleKey, unitKey) => ({
  type: DATA_LESSON_REQUEST,
  siteId,
  courseId,
  moduleKey,
  unitKey,
});

export const requestLesssonDataSucceeded = (siteId, courseId, moduleKey, unitKey, lessonData) => ({
  type: DATA_LESSON_SUCCESS,
  siteId,
  courseId,
  moduleKey,
  unitKey,
  lessonData,
});

export const requestLesssonDataFailed = (siteId, courseId, moduleKey, unitKey, error, code) => ({
  type: DATA_LESSON_ERROR,
  siteId,
  courseId,
  moduleKey,
  unitKey,
  error,
  code,
});

export const resetLesson = (siteId, courseId, moduleKey, unitKey, pageSetKey) => ({
  type: DATA_LESSON_RESET_REQUEST,
  siteId,
  courseId,
  moduleKey,
  unitKey,
  pageSetKey,
});

export const resetLessonFailed = (
  siteId,
  courseId,
  moduleKey,
  unitKey,
  pageSetKey,
  error,
  code,
) => ({
  type: DATA_LESSON_RESET_ERROR,
  siteId,
  courseId,
  moduleKey,
  unitKey,
  pageSetKey,
  error,
  code,
});

export const requestKeyboardingResults = (siteId, courseId) => ({ // action creator
  type: DATA_KEYBOARDING_RESULTS_REQUEST,
  siteId,
  courseId,
});

export const requestKeyboardingResultsSucceeded = (siteId, courseId, results) => ({
  type: DATA_KEYBOARDING_RESULTS_SUCCESS,
  siteId,
  courseId,
  results,
});

export const requestKeyboardingResultsFailed = (courseId, error, code) => ({
  type: DATA_KEYBOARDING_RESULTS_ERROR,
  courseId,
  error,
  code,
});

export const receivedSiteData = (siteId, siteData) => ({
  type: DATA_SITE_DATA_RECEIVED,
  siteId,
  siteData,
});

export const receivedSites = (sites, expiredRoles) => ({
  type: DATA_SITE_LIST_RECEIVED,
  sites,
  expiredRoles,
});

export const finishAssessment = (learnositySession) => ({
  type: DATA_ASSESSMENT_FINISH,
  learnositySession,
});

export const requestFinishAssessmentJob = (jobId) => ({
  type: DATA_ASSESSMENT_FINISH_JOB,
  jobId,
});

export const initiateAssessmentSave = (
  siteId,
  courseId,
  moduleKey,
  unitKey,
) => ({
  type: DATA_ASSESSMENT_SAVE,
  siteId,
  courseId,
  moduleKey,
  unitKey,
});

export const assessmentSaveSucceeded = (
  siteId,
  courseId,
  moduleKey,
  unitKey,
) => ({
  type: DATA_ASSESSMENT_SAVE_SUCCESS,
  siteId,
  courseId,
  moduleKey,
  unitKey,
});

export const updateAssessmentWork = (
  siteId,
  courseId,
  moduleKey,
  unitKey,
  assessmentWork,
) => ({
  type: DATA_ASSESSMENT_WORK_UPDATED,
  siteId,
  courseId,
  moduleKey,
  unitKey,
  assessmentWork,
});

export const updateLessonWork = (
  siteId,
  courseId,
  moduleKey,
  unitKey,
  pageSetKey,
  lessonWorkData,
) => ({
  type: DATA_LESSON_WORK_UPDATED,
  siteId,
  courseId,
  moduleKey,
  unitKey,
  pageSetKey,
  lessonWorkData,
});

export const lessonWorkFailed = (error, code) => ({
  type: DATA_LESSON_WORK_ERROR,
  error,
  code,
});

export const updateLockedAssessmentId = (lockedAssessmentId) => ({
  type: DATA_LOCKED_ASSESSMENT_ID_UPDATED,
  lockedAssessmentId,
});

export const updateQuizRetry = (
  siteId,
  courseId,
  moduleKey,
  unitKey,
  attemptTimer,
) => ({
  type: DATA_ASSESSMENT_TIMER_UPDATED,
  siteId,
  courseId,
  moduleKey,
  unitKey,
  attemptTimer,
});

export const logEvent = (siteId, courseId, actionType, params) => ({
  type: DATA_LOG_EVENT,
  siteId,
  courseId,
  actionType,
  params,
});

export const clearData = () => ({ type: DATA_CLEAR });

export const incrementErrorCount = () => ({ type: DATA_ERROR_INCREMENT });

export const clearErrors = () => ({ type: DATA_ERROR_CLEAR });

export const teacherCoursesSucceeded = (siteCourses) => ({
  type: DATA_TEACHER_COURSES_SUCCESS,
  siteCourses,
});

export const requestMigration = () => ({
  type: DATA_MIGRATION_REQUEST,
});

export const requestMigrationFailed = (error, code) => ({
  type: DATA_MIGRATION_REQUEST_ERROR,
  error,
  code,
});

export const requestMigrationJob = (migrationCoursesSelected) => ({
  type: DATA_MIGRATION_REQUEST_JOB,
  migrationCoursesSelected,
});

export const requestMigrationStatus = (jobId) => ({
  type: DATA_MIGRATION_REQUEST_STATUS,
  jobId,
});
