import React from 'react';
import PropTypes from 'prop-types';
import Progress from './Progress';
import './LoadingSection.scss';

const LoadingSection = ({ message }) => (
  <section className="loading">
    { message
      ? <h2>{message}</h2>
      : null }
    <Progress />
  </section>
);

LoadingSection.propTypes = { message: PropTypes.string };
LoadingSection.defaultProps = { message: '' };

export default LoadingSection;
