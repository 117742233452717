import React from 'react';
import PropTypes from 'prop-types';
import { OrderedMap } from 'immutable';
import Icon from './Icon';

const getTypeName = (key) => {
  switch (key) {
    case 'Lesson Form':
      return 'Lesson Forms';
    case 'Checklist':
      return 'Skills Checklists';
    default:
      return key;
  }
};

const ResourceLists = ({ resources }) => (
  <div className="itemContainer resources">
    {resources.map((files, key) => (
      // false positive, this is a Map key, not an array index
      // eslint-disable-next-line react/no-array-index-key
      <table key={key} className="resourcesList">
        <thead>
          <tr className="header">
            <th>
              <h3>{getTypeName(key)}</h3>
            </th>
          </tr>
        </thead>
        <tbody>
          { files.map((data) => {
            const title = data.get('title');
            const url = data.get('url');
            const fileKey = url || title;
            return (
              <tr key={fileKey} className={url ? 'resource' : 'blocked'}>
                <td className="lessonTitle">
                  <div className="icon">
                    <Icon text={!url ? 'lock' : ''} />
                  </div>
                  <h3>
                    { url
                      ? <a href={url} target="_blank" rel="noreferrer">{title}</a>
                      : title }
                  </h3>
                </td>
              </tr>
            );
          }) }
        </tbody>
      </table>
    )).toList()}
  </div>
);

ResourceLists.propTypes = {
  resources: PropTypes.instanceOf(OrderedMap).isRequired,
};

export default ResourceLists;
