import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import LoadingSection from './LoadingSection';
import AssessmentLearnosity from './AssessmentLearnosity';

class ChallengeQuestions extends React.Component {
  // eslint-disable-next-line class-methods-use-this
  onSetAttempt(question, attempts, itemsApp) {
    question.validate({ feedbackAttempts: true });
    if (question.isValid()) {
      const { onSetAnswer, challengeData } = this.props;
      const questionCount = challengeData.get('questionCount');
      const attemptedQuestions = itemsApp.attemptedItems()?.length;
      const hasNextQuestion = attemptedQuestions < questionCount;
      const { reference } = itemsApp.getCurrentItem();

      question.disable();

      setTimeout(() => {
        onSetAnswer(reference, attempts, true);
        if (hasNextQuestion) {
          itemsApp.items()?.next();
        }
      }, 1500);
    }
  }

  render() {
    const {
      challengeData,
      ttsData,
      onComplete,
      requestTts,
    } = this.props;
    const learnosityRequest = challengeData.get('learnosityRequest');
    const questionCount = challengeData.get('questionCount');
    const questions = challengeData.get('questions');
    const score = challengeData.get('score');

    return (learnosityRequest
      ? (
        <div className="quiz-challenge">
          <h2>{`Your Score: ${score} points`}</h2>
          <h3>{`Question ${questions.size + 1} of ${questionCount}`}</h3>

          <AssessmentLearnosity
            requestString={learnosityRequest}
            ttsData={ttsData}
            finishAssessment={onComplete}
            setAttempt={(question, attempts, itemsApp) => {
              this.onSetAttempt(question, attempts, itemsApp);
            }}
            requestTts={requestTts}
          />
        </div>
      )
      : <LoadingSection />
    );
  }
}

ChallengeQuestions.propTypes = {
  challengeData: PropTypes.instanceOf(Map).isRequired,
  ttsData: PropTypes.instanceOf(Map),
  onComplete: PropTypes.func.isRequired,
  onSetAnswer: PropTypes.func.isRequired,
  requestTts: PropTypes.func.isRequired,
};

ChallengeQuestions.defaultProps = {
  ttsData: Map(),
};

export default ChallengeQuestions;
