import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, Navigate } from 'react-router-dom';
import { Map } from 'immutable';
import connectWithRouterMatch from '../redux/connectWithRouterMatch';
import {
  authenticateClearEmailVerification,
  authenticateVerifyEmail,
  authenticateVerifyEnrollmentCode,
  createNewStudent,
} from '../redux/Authentication/actions';
import { redirectTo } from '../redux/View/actions';
import { getAuthToken } from '../redux/Authentication/selectors';
import { findError } from '../utils/errors';
import './SignIn.scss';
import RegisterForm from '../components/RegisterForm';
import Input from '../components/Input';
import AESLogo from '../components/AESLogo';

class RegisterNewStudent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      passwordConfirmation: '',
      enrollmentCode: '',
    };
  }

  componentDidMount() {
    const { dispatchClearEmailVerification, urlEnrollmentCode, dispatchVerifyCode } = this.props;
    dispatchClearEmailVerification();
    if (urlEnrollmentCode) {
      dispatchVerifyCode(urlEnrollmentCode);
      this.setState({ enrollmentCode: urlEnrollmentCode });
    }
  }

  onChangeValue(event) {
    const { target: { name, value } } = event;
    this.setState({ [name]: value });
  }

  onClickChange() {
    const { dispatchClearEmailVerification } = this.props;
    this.setState({ email: '' });
    dispatchClearEmailVerification();
  }

  onClickLogin() {
    const { dispatchRedirect } = this.props;
    dispatchRedirect('/enroll/login');
  }

  onSubmitVerifyEmail(event) {
    const { email } = this.state;
    const { dispatchVerifyEmail } = this.props;
    event.preventDefault();
    dispatchVerifyEmail(email);
  }

  onSubmitNewStudent(event) {
    const { dispatchCreateNewStudent } = this.props;
    const {
      email,
      firstName,
      lastName,
      password,
      passwordConfirmation,
      enrollmentCode,
    } = this.state;
    event.preventDefault();
    dispatchCreateNewStudent({
      email,
      firstName,
      lastName,
      password,
      passwordConfirmation,
      enrollmentCode,
    });
  }

  onSubmitEnrollment(e) {
    const { dispatchVerifyCode } = this.props;
    const { enrollmentCode } = this.state;
    e.preventDefault();
    dispatchVerifyCode(enrollmentCode);
  }

  signInGoogle(googleResponse) {
    const { dispatchCreateNewStudent } = this.props;
    const { enrollmentCode } = this.state;
    const { credential } = googleResponse;

    dispatchCreateNewStudent({
      provider: 'google',
      providerToken: credential,
      enrollmentCode,
    });
  }

  render() {
    const {
      enrollmentCode,
    } = this.state;

    const {
      authToken,
      emailAvailable,
      emailVerified,
      verifiedEnrollmentCode,
      enrollmentError,
      newStudentErrors,
      newErrorValues,
    } = this.props;

    if (authToken) {
      return <Navigate to="/student" />;
    }

    return (
      <>
        <AESLogo />
        <div className="sign-in-form">
          {verifiedEnrollmentCode
            ? (
              <RegisterForm
                emailAvailable={emailAvailable}
                emailVerified={emailVerified}
                values={this.state}
                errors={newStudentErrors}
                errorValues={newErrorValues}
                onChangeValue={(event) => this.onChangeValue(event)}
                onClickLogin={() => this.onClickLogin()}
                onClickChange={() => this.onClickChange()}
                onGoogleLogin={(response) => this.signInGoogle(response)}
                onSubmitNewStudent={(event) => this.onSubmitNewStudent(event)}
                onSubmitVerifyEmail={(event) => this.onSubmitVerifyEmail(event)}
              />
            )
            : (
              <form onSubmit={(e) => this.onSubmitEnrollment(e)}>
                <h3 className="enrollment">Enter your Enrollment Code</h3>
                <Input
                  name="enrollmentCode"
                  id="enrollmentCode"
                  value={enrollmentCode}
                  onChange={({ target: { value } }) => this.setState({ enrollmentCode: value })}
                  placeholder="Enter Enrollment Code"
                  error={enrollmentError.message}
                />
                <button
                  type="submit"
                  className="primary"
                >
                  Next
                </button>
                <Link className="signinLink" to="/">Already have an account? Sign in</Link>
              </form>
            )}
        </div>
      </>
    );
  }
}

RegisterNewStudent.propTypes = {
  authToken: PropTypes.string,
  dispatchCreateNewStudent: PropTypes.func.isRequired,
  dispatchVerifyCode: PropTypes.func.isRequired,
  dispatchVerifyEmail: PropTypes.func.isRequired,
  dispatchClearEmailVerification: PropTypes.func.isRequired,
  dispatchRedirect: PropTypes.func.isRequired,
  emailAvailable: PropTypes.bool,
  emailVerified: PropTypes.string,
  verifiedEnrollmentCode: PropTypes.string,
  enrollmentError: PropTypes.string,
  newStudentErrors: PropTypes.instanceOf(Map),
  newErrorValues: PropTypes.instanceOf(Map),
  urlEnrollmentCode: PropTypes.string,
};

RegisterNewStudent.defaultProps = {
  authToken: '',
  emailAvailable: false,
  emailVerified: '',
  verifiedEnrollmentCode: '',
  urlEnrollmentCode: '',
  enrollmentError: '',
  newStudentErrors: Map(),
  newErrorValues: Map(),
};

const mapStateToProps = (state, { match: { params } }) => {
  const { '*': enrollmentCode } = params;
  const { authentication } = state;
  return {
    authToken: getAuthToken(state),
    emailAvailable: authentication.get('emailAvailable'),
    emailVerified: authentication.get('emailVerified'),
    verifiedEnrollmentCode: authentication.get('enrollmentCode'),
    enrollmentError: findError(authentication.get('enrollmentError') || ''),
    newStudentErrors: authentication.get('newStudentErrors'),
    newErrorValues: authentication.get('newErrorValues'),
    urlEnrollmentCode: enrollmentCode,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchRedirect: (path) => dispatch(redirectTo(path)),
  dispatchVerifyCode: (code) => dispatch(authenticateVerifyEnrollmentCode(code)),
  dispatchVerifyEmail: (email) => dispatch(authenticateVerifyEmail(email)),
  dispatchClearEmailVerification: () => dispatch(authenticateClearEmailVerification()),
  dispatchCreateNewStudent: (data) => dispatch(createNewStudent(data)),
});

export default connectWithRouterMatch(mapStateToProps, mapDispatchToProps)(RegisterNewStudent);
