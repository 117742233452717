import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Map } from 'immutable';
import './KeyboardGameResults.scss';
import KeyboardingTables from './KeyboardingTables';
import { formatAssessmentDate } from '../utils/date';
import Icon from './Icon';

const renderGlobalLeaders = (globalLeaders) => (
  <>
    <h2>Global Leaders</h2>
    <table>
      <thead>
        <tr>
          <td className="rank">Rank</td>
          <td>Player</td>
          <td>State</td>
          <td className="score">Score</td>
        </tr>
      </thead>
      <tbody>
        {globalLeaders?.isEmpty()
          ? null
          : globalLeaders?.map((leader, idx) => (
            /* eslint-disable react/no-array-index-key */
            <KeyboardingTables key={idx + leader.get('name')} num={idx + 1} rowData={leader} />
          ))}
      </tbody>
    </table>
  </>
);

const renderClassLeaders = (courseLeaders) => (
  <>
    <h2>Class Leaderboard</h2>
    <table>
      <thead>
        <tr>
          <td className="rank">Rank</td>
          <td>Player</td>
          <td className="score">Score</td>
        </tr>
      </thead>
      <tbody>
        {courseLeaders?.isEmpty()
          ? null
          : courseLeaders?.map((leader, idx) => (
            /* eslint-disable react/no-array-index-key */
            <KeyboardingTables key={idx + leader.get('name')} num={idx + 1} rowData={leader} />
          ))}
      </tbody>
    </table>
  </>
);

const setInteger = (score) => {
  if (typeof (score) !== 'number') {
    return '-';
  }

  return score.toFixed();
};

const KeyboardGameResults = ({ results }) => {
  const globalLeaders = results.get('global_leaders');
  const courseLeaders = results.get('course_leaders');
  const myScores = results.get('my_scores')?.filter((score) => score.get('score') !== null);

  return (
    <section className="keyboard-results container">
      <div className="header">
        <h1>Keyboarding</h1>
        <Link to="../../" relative="path" className="button icon">
          <Icon text="close" />
        </Link>
      </div>
      <div className="content">
        {renderGlobalLeaders(globalLeaders)}
        {renderClassLeaders(courseLeaders)}
        <h2>My Scores</h2>
        <table>
          <thead>
            <tr>
              <td>Date</td>
              <td>Score</td>
              <td>Words/Minute</td>
            </tr>
          </thead>
          <tbody>
            {myScores?.isEmpty()
              ? null
              : myScores?.map((scores) => (
                <tr>
                  <td>{formatAssessmentDate(scores.get('updated_at'))}</td>
                  <td className="myScore">{scores.get('score')}</td>
                  <td className="wpm">{setInteger(scores.get('words_per_minute'))}</td>
                </tr>

              ))}
          </tbody>
        </table>
      </div>

    </section>
  );
};

KeyboardGameResults.propTypes = {
  results: PropTypes.instanceOf(Map).isRequired,
};

export default KeyboardGameResults;
