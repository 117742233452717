import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';

const MigrationErrorCourses = ({
  courses,
}) => {
  const errorWording = (type, key) => (
    <div className="errors-list" key={key}>
      {type.includes('module skipped') ? (
        <div>
          Not migrated. This module is no longer supported.
        </div>
      )
        : null}
      {type.includes('no items') ? (
        <div>
          Not migrated. Empty exam or all questions no longer supported.
        </div>
      )
        : null}
      {type.includes('exam modules skipped') ? (
        <div>
          Partial migration. Some of the questions were from modules that are no longer supported.
        </div>
      )
        : null}
      {type.includes('unexpected error') ? (
        <div>
          Not migrated. Unexpected error. Please contact Customer Support.
        </div>
      )
        : null}
      {type.includes('retired questions') ? (
        <div>
          Partial migration. Some of the questions are no longer supported.
        </div>
      )
        : null}
    </div>
  );

  return (
    <>
      {courses.map((course) => {
        const courseName = course.get('courseName');
        const errors = course.get('errors');

        return (
          <div className="error-course" key={courseName}>
            <div className="course-name">{courseName}</div>
            {errors.map((error) => {
              const { name, type } = error;
              return (
                <div key={name}>
                  <div className="error-row">
                    {type.includes('retired questions') || type.includes('exam modules skipped')
                      ? <Icon text="partial-migration" />
                      : <Icon text="close" /> }

                    <div className="error-wording">
                      <div className="error-name">{name}</div>
                      {type.map((t) => (
                        errorWording(t, t + name)
                      ))}
                    </div>

                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </>
  );
};

MigrationErrorCourses.propTypes = {
  courses: PropTypes.object.isRequired,
};

export default MigrationErrorCourses;
