import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import './ChallengeResults.scss';

const ChallengeComplete = ({ challengeData, viewResults }) => (
  <section className="complete">
    <h2>{`Your score: ${challengeData.get('score')}`}</h2>
    <h3>Quiz Challenge Complete!</h3>
    <button type="button" className="completed" onClick={viewResults}>
      View Score Progress
    </button>
  </section>
);

ChallengeComplete.propTypes = {
  challengeData: PropTypes.instanceOf(Map).isRequired,
  viewResults: PropTypes.func.isRequired,
};

export default ChallengeComplete;
