export function launchOldLessonURL(siteId, courseId, moduleKey, pageSetKey, token) {
  const userTZ = () => ((new Date()).getTimezoneOffset() / 60) * (-1);
  const { REACT_APP_STUDENT_URL } = process.env;
  const pageKey = `${pageSetKey}`;
  const query = `token=${token}&course_id=${courseId}&module_key=${moduleKey}&page_set_key=${pageKey}&tz=${userTZ()}`;
  return `${REACT_APP_STUDENT_URL}/${siteId}?${query}`;
}

export function launchTeacherUI(siteAuthToken, beta, courseId = '') {
  // eslint-disable-next-line no-unused-vars
  const { REACT_APP_TEACHER_UI_BETA_URL, REACT_APP_TEACHER_UI_URL } = process.env;
  // switch to allow beta redirects
  // const base = beta === 'true' ? REACT_APP_TEACHER_UI_BETA_URL : REACT_APP_TEACHER_UI_URL;
  const base = REACT_APP_TEACHER_UI_URL;
  document.location = `${base}&token=${siteAuthToken}&courseId=${courseId}`;
}
