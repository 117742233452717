import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { Map } from 'immutable';
import {
  getAuthToken,
  getName,
  hideScreenOrientationWarning,
  isStudent,
  isViewAsStudent,
} from '../redux/Authentication/selectors';
import { closeScreenOrientationWarning, updateStudentPreferences } from '../redux/Authentication/actions';
import { getCourses, isErrorCountExceeded } from '../redux/Data/selectors';
import Main from '../components/Main';
import Menu from '../components/Menu';
import RouteEnforcer from './RouteEnforcer';
import SessionHandler from './SessionHandler';
import ScreenWarningModal from '../components/ScreenWarningModal';
import './App.scss';
import AlertBanner from './AlertBanner';
import { requestEventData } from '../redux/PlatformStatus/actions';
import { getCurrentLandingMessage } from '../redux/PlatformStatus/selectors';
import LandingMessage from './LandingMessage';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { menuVisible: false };
  }

  componentDidMount() {
    const { getEventData } = this.props;
    getEventData();
  }

  hideWarningScreenWarningByPreferences() {
    const { updatePreferences } = this.props;
    const preference = {
      key: 'warn_orient',
      value: true,
    };
    updatePreferences({ preference });
  }

  toggleMenuVisible() {
    const { menuVisible } = this.state;
    this.setState({ menuVisible: !menuVisible });
  }

  render() {
    const {
      authToken,
      name,
      ssoEnroll,
      inviteCode,
      passwordReset,
      courses,
      studentLoggedIn,
      viewAsStudent,
      closeWarning,
      hideScreenOrientation,
      errorsExceeded,
      landingMessage,
    } = this.props;
    const { menuVisible } = this.state;
    if (landingMessage) {
      return <LandingMessage data={landingMessage.toObject()} />;
    }

    return (
      <Router>
        <RouteEnforcer />
        <SessionHandler />
        <div className="App">
          {authToken ? (
            <>
              <AlertBanner />
              <Menu
                name={name}
                courses={courses}
                show={menuVisible}
                isStudent={studentLoggedIn}
                viewAsStudent={viewAsStudent}
                toggleVisible={() => this.toggleMenuVisible()}
              />
              <ScreenWarningModal
                closeModal={closeWarning}
                closeModalPreferences={() => this.hideWarningScreenWarningByPreferences()}
                show={!hideScreenOrientation}
              />
            </>
          ) : null}
          <Main
            authToken={authToken}
            ssoEnroll={ssoEnroll}
            inviteCode={inviteCode}
            passwordReset={passwordReset}
            errorsExceeded={errorsExceeded}
          />
        </div>
      </Router>
    );
  }
}

App.propTypes = {
  authToken: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  ssoEnroll: PropTypes.string,
  inviteCode: PropTypes.string,
  passwordReset: PropTypes.string,
  courses: PropTypes.instanceOf(Map).isRequired,
  studentLoggedIn: PropTypes.bool.isRequired,
  viewAsStudent: PropTypes.bool.isRequired,
  hideScreenOrientation: PropTypes.bool.isRequired,
  errorsExceeded: PropTypes.bool.isRequired,
  landingMessage: PropTypes.instanceOf(Map),
  closeWarning: PropTypes.func.isRequired,
  getEventData: PropTypes.func.isRequired,
  updatePreferences: PropTypes.func.isRequired,
};

App.defaultProps = {
  inviteCode: null,
  ssoEnroll: null,
  passwordReset: null,
  landingMessage: null,
};

const mapStateToProps = (state) => {
  const authToken = getAuthToken(state);
  const { authentication } = state;
  const ssoEnroll = authentication.get('ssoEnroll');
  const inviteCode = authentication.get('teacherInvite');
  const passwordReset = authentication.get('passwordReset');
  const name = getName(state);
  const courses = getCourses(state);
  const studentLoggedIn = isStudent(state);
  const viewAsStudent = isViewAsStudent(state);
  const hideScreenOrientation = hideScreenOrientationWarning(state);
  const errorsExceeded = isErrorCountExceeded(state);
  const landingMessage = getCurrentLandingMessage(state);

  return {
    authToken,
    ssoEnroll,
    inviteCode,
    passwordReset,
    name,
    courses,
    studentLoggedIn,
    viewAsStudent,
    hideScreenOrientation,
    errorsExceeded,
    landingMessage,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeWarning: () => dispatch(closeScreenOrientationWarning()),
  updatePreferences: (data) => dispatch(updateStudentPreferences(data)),
  getEventData: () => dispatch(requestEventData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
