import React, { useState } from 'react';
import PropType from 'prop-types';
import Icon from '@mdi/react';
import {
  mdiAccount,
  mdiClose,
  mdiContentPaste,
  mdiChevronLeft,
  mdiBackupRestore,
  mdiArrowLeft,
  mdiArrowRight,
  mdiCheck,
  mdiFormatListBulleted,
  mdiApps,
  mdiPaperclip,
  mdiMenu,
  mdiCancel,
  mdiProgressCheck,
  mdiLock,
  mdiEye,
  mdiEyeOff,
  mdiAlertCircleOutline,
  mdiScreenRotation,
  mdiCheckboxBlankOutline,
  mdiCheckBoxOutline,
  mdiCalendar,
  mdiHelpNetworkOutline,
  mdiAlert,
  mdiInformationOutline,
} from '@mdi/js';

import './Icon.scss';

const getPath = (className) => {
  switch (className) {
    case 'calendar':
      return mdiCalendar;
    case 'close':
      return mdiClose;
    case 'worksheet':
      return mdiContentPaste;
    case 'back':
      return mdiChevronLeft;
    case 'reset':
      return mdiBackupRestore;
    case 'previous':
      return mdiArrowLeft;
    case 'next':
      return mdiArrowRight;
    case 'check':
    case 'complete':
      return mdiCheck;
    case 'list-bulleted':
      return mdiFormatListBulleted;
    case 'list-apps':
      return mdiApps;
    case 'papperclip':
      return mdiPaperclip;
    case 'menu':
      return mdiMenu;
    case 'block':
      return mdiCancel;
    case 'progress':
      return mdiProgressCheck;
    case 'avatar':
      return mdiAccount;
    case 'lock':
      return mdiLock;
    case 'eye':
      return mdiEye;
    case 'eye-off':
      return mdiEyeOff;
    case 'alert':
      return mdiAlertCircleOutline;
    case 'rotation':
      return mdiScreenRotation;
    case 'empty-checkbox':
      return mdiCheckboxBlankOutline;
    case 'checked-checkbox':
      return mdiCheckBoxOutline;
    case 'network-error':
      return mdiHelpNetworkOutline;
    case 'warning':
      return mdiAlert;
    case 'partial-migration':
      return mdiInformationOutline;
    default:
      return null;
  }
};

const Icons = ({
  text, isButton, size, tooltipText, tooltipPosition,
}) => {
  const [toggleTooltip, setToggleTooltip] = useState(false);
  const className = text.toLowerCase().replace(/[^a-zA-Z]+/g, '-');
  const path = getPath(className);

  if (!path) {
    return null;
  }

  if (!isButton) {
    return <Icon path={path} size={size} className={className} />;
  }

  return (
    <div className="toggleContainer" role="button" aria-hidden="true" onClick={() => setToggleTooltip(!toggleTooltip)}>
      <div onClick={() => setToggleTooltip(!toggleTooltip)} className={`closeArea ${toggleTooltip ? 'active' : ''}`} role="button" aria-hidden="true" />
      <button type="button" className={`toggle ${toggleTooltip ? 'active' : ''}`}>
        {tooltipText && <span className={`tooltip ${tooltipPosition}`}>{tooltipText}</span>}
        <Icon path={path} size={size} className={className} />
      </button>
    </div>
  );
};

Icons.propTypes = {
  text: PropType.string.isRequired,
  isButton: PropType.bool,
  size: PropType.any,
  tooltipText: PropType.string,
  tooltipPosition: PropType.string,
};

Icons.defaultProps = {
  isButton: false,
  size: 1,
  tooltipText: '',
  tooltipPosition: '',
};

export default Icons;
