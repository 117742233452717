import React from 'react';
import './AssessmentResultsVaS.scss';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import screenshot from '../images/results-image2x.png';

const returnButtonText = (type) => {
  if (type === 'exam') {
    return 'Assignment List';
  }

  return type === 'test' ? 'Module' : 'Unit';
};

const retakeButtonText = (type) => {
  if (type === 'exam') {
    return 'Exam';
  }

  return type === 'test' ? 'Test' : 'Quiz';
};

const AssessmentResultsVaS = ({ type, retake }) => (
  <div className="container vas-message">
    <div className="message-text">
      <p>When your student submits an assessment, they will see a results page.</p>
      <p>
        The information shown to your students on this page depends on the Quiz & Test
        {' '}
        settings you chose for the class. A sample results page is shown below.
      </p>
      <p>
        Read this article to learn about setting
        {' '}
        <a
          href="https://help.aeseducation.com/en/articles/2245942-pre-test-test-and-quiz-controls"
          target="_blank"
          rel="noreferrer noopener"
        >
          Student Assessment Feedback: Pre-Test, Test and Quiz Controls
        </a>
        .
      </p>
    </div>
    <div className="vas-action-buttons">
      <Link to="../../" relative="path" className="button submit">
        Return to
        {' '}
        {returnButtonText(type)}
      </Link>
      <button
        type="button"
        onClick={() => retake()}
        className="retake-button"
      >
        Retake
        {' '}
        {retakeButtonText(type)}
      </button>
    </div>
    <div className="screenshot">
      <img src={screenshot} alt="Results Screen Example" />
    </div>
  </div>
);

AssessmentResultsVaS.propTypes = {
  type: PropTypes.string.isRequired,
  retake: PropTypes.func.isRequired,
};

export default AssessmentResultsVaS;
