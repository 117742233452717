import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { isLinkedToICEV, isTeacher } from '../redux/Authentication/selectors';
import { authenticateEnrollStudent } from '../redux/Authentication/actions';
import './SignIn.scss';

const SignInRedirect = (props) => {
  const {
    siteId,
    courseId,
    teacher,
    isCourseMigration,
    linkedToICEV,
  } = props;

  if (courseId && siteId) {
    return <Navigate to={`/site/${siteId}/course/${courseId}/`} />;
  }

  if (teacher) {
    if (isCourseMigration) {
      if (linkedToICEV) {
        return <Navigate to="/migrate/sites/" />;
      }

      return <Navigate to="/migrate/confirm-account/" />;
    }
    return <Navigate to="/teacher/sites/" />;
  }

  return <Navigate to="/courses/" />;
};

SignInRedirect.propTypes = {
  siteId: PropTypes.string,
  courseId: PropTypes.string,
  teacher: PropTypes.bool.isRequired,
  isCourseMigration: PropTypes.bool.isRequired,
  linkedToICEV: PropTypes.bool.isRequired,
};

SignInRedirect.defaultProps = {
  siteId: null,
  courseId: null,
};

const mapStateToProps = (state) => {
  const { authentication } = state;
  const siteId = authentication.get('enrollmentSiteId');
  const courseId = authentication.get('enrollmentCourseId');
  const teacher = isTeacher(state);
  const isCourseMigration = authentication.get('courseMigration');
  const linkedToICEV = isLinkedToICEV(state);

  return {
    siteId,
    courseId,
    teacher,
    isCourseMigration,
    linkedToICEV,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchEnrollStudent: (enrollmentCode) => dispatch(
    authenticateEnrollStudent({ enrollmentCode }),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignInRedirect);
