import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import Input from './Input';
import Alert from './Alert';
import { getErrorText } from '../utils/errors';
import RegisterEmailTaken from './RegisterEmailTaken';
import RegisterEmailOrGoogle from './RegisterEmailOrGoogle';
import RegisterAgeCheck from './RegisterAgeCheck';
import { isRequestActive } from '../redux/View/selectors';

const RegisterForm = ({
  values: {
    firstName,
    lastName,
    email,
    password,
    passwordConfirmation,
  },
  errors,
  emailVerified,
  emailAvailable,
  onGoogleLogin,
  onChangeValue,
  onClickLogin,
  onClickChange,
  onSubmitVerifyEmail,
  onSubmitNewStudent,
  error,
  loading,
}) => {
  const [ageConfirmed, setConfirmed] = useState(false);
  const firstNameErr = errors.get('firstName') || {};
  const lastNameErr = errors.get('lastName') || {};
  const passwordErr = errors.get('password') || {};
  const confirmErr = errors.get('passwordConfirmation') || {};

  if (!loading && emailVerified && !error) {
    if (ageConfirmed && emailAvailable) {
      return (
        <>
          <h3 className="enrollment">Let&apos;s set up your Account</h3>
          <p className="hint">
            * All fields are required
          </p>

          {errors.size
            ? <Alert message="Some fields require your attention." />
            : null}
          <form onSubmit={onSubmitNewStudent} autoComplete="off">
            <Input
              autoComplete="username"
              name="email"
              label="Email or Username"
              value={email}
              disabled
            />

            <Input
              autoComplete="fname"
              name="firstName"
              label="First Name"
              value={firstName}
              error={getErrorText('firstName', firstNameErr.type, firstNameErr.value)}
              onChange={onChangeValue}
            />

            <Input
              autoComplete="lname"
              name="lastName"
              label="Last Name"
              value={lastName}
              error={getErrorText('lastName', lastNameErr.type, lastNameErr.value)}
              onChange={onChangeValue}
            />
          </form>
          {/* form is split to break up chromium autofill confusion */}
          <form onSubmit={onSubmitNewStudent}>
            <Input
              autoComplete="new-password"
              type="password"
              name="password"
              label="Create a Password"
              placeholder="Type your password"
              value={password}
              error={getErrorText('password', passwordErr.type, passwordErr.value)}
              onChange={onChangeValue}
            />
            <Input
              autoComplete="new-password"
              type="password"
              name="passwordConfirmation"
              label="Confirm Password"
              placeholder="Type your password again"
              value={passwordConfirmation}
              error={getErrorText('passwordConfirmation', confirmErr.type, confirmErr.value)}
              onChange={onChangeValue}
            />
            <p className="subtle">
              <strong>Hint: </strong>
              Use a word or combination of words you will remember.
            </p>
            <p className="subtle">
              Your Password must contain at least 6 characters
            </p>

            <button type="submit" className="primary">Create my Account</button>
          </form>
        </>
      );
    }

    if (!emailAvailable) {
      return (
        <RegisterEmailTaken
          email={email}
          onClickLogin={onClickLogin}
          onClickChange={onClickChange}
        />
      );
    }

    if (!ageConfirmed) {
      return <RegisterAgeCheck onConfirm={() => setConfirmed(true)} />;
    }
  }

  return (
    <RegisterEmailOrGoogle
      email={email}
      onChangeValue={onChangeValue}
      onSubmit={onSubmitVerifyEmail}
      onGoogleLogin={onGoogleLogin}
      error={error}
    />
  );
};

RegisterForm.propTypes = {
  values: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    password: PropTypes.string,
    passwordConfirmation: PropTypes.string,
  }).isRequired,
  errors: PropTypes.instanceOf(Map),
  emailVerified: PropTypes.string,
  emailAvailable: PropTypes.bool,
  onGoogleLogin: PropTypes.func.isRequired,
  onChangeValue: PropTypes.func.isRequired,
  onClickLogin: PropTypes.func.isRequired,
  onClickChange: PropTypes.func.isRequired,
  onSubmitVerifyEmail: PropTypes.func.isRequired,
  onSubmitNewStudent: PropTypes.func.isRequired,
  error: PropTypes.string,
  loading: PropTypes.bool.isRequired,
};

RegisterForm.defaultProps = {
  errors: Map(),
  emailVerified: '',
  emailAvailable: false,
  error: '',
};

const mapStateToProps = (state) => {
  const { authentication } = state;
  const error = authentication.get('enrollmentError');
  const loading = isRequestActive(state, 'enrollment');
  return ({ error, loading });
};

export default connect(mapStateToProps)(RegisterForm);
