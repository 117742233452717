import { combineReducers } from 'redux';
import authentication from './Authentication/reducer';
import data from './Data/reducer';
import view from './View/reducer';
import platformStatus from './PlatformStatus/reducer';

export default combineReducers({
  authentication,
  data,
  view,
  platformStatus,
});
