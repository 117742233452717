import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Prompt from './Prompt';

const ChallengePrompt = ({ challengeSkipped, skipChallenge }) => {
  const navigate = useNavigate();

  return (
    !challengeSkipped
      ? (
        <Prompt
          title="Test Your Knowledge"
          onClickClose={skipChallenge}
          primaryBtnText="Let&apos;s Go!"
          primaryBtnCB={() => navigate('challenge/')}
          secondaryBtnText="Skip for now"
          secondaryBtnCB={skipChallenge}
        >
          <p>
            Each day it is a good idea to test what you know.
            <br />
            Answer the following 10 questions and see if you can get on the Class Leaderboard!
          </p>
        </Prompt>
      )
      : null
  );
};

ChallengePrompt.propTypes = {
  challengeSkipped: PropTypes.bool.isRequired,
  skipChallenge: PropTypes.func.isRequired,
};

export default ChallengePrompt;
