import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const ENTITY_ID = 'USICEV';
const LICENSE = '1a579bdcfa8f75e841a72c558cd70807693c1580';
// const LICENSE = 'e355148bb611cfc7e5c11f78e1f22bcd4a8858ee';

const Smowl = ({
  userId,
  courseId,
  initialized,
  setInitialized,
}) => {
  const downloadUrl = `https://swl.smowltech.net/monitor/SCMdownload.php?entity_Name=${
    ENTITY_ID
  }&user_idUser=${
    userId
  }&lang=en`;
  const registerUrl = `https://swl.smowltech.net/monitor/controllerReg.php?entity_Name=${
    ENTITY_ID
  }&swlLicenseKey=${
    LICENSE
  }&user_idUser=${
    userId
  }&lang=en&Course_link=${
    document.location
  }`;
  const monitorUrl = `https://swl.smowltech.net/monitor/controller.php?entity_Name=${
    ENTITY_ID
  }&swlLicenseKey=${
    LICENSE
  }&modality_ModalityName=test&course_CourseName=${
    courseId
  }&course_Container=${
    courseId
  }&user_idUser=${
    userId
  }&lang=en&type=0&savePhoto=${
    initialized ? '1' : '0'
  }&Course_link=${
    document.location
  }`;

  useEffect(() => {
    const messageListener = ({ data, message }) => {
      if (data === 'monitoringstatusOK' || message === 'monitoringstatusOK') {
        setInitialized(true);
      } else if (data === 'monitoringstatusNOTOK' || message === 'monitoringstatusNOTOK') {
        // eslint-disable-next-line no-alert
        alert('Smowl Monitoring not active');
      }
    };

    window.addEventListener('message', messageListener);
    // return disposer
    return () => window.removeEventListener('message', messageListener);
  }, []);

  return (
    <>
      { !initialized
        ? (
          <div className="container">
            <h2>Smowl must be active to continue with assessment</h2>
            <a className="button" href={registerUrl}>
              Click here to Register with Smowl
            </a>
            <a className="button" href={downloadUrl} target="smowl_download">
              Click here to download Smowl
            </a>
          </div>
        )
        : null }
      <iframe
        allow="microphone; camera"
        sandbox="allow-top-navigation allow-scripts allow-modals allow-same-origin"
        title="Smowl Authentication"
        allowFullScreen
        style={{
          position: 'fixed',
          bottom: 0,
          right: 0,
          top: 'unset',
          left: 'unset',
          width: 220,
          height: 300,
        }}
        src={monitorUrl}
      />
    </>
  );
};

Smowl.propTypes = {
  userId: PropTypes.string.isRequired,
  courseId: PropTypes.string.isRequired,
  initialized: PropTypes.bool,
  setInitialized: PropTypes.func,
};

Smowl.defaultProps = {
  initialized: false,
  setInitialized: () => { /* stub */ },
};

export default Smowl;
