import React from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import Input from './Input';
import './Prompt.scss';
import { mapErrorsToTypes, getError } from '../utils/errors';
import AESLogo from './AESLogo';
import Alert from './Alert';

const PasswordForm = ({
  done,
  password,
  confirmation,
  errors,
  instructions,
  setPassword,
  setConfirmation,
  submit,
  finish,
}) => {
  const mappedErrors = mapErrorsToTypes(errors);
  const passwordError = getError('password', mappedErrors);
  const confirmationError = getError('password_confirmation', mappedErrors);
  const message = getError('error', mappedErrors);
  const disabled = !password || !confirmation;

  return (
    <>
      <AESLogo />
      { done
        ? (
          <div className="sign-in-form recover-password">
            <h3>Your password has been reset.</h3>
            <button
              type="button"
              onClick={() => finish()}
              className="primary"
            >
              Continue
            </button>
          </div>
        )
        : (
          <div className="sign-in-form recover-password">
            { message
              ? <Alert type="error" message={message} />
              : <h3>{instructions}</h3>}
            <form onSubmit={(e) => submit(e)}>
              <Input
                type="password"
                name="password"
                id="password"
                value={password}
                error={passwordError}
                onChange={({ target: { value } }) => setPassword(value)}
                placeholder="Create a password."
              />
              <Input
                type="password"
                name="passwordConfirmation"
                id="passwordConfirmation"
                error={confirmationError}
                value={confirmation}
                onChange={({ target: { value } }) => setConfirmation(value)}
                placeholder="Enter the password again."
              />
              <button id="sign-in" className="primary" type="submit" disabled={disabled}>
                Continue
              </button>
            </form>
          </div>
        ) }

    </>
  );
};

PasswordForm.propTypes = {
  password: PropTypes.string,
  confirmation: PropTypes.string,
  errors: PropTypes.instanceOf(Map),
  instructions: PropTypes.string.isRequired,
  done: PropTypes.bool,
  setPassword: PropTypes.func.isRequired,
  setConfirmation: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  finish: PropTypes.func,
};

PasswordForm.defaultProps = {
  password: null,
  confirmation: null,
  errors: Map(),
  done: false,
  finish: () => {},
};

export default PasswordForm;
