import { launchOldLessonURL } from './launch';

/* eslint-disable no-console */
const apiUrl = process.env.REACT_APP_API_BASE_URL;
const baseUrl = process.env.REACT_APP_MEDIA_BASE_URL;

const keyboardingUrl = (pageSetKey, enrollmentId, token) => {
  const gameMapping = {
    U_BCKEYBOARD01_F2: 'home_row.html',
    U_BCKEYBOARD01_F3: 'top_row.html',
    U_BCKEYBOARD01_F4: 'bottom_row.html',
    U_BCKEYBOARD01_F5: 'capital_letters.html',
    U_BCKEYBOARD01_F6: 'punctuation.html',
    U_BCKEYBOARD01_F7: 'numeric_row.html',
    U_BCKEYBOARD01_F8: 'symbols.html',
    U_BCKEYBOARD01_G9: 'index.html',
  };
  const fileName = gameMapping[pageSetKey];

  return `${baseUrl}/keyboarding/${fileName}?enrollment=${enrollmentId}&token=${token}`;
};

class ScormProvider {
  constructor(params) {
    // SCORM Window Hook
    window.API_1484_11 = {
      Initialize: () => this.Initialize(),
      GetDiagnostic: () => this.GetDiagnostic(),
      GetErrorString: () => this.GetErrorString(),
      GetLastError: () => this.GetLastError(),
      GetValue: (key) => this.GetValue(key),
      SetValue: (key, value) => this.SetValue(key, value),
      Commit: () => this.Commit(),
      Terminate: () => this.Terminate(),
      // NOT DEAD CODE, only called from inside Keyboarding lessons
      AES_LaunchGame: () => this.LaunchKeyboardingGame(),
    };

    // Phlebotomy game uses the AES Name directly
    window.AESScorm2004_v2 = window.API_1484_11;

    this.onMessage = ({ data }) => {
      if (data === 'ScormTerminate') {
        this.Terminate();
      }
    };

    window.addEventListener('message', this.onMessage);

    this.params = params;
    this.lastError = 0;
    this.lastErrorString = '';
    this.lastRequest = null;
    this.lastTransmit = null;
    this.pendingChanges = false;
    this.data = {
      'cmi.success_status': 'unknown',
      'cmi.completion_status': 'not attempted',
      'cmi.mode': 'normal',
    };

    this.DataLoaded(params);
  }

  DataLoaded(params) {
    const {
      siteId,
      courseId,
      enrollmentId,
      lessonWorkId,
      onContentChange,
      onPostData,
      onPostError,
      onTerminate,
      pageSetExtra,
      postOnTerminate = true,
      scormData,
      token,
      unitVersion,
      moduleKey,
      pageSetKey,
      pageSetType,
      viewAsStudent,
    } = params;
    if (pageSetType === 'SCRIPT') {
      this.contentUrl = launchOldLessonURL(siteId, courseId, moduleKey, pageSetKey, token);
      this.contentType = 'SCRIPT';
    } else {
      const teacherToken = viewAsStudent ? 'TEACHER' : '';
      const contentParts = pageSetExtra.split('~');
      const contentPath = contentParts.length > 1 ? contentParts[1] : contentParts[0];
      this.contentType = contentParts.length > 1 ? contentParts[0] : '';
      if (this.contentType === 'KEYBOARDING') {
        this.contentUrl = keyboardingUrl(pageSetKey, enrollmentId, teacherToken || token);
      } else {
        this.contentUrl = `${
          baseUrl
        }${
          contentPath.replace('/media', `/${unitVersion}/media`)
        }${
          teacherToken && '?token=TEACHER'
        }`;
      }
    }

    this.callbackUrl = viewAsStudent
      ? `${apiUrl.replace('v1', 'v2')}/teacher/courses/${courseId}/lessons_as_student/${lessonWorkId}`
      : `${apiUrl}/learner/courses/${courseId}/lessons/${lessonWorkId}`;

    this.onPostData = onPostData;
    this.onPostError = onPostError;
    this.onTerminate = onTerminate;
    this.onContentChange = onContentChange;
    this.postOnTerminate = postOnTerminate;
    this.token = token;

    if (scormData) {
      this.data = JSON.parse(scormData);
    }
  }

  GetContentData() {
    return {
      contentType: this.contentType,
      contentUrl: this.contentUrl,
    };
  }

  // //
  // // SCORM INTERFACE
  // //

  Initialize() {
    this.ResetError();
    console.log('AESScorm2004::Initialize');
    return true;
  }

  Terminate() {
    // Prevent terminate from actually firing when the keyboarding game is launching
    if (this.launchingKeyboardingGame) {
      return 'true';
    }

    // Cleanup event listener
    window.removeEventListener('message', this.onMessage);

    console.log('AESScorm2004::Terminate');
    this.ResetError();
    const doTerminate = () => {
      if (typeof this.onTerminate === 'function') {
        this.onTerminate();
      }
    };

    if (this.postOnTerminate) {
      // SL1 - Go ahead and post during terminate
      this.PostData(doTerminate);
    } else {
      // SL2 - Don't post data again during terminate
      // or there will be errors from the XHR because
      // we are posting twice at the same time
      setTimeout(doTerminate, 100);
    }

    return 'true';
  }

  GetValue(key) {
    let value;
    this.ResetError();

    switch (key) {
      case 'aes.game_url':
        value = this.params.game_url;
        break;
      case 'cmi.interactions._children':
        value = 'id,type,objectives,timestamp,correct_responses,weighting,learner_response,result,latency,description,RO';
        break;
      case 'cmi.interactions._count':
        value = this.CountInteractions();
        break;
      case 'cmi.score._children':
        value = 'scaled,raw,min,max, RO';
        break;
      case 'aes.test':
        value = 'AES Test Variable';
        break;
      default:
        value = this.data[key];
    }

    if (typeof value !== 'undefined') {
      console.log(`AESScorm2004::GetValue  name=${key} value=${value}`);
    } else {
      this.lastError = 403;
      this.lastErrorString = 'Data Model Element Value';
      console.log(`AESScorm2004::GetValue  name=${key} ValueNotSet`);
      value = '';
    }
    return value;
  }

  SetValue(key, value) {
    console.log(`AESScorm2004::SetValue name=${key} value=${value}`);

    this.data[key] = value;

    // If this is just a session time increment, skip POST
    if (key === 'cmi.session_time') {
      return true;
    }

    this.pendingChanges = true;

    if (this.lastRequest) {
      this.lastRequest.then(() => this.PostData());
    } else {
      this.PostData();
    }

    return true;
  }

  Commit() {
    console.log('AESScorm2004::Commit');
    this.PostData();
    return true;
  }

  GetLastError() {
    console.log('AESScorm2004::GetLastError');
    return this.lastError;
  }

  GetErrorString() {
    console.log('AESScorm2004::GetErrorString');
    return this.lastErrorString;
  }

  /* eslint-disable-next-line class-methods-use-this */
  GetDiagnostic() {
    console.log('AESScorm2004::GetDiagnostic');
    return '';
  }

  CountInteractions() {
    let interactionCount = 0;
    while (this.data[`cmi.interactions.${interactionCount}.id`] != null) {
      interactionCount += 1;
    }
    return interactionCount;
  }

  ResetError() {
    this.lastError = 0;
    this.lastErrorString = '';
  }

  PostData(callback) {
    console.log('AESScorm2004::AES_PostData');
    const hasCallback = typeof callback === 'function';

    if (this.pendingChanges) {
      this.pendingChanges = false;
      this.lastRequest = fetch(this.callbackUrl, {
        method: 'PUT',
        headers: {
          'X-Auth-Token': this.token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(this.data),
      }).then((response) => {
        if (response.ok) {
          this.ResetError();
          this.lastTransmit = Date.now();
          if (typeof this.onPostData === 'function') {
            response.json()
              .then((json) => this.onPostData(json));
          }
        } else {
          this.lastError = response.status;
          this.lastErrorString = 'Network Error during PostData';
          this.onPostError({ code: this.lastError, error: this.lastErrorString });
          console.error(`AESScorm2004::Error: ${this.lastErrorString}`);
        }
        if (hasCallback) {
          callback(response);
        }
      }).catch((error) => {
        this.lastError = 1;
        this.lastErrorString = 'Error During PostData';
        this.onPostError({ error });
        console.error('AESScorm2004::Error:', error);
        if (hasCallback) {
          callback(error);
        }
      });
    } else if (hasCallback) {
      callback();
    }
  }

  /**
   * THIS IS NOT dead code, it is only called from inside Keyboarding lessons
   */
  // eslint-disable-next-line camelcase
  LaunchKeyboardingGame() {
    console.log('AESScorm2004::AES_LaunchGame');
    const {
      enrollmentId,
      token,
      pageSetKey,
      viewAsStudent,
    } = this.params;
    const urlToken = viewAsStudent
      ? 'TEACHER'
      : token;
    // this flag is required to prevent the lesson terminate() call from going through
    this.launchingKeyboardingGame = true;
    setTimeout(() => { this.launchingKeyboardingGame = false; }, 3000);
    this.contentUrl = keyboardingUrl(pageSetKey, enrollmentId, urlToken);
    this.onContentChange(this.contentUrl);

    return 'true';
  }
}

export default ScormProvider;
