import React from 'react';
import PropTypes from 'prop-types';

const FormatState = (state) => {
  if (state === 'None' || !state) {
    return '-';
  }

  return state;
};

const KeyboardingTables = ({ num, rowData }) => {
  const name = rowData.get('name');
  const state = rowData.get('state');
  const score = rowData.get('score');

  return (
    <tr>
      <td className="rank">{num}</td>
      <td>{name}</td>
      { state
        ? <td className="state">{FormatState(state)}</td>
        : null }
      <td className="score">{score}</td>
    </tr>
  );
};

KeyboardingTables.propTypes = {
  num: PropTypes.number.isRequired,
  rowData: PropTypes.instanceOf(Map).isRequired,
};

export default KeyboardingTables;
