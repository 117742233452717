import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { passwordSetRequest, passwordSetFinish } from '../redux/Authentication/actions';
import PasswordForm from '../components/PasswordForm';

class PasswordReset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      passwordConfirmation: '',
    };
  }

  submit(e) {
    const { code, email, setPassword } = this.props;
    const { password, passwordConfirmation } = this.state;
    e.preventDefault();
    setPassword(code, email, password, passwordConfirmation);
  }

  render() {
    const { password, passwordConfirmation } = this.state;
    const { errors, done, finish } = this.props;
    return (
      <div className="content reset">
        <PasswordForm
          instructions="Enter your new password."
          password={password}
          confirmation={passwordConfirmation}
          errors={errors}
          done={done}
          setPassword={(p) => this.setState({ password: p })}
          setConfirmation={(c) => this.setState({ passwordConfirmation: c })}
          submit={(e) => this.submit(e)}
          finish={finish}
        />
      </div>
    );
  }
}

PasswordReset.propTypes = {
  code: PropTypes.string.isRequired,
  done: PropTypes.bool,
  email: PropTypes.string,
  errors: PropTypes.instanceOf(Map),
  setPassword: PropTypes.func.isRequired,
  finish: PropTypes.func.isRequired,
};

PasswordReset.defaultProps = {
  done: false,
  email: '',
  errors: Map(),
};

const mapDispatchToProps = (dispatch) => ({
  setPassword: (code, email, password, confirm) => dispatch(
    passwordSetRequest(code, email, password, confirm),
  ),
  finish: () => dispatch(
    passwordSetFinish(),
  ),
});

const mapStateToProps = ({ authentication }) => ({
  code: authentication.get('passwordReset'),
  done: authentication.get('passwordSetDone'),
  errors: authentication.get('passwordSetErrors'),
  email: authentication.get('ltiEmail'),
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
