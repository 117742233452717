import React from 'react';
import PropTypes from 'prop-types';
import { Map, OrderedMap } from 'immutable';
import { requestAssessmentData, requestLessonData, resetLesson } from '../redux/Data/actions';
import { getModule, getUnit, getModuleTitle } from '../redux/Data/selectors';
import LessonList from '../components/LessonList';
import {
  getModuleIsClosed,
  getQuizCloseReason,
  getQuizIsClosed,
  getUnitIsClosed,
  getUnitCloseReason,
} from '../utils/pacing';
import { getMediaUrl } from '../utils/url';
import { isLessonLoading } from '../redux/View/selectors';
import LoadingSection from '../components/LoadingSection';
import ClosedMessage from '../components/ClosedMessage';
import { isViewAsStudent } from '../redux/Authentication/selectors';
import ResourceLists from '../components/ResourceLists';
import WorksheetTable from '../components/WorksheetTable';
import connectWithRouterMatch from '../redux/connectWithRouterMatch';

class Unit extends React.Component {
  componentDidMount() {
    this.requestLessonData();
  }

  componentDidUpdate() {
    this.requestLessonData();
  }

  resetLesson(pageSetKey) {
    const { dispatch } = this.props;
    dispatch.resetLesson(pageSetKey);
  }

  requestLessonData() {
    const { dispatch, lessons } = this.props;
    if (lessons.isEmpty()) {
      dispatch.requestLessonData();
    }
  }

  retakeQuiz() {
    const { dispatch } = this.props;
    dispatch.requestAssessment();
  }

  render() {
    const {
      lessons,
      resources,
      worksheets,
      worksheetStatus,
      unitIsClosed,
      quizExists,
      quizWork,
      quizIsClosed,
      quizCloseReason,
      quizIsOverridden,
      isLoading,
      unitClosedReason,
      moduleTitle,
    } = this.props;

    if (isLoading) {
      return <LoadingSection />;
    }

    const worksheetType = worksheets.get('type');
    const worksheetUrl = worksheetType === 'pdf'
      ? getMediaUrl(worksheets.getIn(['url', 0, 'url']))
      : worksheets.get('url');

    return unitIsClosed
      ? (
        <ClosedMessage
          classInfo={moduleTitle} // module name
          reasonCopy={unitClosedReason}
          messageType="unit"
        />
      )
      : (
        <section className="unit">
          { worksheetUrl
            ? <WorksheetTable url={worksheetUrl} status={worksheetStatus} type={worksheetType} />
            : null }

          {lessons.isEmpty()
            ? null
            : (
              <LessonList
                lessons={lessons}
                worksheetUrl={worksheetUrl}
                worksheetStatus={worksheetStatus}
                quizExists={quizExists}
                quizWork={quizWork}
                quizIsClosed={quizIsClosed}
                quizCloseReason={quizCloseReason}
                quizIsOverridden={quizIsOverridden}
                resetLesson={(pageSetKey) => this.resetLesson(pageSetKey)}
                retakeQuiz={() => this.retakeQuiz()}
              />
            )}
          <ResourceLists resources={resources} />
        </section>
      );
  }
}

Unit.propTypes = {
  dispatch: PropTypes.object.isRequired,
  lessons: PropTypes.instanceOf(OrderedMap).isRequired,
  resources: PropTypes.instanceOf(OrderedMap).isRequired,
  unitIsClosed: PropTypes.bool.isRequired,
  worksheets: PropTypes.instanceOf(Map).isRequired,
  worksheetStatus: PropTypes.string.isRequired,
  quizExists: PropTypes.bool.isRequired,
  quizCloseReason: PropTypes.string,
  quizIsClosed: PropTypes.bool,
  quizWork: PropTypes.instanceOf(Map).isRequired,
  quizIsOverridden: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  unitClosedReason: PropTypes.string.isRequired,
  moduleTitle: PropTypes.string.isRequired,
};

Unit.defaultProps = {
  quizCloseReason: '',
  quizIsClosed: false,
};

const mapStateToProps = (state, { match: { params } }) => {
  const {
    courseId,
    moduleKey,
    siteId,
    unitKey,
  } = params;
  const moduleData = getModule(state, siteId, courseId, moduleKey);
  const unitData = getUnit(state, siteId, courseId, moduleKey, unitKey);
  const unitClosedReason = getUnitCloseReason(unitData);
  const viewAsStudent = isViewAsStudent(state);
  const moduleIsClosed = getModuleIsClosed(moduleData);
  const moduleTitle = getModuleTitle(state, siteId, courseId, moduleKey);
  const unitIsClosed = getUnitIsClosed(unitData, moduleIsClosed);
  const quizExists = unitData.get('quiz_status') !== 'No Quiz';
  const quizIsClosed = !viewAsStudent && getQuizIsClosed(unitData);
  const quizCloseReason = getQuizCloseReason(unitData);
  const quizIsOverridden = unitData.getIn(['work', 'is_override']) || false;
  const lessons = unitData.get('pageSets') || OrderedMap();
  const resources = unitData.get('resources') || OrderedMap();
  const quizWork = unitData.get('assessmentWork') || Map();
  const worksheets = unitData.get('worksheets') || Map();
  const worksheetStatus = unitData.get('worksheetStatus') || '';

  const isLoading = isLessonLoading(state);

  return ({
    lessons,
    resources,
    worksheets,
    worksheetStatus,
    quizExists,
    quizWork,
    quizIsClosed,
    quizCloseReason,
    quizIsOverridden,
    unitIsClosed,
    unitClosedReason,
    moduleKey,
    moduleTitle,
    isLoading,
  });
};

const mapDispatchToProps = (dispatch, { match: { params } }) => {
  const {
    courseId,
    moduleKey,
    siteId,
    unitKey,
  } = params;
  return ({
    dispatch: {
      requestLessonData: () => dispatch(requestLessonData(siteId, courseId, moduleKey, unitKey)),
      resetLesson: (pageSetKey) => dispatch(
        resetLesson(siteId, courseId, moduleKey, unitKey, pageSetKey),
      ),
      requestAssessment: () => dispatch(requestAssessmentData(
        siteId,
        courseId,
        moduleKey,
        unitKey,
      )),
    },
  });
};

export default connectWithRouterMatch(mapStateToProps, mapDispatchToProps)(Unit);
