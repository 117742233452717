import { List, Map } from 'immutable';
import { STATUS_EVENTS_SUCCESS } from './actions';

const initialState = Map({
  events: List(),
});

const statusReducer = (state = initialState, action) => {
  switch (action.type) {
    case STATUS_EVENTS_SUCCESS:
      return state.set('events', action.events);
    default:
      return state;
  }
};

export default statusReducer;
