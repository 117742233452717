import React from 'react';
import './Progress.scss';

const Progress = () => (
  <svg className="progress indeterminate">
    <circle cx="25" cy="25" r="23" />
  </svg>
);

export default Progress;
