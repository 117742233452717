import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { isLinkedToICEV } from '../redux/Authentication/selectors';
import { createIcevAccount } from '../redux/Authentication/actions';
import { getMigrationError } from '../redux/View/selectors';
import errorImage from '../images/migration-error.png';
import './MigrationAccountCreation.scss';

const MigrationAccountCreation = () => {
  const [timeComplete, setTimeComplete] = useState(false);
  const accountCreated = useSelector(isLinkedToICEV);
  const migrationError = useSelector(getMigrationError);
  const dispatch = useDispatch();

  useEffect(() => {
    if (accountCreated) {
      setTimeComplete(true);
      return () => { /* stub for consistent return */ };
    }

    const timeout = setTimeout(() => setTimeComplete(true), 15000);

    dispatch(createIcevAccount());

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  if (timeComplete && accountCreated) {
    return <Navigate to="/migrate/sites" />;
  }

  if (migrationError) {
    const reason = migrationError.join(' ')
      .replace('subject: 10', 'Health Science')
      .replace('subject: 8', 'Business');

    return (
      <section className="migration-account-error">
        <img src={errorImage} alt="error" />
        <h1>Oops… we seem to have run into an error creating your account!</h1>
        <p>{`Reason: ${reason}`}</p>
        <p>
          Please reach out to customer support to resolve the issue before resuming your migration.
        </p>
        <a href="https://icevonline.com/contact-us" target="icev_contact" className="button primary">
          Contact Customer Support
        </a>
      </section>
    );
  }

  return (
    <div className="migration-account-creation">
      <div className="migrate-account">
        <svg className="progress indeterminate">
          <circle cx="25" cy="25" r="23" />
        </svg>
        <p>
          Please be patient while we create your iCEV account.
        </p>
      </div>
    </div>
  );
};

export default MigrationAccountCreation;
