import {
  call,
  delay,
  put,
  select,
  takeEvery,
  takeLeading,
} from 'redux-saga/effects';
import { OrderedMap, Map, List } from 'immutable';
import {
  DATA_MIGRATION_REQUEST_STATUS,
  DATA_MIGRATION_REQUEST,
  requestMigrationStatus,
  requestMigrationFailed,
} from '../Data/actions';
import {
  setMigrationCompleted,
  setMigrationError,
  setMigrationReport,
} from '../View/actions';
import {
  getAuthToken,
  getSiteToken,
  getSiteTokens,
  getTeacherContext,
} from '../Authentication/selectors';

import { getMigrationCoursesSelected } from '../View/selectors';
import { createIcevTeacher, getJob, startMigration } from '../../services/API';
import {
  CREATE_ICEV_ACCOUNT_JOB_REQUEST, CREATE_ICEV_ACCOUNT_REQUEST,
  createIcevAccountSucceeded,
  getIcevAccountJob,
} from '../Authentication/actions';
import { getSites } from '../Data/selectors';

export function* doRequestMigrationStatus({ jobId }) {
  const token = yield select(getAuthToken);

  yield delay(1000);
  const response = yield call(getJob, jobId, token); // siteId
  const status = response.get('status');
  const migrationResults = response.get('results');
  const sites = yield select(getSites);

  if (status === 'completed') {
    const errors = migrationResults.filter((course) => {
      const examResults = course.get('exam_results') || List();
      const examErrors = examResults.size === 0 ? List()
        : examResults.filter((exam) => {
          const err = exam.get('error');
          const skippedModules = exam.get('skipped_modules', List());

          return !(err === null && skippedModules.size === 0);
        });

      return !(course.get('modules_skipped', List()).size === 0
        && course.get('error') === null
        && examErrors.size === 0);
    });

    if (errors?.size > 0) {
      const errorReport = errors.reduce((errorsMap, error, courseId) => {
        const siteId = error.get('site_id').toString();
        const errorSite = sites.get(siteId);
        const siteName = errorSite.get('name');
        const errorCourse = errorSite.get('teacherCourses').find((course) => (
          course.get('id').toString() === courseId
        ));
        const courseName = errorCourse.get('title');
        const moduleErrors = error.get('modules_skipped') || List();
        const examErrors = error.get('exam_results') || List();
        const errorsArray = [];
        if (moduleErrors.size > 0) {
          moduleErrors.map((moduleError) => (
            errorsArray.push({
              name: moduleError,
              type: ['module skipped'],
            })
          ));
        }

        if (examErrors.size > 0) {
          examErrors.forEach((examError) => {
            const err = examError.get('error');
            const skippedModules = examError.get('skipped_modules');
            const allExamErrors = [];

            if (err === 'No valid items to migrate') {
              allExamErrors.push('no items');
            } else if (err === 'Retired questions removed') {
              allExamErrors.push('retired questions');
            } else if (err !== null) {
              allExamErrors.push('unexpected error');
            }

            // Do not show Exam Question Modules Skipped Error if no valid items to migrate
            if (skippedModules.size > 0 && err !== 'No valid items to migrate') {
              allExamErrors.push('exam modules skipped');
            }

            if (allExamErrors.length > 0) {
              errorsArray.push({
                name: examError.get('exam_title'),
                type: allExamErrors,
              });
            }
          });
        }

        return errorsMap.update(siteName, (site = Map()) => (
          site.set('siteName', siteName)
            .update('courses', (courses = List()) => (
              courses.push(Map({
                courseName,
                errors: List(errorsArray),
              }))
            ))
        ));
      }, OrderedMap());

      yield put(setMigrationReport(errorReport));
    }

    yield put(setMigrationCompleted());
  } else {
    yield put(requestMigrationStatus(jobId));
  }
}

export function* doRequestMigration() {
  const migrationCoursesSelected = yield select(getMigrationCoursesSelected);
  const siteId = migrationCoursesSelected.first().get('site_id').toString();
  const siteToken = yield select(getSiteToken, siteId);
  const teacherContext = yield select(getTeacherContext);

  const selected = migrationCoursesSelected.map((course) => course.get('id')).toJS();
  const response = yield call(startMigration, selected, siteToken, teacherContext);

  const jobId = response.get('job_id');
  if (jobId) {
    yield put(requestMigrationStatus(jobId));
  } else {
    yield put(requestMigrationFailed('Error contacting API server'));
  }
}

export function* doCreateAccountJob({ jobId }) {
  const token = yield select(getAuthToken);

  yield delay(1000);
  const response = yield call(getJob, jobId, token); // siteId
  const status = response.get('status');
  const icevAccountId = response.get('icev_account_id');

  if (status === 'completed') {
    if (icevAccountId) {
      yield put(createIcevAccountSucceeded(icevAccountId));
    } else {
      const errorJSON = response.get('error') || '["Unknown Error"]';
      yield put(setMigrationError(JSON.parse(errorJSON)));
    }
  } else {
    yield put(getIcevAccountJob(jobId));
  }
}

export function* doCreateAccount() {
  const siteTokensIterator = yield select(getSiteTokens);
  const siteToken = siteTokensIterator.next().value;
  const response = yield call(createIcevTeacher, siteToken);

  const jobId = response.get('job_id');
  if (jobId) {
    yield put(getIcevAccountJob(jobId));
  } else {
    yield put(setMigrationError('Error contacting API server'));
  }
}

export function* watchRequestMigration() {
  yield takeLeading(DATA_MIGRATION_REQUEST, doRequestMigration);
}

export function* watchRequestMigrationStatus() {
  yield takeEvery(DATA_MIGRATION_REQUEST_STATUS, doRequestMigrationStatus);
}

export function* watchCreateIcevAccount() {
  yield takeLeading(CREATE_ICEV_ACCOUNT_REQUEST, doCreateAccount);
}

export function* watchCreateIcevAccountJob() {
  yield takeEvery(CREATE_ICEV_ACCOUNT_JOB_REQUEST, doCreateAccountJob);
}
