import { Map } from 'immutable';
import { isPastDue } from './date';

export const getUnitHasOverride = (unit) => unit.get('reason') === 'Override';

export const getModuleHasOverrides = (module) => module.get('units', Map())
  .some(getUnitHasOverride);

/**
 * Gets whether the module's status is closed,
 * or optionally consider module open if any unit or test within the module is overridden
 * @param module
 * @param includeOverrides
 * @returns {boolean}
 */
export const getModuleIsClosed = (module, includeOverrides = false) => {
  const isClosed = module.get('status') !== 'Open';

  if (isClosed && includeOverrides) {
    return !getModuleHasOverrides(module);
  }
  return isClosed;
};

/**
 * Gets whether the unit's status is closed,
 * or require the unit to be overridden to be considered open if module is closed
 * @param unit
 * @param moduleIsClosed
 * @returns {boolean}
 */
export const getUnitIsClosed = (unit, moduleIsClosed = false) => (
  moduleIsClosed
    ? !getUnitHasOverride(unit)
    : !unit.isEmpty() && unit.get('status') !== 'Open'
);

export const getModuleCloseReason = (module) => {
  const due = module.get('due');
  const reason = module.get('reason');

  if (isPastDue(due)) {
    return 'Due Date Passed';
  }

  // If Advanced Pacing is on and teacher closes module, reason will be null, otherwise 'No pacing'
  if (!reason
    || reason === 'No pacing'
    || reason === 'Module Closed') {
    return 'Closed';
  }

  return reason;
};

export const getUnitCloseReason = (unit) => {
  const reason = unit.get('reason');

  switch (reason) {
    case 'Normal':
      return 'Closed';
    case 'Incomplete Units':
      return reason;
    default: {
      return 'Closed';
    }
  }
};

export const getQuizIsClosed = (unit) => (
  unit.get('quiz_status') !== 'Open'
);

export const getQuizCloseReason = (unit) => {
  const reason = unit.get('quiz_reason');

  switch (reason) {
    case 'No pacing':
    case 'Unit Closed':
      return 'Closed';
    default:
      return reason;
  }
};

export const getTipsColor = (closeReason) => {
  if (closeReason === 'Due Date Passed' || closeReason === 'Lessons incomplete') return 'in-progress';

  if (closeReason === 'Unit not started'
  || closeReason === 'Incomplete Units'
  || closeReason === 'Previous module incomplete'
  || closeReason === 'Blocked by closed module'
  || closeReason === 'Lessons incomplete') {
    return 'locked';
  }

  return '';
};

export const getClosedType = (closeReason, isClosed) => {
  if (isClosed) {
    if (closeReason === 'Unit Closed'
      || closeReason === 'Module Closed'
      || closeReason === 'Closed') {
      return ({ statusClass: 'closed', icon: 'block' });
    }

    if (closeReason === 'Due Date Passed') {
      return ({ statusClass: 'closed due-passed', icon: 'calendar' });
    }

    if (closeReason === 'Unit not started'
      || closeReason === 'Incomplete Units'
      || closeReason === 'Previous module incomplete'
      || closeReason === 'Blocked by closed module'
      || closeReason === 'Lessons incomplete') {
      return ({ statusClass: 'blocked', icon: 'lock' });
    }
  }

  // not closed, but past due
  if (closeReason === 'Due Date Passed') {
    return ({ statusClass: 'due-passed', icon: '' });
  }

  return ({ statusClass: '', icon: '' });
};

export const getModuleCompletion = (moduleData) => {
  const type = moduleData.get('type_name');
  const completedCount = moduleData.get('completed_count');
  const completedMax = moduleData.get('completed_max');

  if (type === 'exam') {
    const grade = moduleData.get('grade');
    return {
      // intentionally coercing to handle null || undefined
      completedCount: grade == null ? 0 : 1,
      completedMax: 1,
    };
  }

  return { completedCount, completedMax };
};

export const formatClosedReason = (reason) => {
  switch (reason) {
    case 'Unit not started':
    case 'Incomplete Units':
    case 'Previous module incomplete':
    case 'Blocked by closed module':
    case 'incomplete':
      return 'Locked';
    default:
      return reason;
  }
};
