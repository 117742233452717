import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { authenticateEnrollStudent } from '../redux/Authentication/actions';
import EnrollmentSingleSignOnForm from '../components/EnrollmentSingleSignOnForm';

class EnrollmentSingleSignOn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enrollmentCode: '',
    };
  }

  onSubmitEnrollment(ev) {
    const { enrollmentCode } = this.state;
    const { dispatchEnrollStudent, ssoEnroll } = this.props;
    ev.preventDefault();
    dispatchEnrollStudent(enrollmentCode, ssoEnroll);
  }

  setEnrollmentCode(enrollmentCode) {
    this.setState({ enrollmentCode });
  }

  render() {
    const { enrollmentCode } = this.state;
    const { enrollmentError } = this.props;
    return (
      <div className="content enroll">
        <EnrollmentSingleSignOnForm
          enrollmentCode={enrollmentCode}
          enrollmentError={enrollmentError}
          setEnrollmentCode={(code) => this.setEnrollmentCode(code)}
          onSubmitEnrollment={(ev) => this.onSubmitEnrollment(ev)}
          enrollForSingleSignOn
        />
      </div>
    );
  }
}

EnrollmentSingleSignOn.propTypes = {
  dispatchEnrollStudent: PropTypes.func.isRequired,
  ssoEnroll: PropTypes.string,
  enrollmentError: PropTypes.string,
};

EnrollmentSingleSignOn.defaultProps = {
  ssoEnroll: null,
  enrollmentError: null,
};

const mapStateToProps = (state) => {
  const { authentication } = state;
  const enrollmentError = authentication.get('enrollmentError');
  const ssoEnroll = authentication.get('ssoEnroll');
  return { enrollmentError, ssoEnroll };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchEnrollStudent: (enrollmentCode, ssoEnroll) => dispatch(authenticateEnrollStudent({
    enrollmentCode, ssoEnroll,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(EnrollmentSingleSignOn);
