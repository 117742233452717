import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Prompt from './Prompt';
import './AssessmentWarningModal.scss';

const AssessmentWarningModal = ({
  title, type, inProgress, onClickStart,
}) => {
  const navigate = useNavigate();
  const goBack = () => navigate('../..', { relative: 'path' });

  if (inProgress) {
    return (
      <Prompt
        title={title}
        type="assessment"
        primaryBtnText={`Resume ${type}`}
        primaryBtnCB={onClickStart}
      >
        <div className="content">
          <p>
            {`This ${type} is still in progress.`}
            <br />
            You will need to complete it before you can continue with lessons.
          </p>
        </div>
      </Prompt>
    );
  }

  return (
    <Prompt
      title={title}
      type="assessment"
      onClickClose={goBack}
      primaryBtnText={`Start ${type}`}
      primaryBtnCB={onClickStart}
      secondaryBtnText="Return to Lessons"
      secondaryBtnCB={goBack}
    >
      <div className="content">
        <p>
          {`Once the ${type} is started, you will not be able to continue with lessons until it is completed. `}
          <br />
          {`Do you want to start the ${type} now?`}
        </p>
      </div>
    </Prompt>
  );
};

AssessmentWarningModal.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  inProgress: PropTypes.bool.isRequired,
  onClickStart: PropTypes.func.isRequired,
};

export default AssessmentWarningModal;
