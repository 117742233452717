import { all, call, spawn } from 'redux-saga/effects';
import {
  watchAuthenticateRequest,
  watchAuthenticateSiteRequest,
  watchAuthenticateTeacherSiteRequest,
  watchAuthenticationEnrollVerify,
  watchAuthenticationEnrollStudent,
  watchAuthenticationEnrollSuccess,
  watchAuthenticationEmailVerify,
  watchPasswordResetRequest,
  watchPasswordSetRequest,
  watchCreateNewStudentRequest,
  watchChangeModuleView,
  watchUpdateStudentRequest,
  watchTeacherInviteRequest,
  watchLogout,
  watchSetActiveCourse,
  watchActiveSession,
  watchLoadTeacherCourses,
} from './authentication';
import {
  watchCourseDataRequest,
  watchLessonDataRequest,
  watchModuleDataRequest,
  watchLogEvent,
  watchResetLesson,
  watchUpdateLessonWork,
} from './lessons';
import {
  watchAssessmentDataRequest,
  watchAssessmentResultsRequest,
  watchAssessmentSave,
  watchAssessmentTimerUpdate,
  watchAssessmentTtsJobRequest,
  watchAssessmentTtsRequest,
  watchAssessmentUpdate,
  watchFinishAssessment,
  watchFinishAssessmentJob,
  watchLockedAssessmentRequest,
} from './assessments';
import {
  watchChallengeAnswerRequest,
  watchChallengeRequest,
  watchChallengeResultsRequest,
} from './challenge';
import { watchRequestEnds, watchRequestStarts } from './view';
import { watchErrors, watchSuccess } from './errors';
import { watchKeyboardingResultsRequest } from './keyboarding';
import { watchEventsRequest } from './status';
import {
  watchCreateIcevAccountJob,
  watchCreateIcevAccount,
  watchRequestMigration,
  watchRequestMigrationStatus,
} from './migration';

/*
  Creates a self-healing saga that will respawn on error, but log to Rollbar so we know about it.
 */
export const RespawningSaga = (saga) => spawn(function* respawnGenerator() {
  while (true) {
    try {
      yield call(saga);
      break;
    } catch (error) {
      window.Rollbar.error(error);
    }
  }
});

export default function* rootSaga() {
  const sagas = [
    watchActiveSession,
    watchAssessmentDataRequest,
    watchAssessmentResultsRequest,
    watchAssessmentSave,
    watchAssessmentTimerUpdate,
    watchAssessmentTtsJobRequest,
    watchAssessmentTtsRequest,
    watchAssessmentUpdate,
    watchAuthenticateRequest,
    watchAuthenticateSiteRequest,
    watchAuthenticateTeacherSiteRequest,
    watchAuthenticationEmailVerify,
    watchAuthenticationEnrollStudent,
    watchAuthenticationEnrollSuccess,
    watchAuthenticationEnrollVerify,
    watchChallengeAnswerRequest,
    watchChallengeRequest,
    watchChallengeResultsRequest,
    watchChangeModuleView,
    watchCourseDataRequest,
    watchCreateNewStudentRequest,
    watchErrors,
    watchFinishAssessment,
    watchFinishAssessmentJob,
    watchKeyboardingResultsRequest,
    watchLessonDataRequest,
    watchLockedAssessmentRequest,
    watchLogEvent,
    watchLogout,
    watchModuleDataRequest,
    watchPasswordResetRequest,
    watchPasswordSetRequest,
    watchRequestStarts,
    watchRequestEnds,
    watchResetLesson,
    watchSetActiveCourse,
    watchSuccess,
    watchTeacherInviteRequest,
    watchUpdateLessonWork,
    watchUpdateStudentRequest,
    watchEventsRequest,
    watchLoadTeacherCourses,
    watchRequestMigration,
    watchRequestMigrationStatus,
    watchCreateIcevAccountJob,
    watchCreateIcevAccount,
  ].map((saga) => RespawningSaga(saga));

  yield all(sagas);
}
