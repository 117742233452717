import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useMatch, useNavigate } from 'react-router-dom';
import { getAuthToken } from '../redux/Authentication/selectors';
import { clearView } from '../redux/View/actions';
import { getLockedAssessmentPath, getRedirect } from '../redux/View/selectors';
import useRouteMatch from '../hooks/useRouteMatch';

const whitelist = [
  '/',
  '/patterns',
  '/enroll',
  '/enroll/:enrollmentCode',
  '/student',
  '/student/help',
  '/teacher',
  '/teacher/help',
  '/migrate',
];

const RouteEnforcer = ({
  authToken,
  lockedAssessmentPath,
  redirect,
  clearRedirect,
}) => {
  const { location } = window;
  const navigate = useNavigate();
  const whiteListMatch = useRouteMatch(whitelist);
  const lockedAssessmentMatch = useMatch(lockedAssessmentPath);

  // Fixes bad urls created by users typing . at the end of the domain
  useEffect(() => {
    if (location.origin?.endsWith('.')) {
      location.replace(location.origin.slice(0, -1));
    }
  }, [location.origin]);

  useEffect(() => {
    if (!authToken && !whiteListMatch) {
      navigate('/', { replace: true });
    }

    if (lockedAssessmentPath && !lockedAssessmentMatch) {
      navigate(lockedAssessmentPath, { replace: true });
    }

    if (redirect) {
      clearRedirect();
      navigate(redirect);
    }
  }, [authToken, whiteListMatch, lockedAssessmentMatch, lockedAssessmentPath, redirect]);

  return null;
};

RouteEnforcer.propTypes = {
  authToken: PropTypes.string.isRequired,
  lockedAssessmentPath: PropTypes.string.isRequired,
  redirect: PropTypes.string.isRequired,
  clearRedirect: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authToken: getAuthToken(state),
  lockedAssessmentPath: getLockedAssessmentPath(state),
  redirect: getRedirect(state),
});

export default connect(mapStateToProps, { clearRedirect: clearView })(RouteEnforcer);
