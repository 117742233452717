import { Map } from 'immutable';
import {
  DATA_COURSE_ERROR,
  DATA_COURSE_SUCCESS,
  DATA_LESSON_SUCCESS,
  DATA_LESSON_ERROR,
  DATA_LESSON_WORK_UPDATED,
  DATA_SITE_DATA_RECEIVED,
  DATA_SITE_LIST_RECEIVED,
  DATA_ASSESSMENT_SUCCESS,
  DATA_ASSESSMENT_RESULTS_SUCCESS,
  DATA_ASSESSMENT_WORK_UPDATED,
  DATA_ASSESSMENT_REQUEST_TTS_JOB,
  DATA_ASSESSMENT_REQUEST_TTS_SUCCESS,
  DATA_ASSESSMENT_SAVE_SUCCESS,
  DATA_CHALLENGE_SUCCESS,
  DATA_CHALLENGE_SKIP,
  DATA_CHALLENGE_COMPLETE,
  DATA_KEYBOARDING_RESULTS_SUCCESS,
  DATA_LOCKED_ASSESSMENT_ID_UPDATED,
  DATA_CLEAR,
  DATA_ASSESSMENT_RESULTS_CLEAR,
  DATA_ASSESSMENT_TIMER_UPDATED,
  DATA_CHALLENGE_RESULTS_SUCCESS,
  DATA_ERROR_INCREMENT,
  DATA_ERROR_CLEAR,
  DATA_TEACHER_COURSES_SUCCESS,
} from './actions';

const initialState = Map({
  assessments: Map(),
  sites: Map(),
  errorCount: 0,
});

const clearAssessmentPendingSave = (state, action) => {
  const {
    siteId,
    courseId,
    moduleKey,
    unitKey,
  } = action;
  const selector = unitKey
    ? [
      'sites',
      siteId,
      'courses',
      courseId,
      'modules',
      moduleKey,
      'units',
      unitKey,
      'assessmentWork',
      'pendingSave',
    ]
    : [
      'sites',
      siteId,
      'courses',
      courseId,
      'modules',
      moduleKey,
      'assessmentWork',
      'pendingSave',
    ];

  return state.deleteIn(selector);
};

const updateAssessmentWork = (state, action) => {
  const {
    siteId,
    courseId,
    moduleKey,
    unitKey,
    assessmentWork,
  } = action;

  // quiz, test, pre-test have unit work
  if (unitKey) {
    const unitSelector = [
      'sites',
      siteId,
      'courses',
      courseId,
      'modules',
      moduleKey,
      'units',
      unitKey,
    ];

    return state.updateIn(unitSelector, (unit) => (
      unit?.set('assessmentWork', assessmentWork)
        .update('work', (work) => (
          work
            ?.set('status', assessmentWork.get('status'))
            ?.set('quiz_grade', assessmentWork.get('lesson_grade'))
        ))
    ));
  }

  // exam only has assessmentWork
  return state.setIn([
    'sites',
    siteId,
    'courses',
    courseId,
    'modules',
    moduleKey,
    'assessmentWork',
  ], assessmentWork);
};

const updateAssessmentPageSet = (state, action) => {
  const { assignmentId, assignmentEntryId, pageSet } = action;
  if (assignmentEntryId) {
    return state.setIn(['assessments', assignmentId, assignmentEntryId, 'pageSet'], pageSet);
  }
  return state.setIn(['assessments', assignmentId, 'pageSet'], pageSet);
};

const updateAssessmentResults = (state, action) => {
  const { assignmentId, assignmentEntryId, results } = action;
  if (assignmentEntryId) {
    return state.setIn(['assessments', assignmentId, assignmentEntryId, 'results'], results);
  }
  return state.setIn(['assessments', assignmentId, 'results'], results);
};

const updateAssessmentTimer = (state, action) => {
  const {
    siteId,
    courseId,
    moduleKey,
    unitKey,
    attemptTimer,
  } = action;

  return state.setIn([
    'sites',
    siteId,
    'courses',
    courseId,
    'modules',
    moduleKey,
    'units',
    unitKey,
    'assessmentWork',
    'attempt_timer',
  ], attemptTimer);
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case DATA_COURSE_SUCCESS:
      return state.mergeIn(['sites', action.siteId, 'courses', action.courseId], action.courseData);
    case DATA_COURSE_ERROR:
      return state.setIn(['sites', action.siteId, 'error'], action.error);
    case DATA_TEACHER_COURSES_SUCCESS:
      return state.mergeDeepIn(['sites'], action.siteCourses);
    case DATA_LESSON_SUCCESS:
      return state.mergeIn([
        'sites',
        action.siteId,
        'courses',
        action.courseId,
        'modules',
        action.moduleKey,
        'units',
        action.unitKey,
      ], action.lessonData);
    case DATA_LESSON_ERROR:
      return state.setIn([
        'sites',
        action.siteId,
        'courses',
        action.courseId,
        'modules',
        action.moduleKey,
        'units',
        action.unitKey,
        'error',
      ], action.error);
    case DATA_LESSON_WORK_UPDATED:
      return state.setIn([
        'sites',
        action.siteId,
        'courses',
        action.courseId,
        'modules',
        action.moduleKey,
        'units',
        action.unitKey,
        'pageSets',
        action.pageSetKey,
        'work',
      ], action.lessonWorkData);
    case DATA_SITE_DATA_RECEIVED:
      return state.setIn(['sites', action.siteId], action.siteData);
    case DATA_SITE_LIST_RECEIVED:
      return state.set('sites', action.sites).set('expiredRoles', action.expiredRoles);
    case DATA_ASSESSMENT_SUCCESS:
      return updateAssessmentPageSet(state, action);
    case DATA_ASSESSMENT_RESULTS_CLEAR:
    case DATA_ASSESSMENT_RESULTS_SUCCESS:
      return updateAssessmentResults(state, action);
    case DATA_ASSESSMENT_WORK_UPDATED:
      return updateAssessmentWork(state, action);
    case DATA_ASSESSMENT_REQUEST_TTS_JOB:
      return state.setIn([
        'assessments',
        'tts',
        action.question.key,
        'ttsJob',
      ], action.jobId);
    case DATA_ASSESSMENT_REQUEST_TTS_SUCCESS:
      return state.setIn([
        'assessments',
        'tts',
        action.key,
        'ttsUrl',
      ], action.ttsUrl);
    case DATA_ASSESSMENT_SAVE_SUCCESS:
      return clearAssessmentPendingSave(state, action);
    case DATA_ASSESSMENT_TIMER_UPDATED:
      return updateAssessmentTimer(state, action);
    case DATA_CHALLENGE_SUCCESS:
      return state.mergeIn(
        ['sites', action.siteId, 'courses', action.courseId, 'challenge'],
        action.challengeData,
      );
    case DATA_CHALLENGE_SKIP:
      return state.setIn(
        ['sites', action.siteId, 'courses', action.courseId, 'challenge', 'skipped'],
        true,
      );
    case DATA_CHALLENGE_COMPLETE:
      return state.setIn(
        ['sites', action.siteId, 'courses', action.courseId, 'challenge', 'complete'],
        true,
      );
    case DATA_KEYBOARDING_RESULTS_SUCCESS:
      return state.setIn(
        ['sites', action.siteId, 'courses', action.courseId, 'keyboarding'],
        action.results,
      );
    case DATA_CHALLENGE_RESULTS_SUCCESS:
      return state.setIn(
        ['sites', action.siteId, 'courses', action.courseId, 'challenge', 'results'],
        action.results,
      );
    case DATA_LOCKED_ASSESSMENT_ID_UPDATED:
      return state.setIn(['user', 'locked_quiz_id'], action.lockedAssessmentId);
    case DATA_ERROR_INCREMENT:
      return state.update('errorCount', (count) => count + 1);
    case DATA_ERROR_CLEAR:
      return state.set('errorCount', 0);
    case DATA_CLEAR:
      return initialState;
    default:
      return state;
  }
};

export default dataReducer;
