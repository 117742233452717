import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logout } from '../redux/Authentication/actions';
import ErrorMessage from '../components/ErrorMessage';

const NetworkErrorsMessage = ({ dispatchLogout }) => (
  <div className="container">
    <ErrorMessage title="Looks like something went wrong…">
      <p>
        You ran into an unidentified network error.
        <br />
        Please sign out and then sign back in again.
      </p>
      <button type="button" className="primary" onClick={dispatchLogout}>Sign out</button>
    </ErrorMessage>
  </div>
);

NetworkErrorsMessage.propTypes = { dispatchLogout: PropTypes.func.isRequired };

const mapDispatchToProps = (dispatch) => ({ dispatchLogout: () => dispatch(logout()) });

export default connect(null, mapDispatchToProps)(NetworkErrorsMessage);
