import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Rollbar from 'rollbar';
import App from './containers/App';
import configureStore from './redux/configureStore';

window.Rollbar = new Rollbar({
  accessToken: '771ad08fd7d047f6893f95e5dbd77f15',
  captureUncaught: true,
  captureUnhandledRejections: true,
  hostSafeList: ['aeseducation.com'],
  payload: {
    environment: process.env.NODE_ENV,
  },
});

const { store, persistor } = configureStore();
const root = createRoot(document.getElementById('root'));

root.render((
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
));
