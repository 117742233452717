import { List, Map } from 'immutable';
import {
  AUTHENTICATION_AUTHENTICATE_REQUEST,
  AUTHENTICATION_AUTHENTICATE_ERROR,
  AUTHENTICATION_AUTHENTICATE_SUCCESS,
  AUTHENTICATION_SITE_DATA_ERROR,
  AUTHENTICATION_SITE_DATA_SUCCESS,
  AUTHENTICATION_SET_ACTIVE_COURSE_SUCCESS,
  AUTHENTICATION_ENROLL_STUDENT,
  AUTHENTICATION_ENROLL_SUCCESS,
  AUTHENTICATION_ENROLL_ERROR,
  AUTHENTICATION_ENROLL_RESET,
  AUTHENTICATION_VERIFY_EMAIL_REQUEST,
  AUTHENTICATION_VERIFY_EMAIL_ERROR,
  AUTHENTICATION_VERIFY_EMAIL_SUCCESS,
  AUTHENTICATION_VERIFY_EMAIL_CLEAR,
  AUTHENTICATION_VERIFY_ENROLLMENT_SUCCESS,
  AUTHENTICATION_VERIFY_ENROLLMENT_ERROR,
  PASSWORD_RESET_REQUEST,
  PASSWORD_RESET_ERROR,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_SET_ERROR,
  PASSWORD_SET_SUCCESS,
  PASSWORD_SET_FINISH,
  CREATE_NEW_STUDENT_SUCCESS,
  CREATE_NEW_STUDENT_ERROR,
  UPDATE_STUDENT_ERROR,
  UPDATE_STUDENT_SUCCESS,
  UPDATE_STUDENT_CLEAR,
  TEACHER_INVITE_ERROR,
  TEACHER_COURSE_MIGRATION,
  AUTHENTICATION_LOGOUT_SUCCESS,
  CHANGE_STUDENT_PREFERENCES_SUCCESS,
  CHANGE_STUDENT_PREFERENCES_ERROR,
  CLOSE_SCREEN_ORIENTATION_WARNING,
  AUTHENTICATION_SET_LAST_ACTIVITY,
  LOAD_TEACHER_COURSES_ERROR, CREATE_ICEV_ACCOUNT_SUCCESS, SET_ICEV_ORG_EXISTS,
} from './actions';

import { getUrlParameter } from '../../utils/url';

export const emptyState = Map({
  authToken: '',
  ltiVerify: '',
  ltiAssignmentId: '',
  ltiProvider: '',
  ltiEmail: '',
  ssoEnroll: '',
  ssoProvider: '',
  teacherInvite: '',
  passwordReset: '',
  message: '',
  hideScreenOrientationWarning: false,
  user: Map(),
  siteTokens: Map(),
  courseMigration: false,
  migrationCoursesEligible: Map(),
  migrationCoursesSelected: List(),
});

export const initialState = emptyState.merge(Map({
  authToken: getUrlParameter('token'),
  ltiVerify: getUrlParameter('lti_verify'),
  ltiAssignmentId: getUrlParameter('lti_assignment_id'),
  ltiProvider: getUrlParameter('provider'),
  ltiEmail: getUrlParameter('email'),
  ssoEnroll: getUrlParameter('sso_enroll'),
  ssoProvider: getUrlParameter('provider'),
  teacherInvite: getUrlParameter('invite'),
  passwordReset: getUrlParameter('reset'),
  message: getUrlParameter('message'),
  newUI: !!getUrlParameter('newUI'),
  viewAsStudent: !!getUrlParameter('view_as_student'),
  cevOrgName: getUrlParameter('org_name'),
  teacher_context: getUrlParameter('payload'),
}));

const authenticationReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTHENTICATION_AUTHENTICATE_SUCCESS:
      return state
        .set('authToken', action.authToken)
        .set('user', action.user)
        .set('userRole', action.userRole)
        .set('lastActivity', Map({
          time: Date.now(),
          event: { type: 'authenticate' },
        }))
        .delete('ltiVerify');
    case AUTHENTICATION_AUTHENTICATE_ERROR:
      return state.set('error', action.error)
        .delete('authToken');
    case AUTHENTICATION_SITE_DATA_SUCCESS:
      return state
        .setIn(['siteTokens', action.siteId, 'token'], action.siteToken)
        .set('siteAuthError', null);
    case AUTHENTICATION_SITE_DATA_ERROR:
      return state
        .setIn(['siteTokens', action.siteId, 'error'], action.error)
        .set('siteAuthError', action.error);
    case AUTHENTICATION_ENROLL_SUCCESS:
      return state
        .set('enrollmentSiteId', action.siteId)
        .set('enrollmentCourseId', action.courseId)
        .deleteAll(['enrollmentCode', 'enrollmentError'])
        .set('enrollSuccess', true);
    case AUTHENTICATION_VERIFY_EMAIL_REQUEST:
      return state
        .set('emailVerified', action.email)
        .delete('enrollmentError');
    case AUTHENTICATION_VERIFY_EMAIL_SUCCESS:
      return state.set('emailAvailable', action.available);
    case AUTHENTICATION_VERIFY_EMAIL_CLEAR:
      return state.deleteAll(['emailVerified', 'emailAvailable']);
    case AUTHENTICATION_VERIFY_ENROLLMENT_SUCCESS:
      return state
        .set('enrollmentCode', action.enrollmentCode)
        .delete('enrollmentError');
    case AUTHENTICATION_AUTHENTICATE_REQUEST:
    case AUTHENTICATION_ENROLL_STUDENT:
      return state.delete('message');
    case AUTHENTICATION_ENROLL_ERROR:
    case AUTHENTICATION_VERIFY_ENROLLMENT_ERROR:
    case AUTHENTICATION_VERIFY_EMAIL_ERROR:
      return state.set('enrollmentError', action.error);
    case AUTHENTICATION_ENROLL_RESET:
      return state
        .delete('enrollSuccess')
        .delete('enrollmentError');
    case AUTHENTICATION_SET_ACTIVE_COURSE_SUCCESS:
      return state.set('activeCourse', Map({
        siteId: action.siteId,
        courseId: action.courseId,
      }));
    case AUTHENTICATION_SET_LAST_ACTIVITY:
      return state.set('lastActivity', Map({
        time: Date.now(),
        event: action.event,
      }));
    case CREATE_NEW_STUDENT_SUCCESS:
      return state
        .set('authToken', action.token)
        .setIn(['siteTokens', action.siteId, 'token'], action.token)
        .set('enrollmentSiteId', action.siteId)
        .set('enrollmentCourseId', action.courseId);
    case CREATE_NEW_STUDENT_ERROR:
      return state.set('newStudentErrors', action.errors);
    case UPDATE_STUDENT_SUCCESS:
      return state.delete('updateStudentErrors').set('updateSuccess', true);
    case UPDATE_STUDENT_CLEAR:
      return state.set('updateSuccess', false).delete('updateStudentErrors');
    case UPDATE_STUDENT_ERROR:
      return state.set('updateStudentErrors', action.errors);
    case PASSWORD_RESET_REQUEST:
      return state.delete('resetError').delete('resetSuccess');
    case PASSWORD_RESET_ERROR:
      return state.set('resetError', action.error).delete('resetSuccess');
    case PASSWORD_RESET_SUCCESS:
      return state.delete('resetError').set('resetSuccess', true);
    case PASSWORD_SET_ERROR:
      return state.set('passwordSetErrors', action.errors);
    case PASSWORD_SET_SUCCESS:
      return state
        .set('passwordSetDone', true);
    case PASSWORD_SET_FINISH:
      return state
        .delete('passwordSetErrors')
        .delete('passwordSetDone')
        .delete('passwordReset');
    case TEACHER_INVITE_ERROR:
      return state.set('inviteError', action.error);
    case TEACHER_COURSE_MIGRATION:
      return state.set('courseMigration', true);
    case AUTHENTICATION_LOGOUT_SUCCESS:
      return emptyState.set('message', action.message);
    case CHANGE_STUDENT_PREFERENCES_SUCCESS:
      return state.setIn(['user', 'preferences'], action.preferences);
    case CHANGE_STUDENT_PREFERENCES_ERROR:
      return state.setIn(['user', 'preferences', 'errors', 'preferences'], action.errors);
    case CLOSE_SCREEN_ORIENTATION_WARNING:
      return state.set('hideScreenOrientationWarning', true);
    case LOAD_TEACHER_COURSES_ERROR:
      return state.set('newStudentErrors', action.error);
    case CREATE_ICEV_ACCOUNT_SUCCESS:
      return state
        .setIn(['user', 'icev_account_id'], action.icevAccountId)
        .set('is_new_icev_teacher', true);
    case SET_ICEV_ORG_EXISTS:
      return state.set('icev_org_exists', true);
    default:
      return state;
  }
};

export default authenticationReducer;
