import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import CompetencyRows from './CompetencyRows';
import './ChallengeResults.scss';

const renderCompetencyRow = (moduleData) => (
  <>
    { moduleData.map((item, moduleName) => (
      item
        .map((rowData, unitKey) => rowData.set('key', unitKey))
        .toList()
        .sortBy((units) => units.get('key'))
        .map((rowData, index) => (
          <CompetencyRows
            key={rowData.get('key')}
            moduleName={moduleName}
            rowData={rowData}
            scoreData={rowData.get('student')}
            isFirst={index < 1}
          />
        ))
    ))
      .toList()}
  </>
);

const CompetencyTable = ({ competencyData }) => (
  <div className="content">
    <h2>Topic Competency Report</h2>
    <table>
      <thead>
        <tr>
          <th>Module</th>
          <th>Unit</th>
          <th>Number Correct/Attempts</th>
          <th>% Correct</th>
          <th>Course Avg.*</th>
          <th>National Avg.**</th>
        </tr>
      </thead>
      <tbody>
        {(competencyData.isEmpty())
          ? null
          : renderCompetencyRow(competencyData)}
      </tbody>
    </table>
    <p className="footnote">
      * Course data is updated every 15 minutes
      <br />
      ** National data is updated each night
    </p>
  </div>
);

CompetencyTable.propTypes = {
  competencyData: PropTypes.instanceOf(Map),
};

CompetencyTable.defaultProps = {
  competencyData: Map(),
};

export default CompetencyTable;
