import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import { Navigate } from 'react-router-dom';
import Alert from '../components/Alert';
import ProgressBar from '../components/ProgressBar';
import ButtonLink from '../components/ButtonLink';
import MigrationErrorSites from '../components/MigrationErrorSites';
import Icon from '../components/Icon';

import './MigrationProgress.scss';

const { REACT_APP_ICEV_LOGIN } = process.env;

const MigrationProgress = ({
  migrationCompleted,
  migrationErrorReport,
  courseCount,
  icevNewTeacher,
}) => {
  const [stepComplete, setStepComplete] = useState(0);
  const [showErrors, setShowErrors] = useState(false);
  const upgradeComplete = migrationCompleted && stepComplete > 2;
  const percentComplete = upgradeComplete ? 100 : stepComplete * 27;
  const icevLink = `${REACT_APP_ICEV_LOGIN}/mycourses/added?newCoursesAdded=${courseCount}`;
  const showMigrationReport = migrationErrorReport.size > 0;

  const continueButton = () => (upgradeComplete
    ? (
      <ButtonLink
        href={icevLink}
        text="Continue to iCEV"
      />
    )
    : (
      <button
        type="button"
        onClick={() => { /* do nothing */ }}
        disabled
      >
        Continue to iCEV
      </button>
    ));

  const newIcevTeacherContinueButton = () => (showMigrationReport && upgradeComplete
    ? (
      <ButtonLink
        href="/migrate/sites/complete/"
        text="Continue to iCEV"
      />
    )
    : (
      <button
        type="button"
        onClick={() => { /* do nothing */ }}
        disabled
      >
        Continue to iCEV
      </button>
    )
  );

  useEffect(() => {
    const timeout1 = setTimeout(() => (setStepComplete(1)), 3000);
    const timeout2 = setTimeout(() => (setStepComplete(2)), 6000);
    const timeout3 = setTimeout(() => (setStepComplete(3)), 15000);
    return () => {
      clearTimeout(timeout1);
      clearTimeout(timeout2);
      clearTimeout(timeout3);
    };
  }, []);

  if (upgradeComplete && icevNewTeacher && !showMigrationReport) {
    return <Navigate to="/migrate/sites/complete/" />;
  }

  const handleOnClick = () => (setShowErrors(!showErrors));

  return (
    <div className="migrate-progress">
      <h1>
        { upgradeComplete
          ? 'Your courses have been successfully migrated'
          : 'Please be patient while we migrate your courses' }
      </h1>
      <ProgressBar value={percentComplete} />
      <div className="student-info-warning">
        Note: Courses and exams are the only items migrated as a part of this process.
        {' '}
        Student information will NOT migrate and students must enroll within iCEV.
      </div>
      <ul className="steps">
        <li className={stepComplete > 0 ? 'complete' : 'processing'}>
          { stepComplete
            ? <Icon text="complete" />
            : null }
          Migrating course information
        </li>
        <li className={percentComplete === 100 ? 'complete' : 'processing'}>
          { percentComplete === 100
            ? <Icon text="complete" />
            : null }
          Migrating course / exam structures
        </li>
      </ul>
      {(showMigrationReport && upgradeComplete) && (
        <div className="error-warning">
          <div className="error-report">
            <Icon text="warning" />
            <div className="warning-text">
              Some of your courses or exams had errors
            </div>
            <button
              type="button"
              className="show-report"
              onClick={handleOnClick}
              onKeyDown={handleOnClick}
            >
              Show Report
            </button>
          </div>
          { showErrors
            ? <MigrationErrorSites errors={migrationErrorReport} />
            : null}
        </div>
      )}
      { upgradeComplete
        ? (
          <Alert type="success">
            Migration is complete, you may now continue to iCEV.
          </Alert>
        )
        : (
          <Alert type="info">
            You can continue to iCEV once the migration process is complete.
          </Alert>
        ) }

      { icevNewTeacher ? newIcevTeacherContinueButton() : continueButton() }
    </div>
  );
};

MigrationProgress.propTypes = {
  migrationCompleted: PropTypes.bool.isRequired,
  migrationErrorReport: PropTypes.instanceOf(Map).isRequired,
  courseCount: PropTypes.number.isRequired,
  icevNewTeacher: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ authentication, view }) => {
  const migrationCompleted = view.get('migrationCompleted') || false;
  const migrationErrorReport = view.get('migrationReport') || Map();
  const migrationCoursesSelected = view.get('migrationCoursesSelected') || List();
  const courseCount = migrationCoursesSelected.size;
  const icevNewTeacher = authentication.get('is_new_icev_teacher') || false;
  return {
    migrationCompleted,
    migrationErrorReport,
    courseCount,
    icevNewTeacher,
  };
};

export default connect(mapStateToProps)(MigrationProgress);
