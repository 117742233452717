import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import Learnosity from '../services/learnosity';
import AssessmentTts from './AssessmentTts';
import './AssessmentLearnosity.scss';

class AssessmentLearnosity extends React.Component {
  constructor(props) {
    super(props);
    this.section = React.createRef();
    this.state = { questions: [] };
  }

  componentDidMount() {
    const id = 'learnosity_container';
    const {
      requestString,
      finishAssessment,
      setAssessmentActivity,
      setAttempt,
      onInitError,
    } = this.props;
    const container = document.createElement('div');
    const section = this.section.current;
    const gotoHandler = () => {
      setAssessmentActivity();
      const audioElements = document.getElementsByTagName('audio');
      // eslint-disable-next-line no-restricted-syntax
      for (const item of audioElements) {
        item.pause();
      }
    };
    const callbacks = {
      onGoto: gotoHandler,
      onSetAttempt: setAttempt,
      onSubmit: finishAssessment,
      onReady: (assessment, itemsApp) => this.onReady(assessment, itemsApp),
      onError: onInitError,
    };

    container.id = id;
    section.appendChild(container);

    Learnosity.initialize(requestString, `#${id}`, callbacks);
  }

  componentDidUpdate() {
    const { pendingSave, onSaveSucceeded } = this.props;
    if (pendingSave) {
      this.itemsApp?.save({ success: onSaveSucceeded });
    }
  }

  componentWillUnmount() {
    Learnosity.reset();
  }

  onReady({ questions }, itemsApp) {
    this.setState({ questions });
    this.itemsApp = itemsApp;
  }

  render() {
    const { reviewMode, requestTts, ttsData } = this.props;
    const { questions } = this.state;

    return (
      <>
        <section className="container learnosity" ref={this.section} />
        { !reviewMode && questions.map((question) => (
          <AssessmentTts
            key={question.key}
            question={question}
            ttsData={ttsData}
            requestTts={requestTts}
          />
        ))}
      </>
    );
  }
}

AssessmentLearnosity.propTypes = {
  pendingSave: PropTypes.bool,
  reviewMode: PropTypes.bool,
  requestString: PropTypes.string.isRequired,
  ttsData: PropTypes.instanceOf(Map),
  finishAssessment: PropTypes.func,
  onSaveSucceeded: PropTypes.func,
  onInitError: PropTypes.func,
  requestTts: PropTypes.func,
  setAssessmentActivity: PropTypes.func,
  setAttempt: PropTypes.func,
};

AssessmentLearnosity.defaultProps = {
  finishAssessment: () => {},
  onSaveSucceeded: () => {},
  onInitError: () => {},
  pendingSave: false,
  requestTts: () => {},
  reviewMode: false,
  setAssessmentActivity: () => {},
  setAttempt: () => {},
  ttsData: Map(),
};

export default AssessmentLearnosity;
