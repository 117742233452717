import { Map, OrderedMap } from 'immutable';
import { isViewAsStudent } from '../Authentication/selectors';
import { getModuleIsClosed, getUnitIsClosed } from '../../utils/pacing';

export const getCourse = ({ data }, siteId, courseId) => (
  data.getIn(['sites', siteId, 'courses', courseId]) || Map()
);

export const getModule = ({ data }, siteId, courseId, moduleKey) => (
  data.getIn(['sites', siteId, 'courses', courseId, 'modules', moduleKey]) || Map()
);

export const getModuleBySequence = ({ data }, siteId, courseId, sequence) => {
  const modules = data.getIn(['sites', siteId, 'courses', courseId, 'modules']) || Map();
  return modules.find((module) => module.get('sequence') === sequence) || Map();
};

export const getCourseModules = ({ data }, siteId, courseId) => (
  data.getIn(['sites', siteId, 'courses', courseId, 'modules'])
);

export const getUnits = (data) => data.get('units')
  ?.filter((unit) => unit.get('type') === 'unit') || OrderedMap();

export const getTests = (data) => data.get('units')
  ?.filter((unit) => unit.get('type') === 'test') || OrderedMap();

export const getUnit = ({ data }, siteId, courseId, moduleKey, unitKey) => (
  data.getIn([
    'sites',
    siteId,
    'courses',
    courseId,
    'modules',
    moduleKey,
    'units',
    unitKey,
  ]) || Map()
);

export const getPageSet = ({ data }, siteId, courseId, moduleKey, unitKey, pageSetKey) => (
  data.getIn([
    'sites',
    siteId,
    'courses',
    courseId,
    'modules',
    moduleKey,
    'units',
    unitKey,
    'pageSets',
    pageSetKey,
  ]) || Map()
);

export const isPretestEnabled = (state, siteId, courseId) => (
  getCourse(state, siteId, courseId).getIn(['course_settings', 'enable_pre_test'])
);

export const isPretestRequired = (state, siteId, courseId, moduleKey) => {
  if (isViewAsStudent(state)) {
    return false;
  }

  if (isPretestEnabled(state, siteId, courseId)) {
    const preTest = getUnit(state, siteId, courseId, moduleKey, `PREMT_${moduleKey}`);
    const status = preTest.getIn(['work', 'status']) || 0;
    return !preTest.isEmpty() && status < 2;
  }
  return false;
};

export const getModuleTitle = (state, siteId, courseId, moduleKey) => (
  getModule(state, siteId, courseId, moduleKey).get('title') || ''
);

export const getUnitTitle = (state, siteId, courseId, moduleKey, unitKey = '') => {
  if (unitKey.startsWith('PREMT_')) {
    return `${getModuleTitle(state, siteId, courseId, moduleKey)}, Pre-Test`;
  }
  if (unitKey.startsWith('MT_')) {
    return `${getModuleTitle(state, siteId, courseId, moduleKey)}, Module Test`;
  }
  return getUnit(state, siteId, courseId, moduleKey, unitKey).get('title') || '';
};

export const getPageSetTitle = (state, siteId, courseId, moduleKey, unitKey, pageSetKey) => (
  pageSetKey === 'post-quiz'
    ? `${getModuleTitle(state, siteId, courseId, moduleKey)},
     ${getUnitTitle(state, siteId, courseId, moduleKey, unitKey)} Quiz`
    : getPageSet(state, siteId, courseId, moduleKey, unitKey, pageSetKey).get('title') || ''
);

export const getModuleHeaderTitle = (state, siteId, courseId, moduleKey, unitKey, pageSetKey) => {
  if (pageSetKey) {
    return getPageSetTitle(state, siteId, courseId, moduleKey, unitKey, pageSetKey);
  }
  if (unitKey) {
    return getUnitTitle(state, siteId, courseId, moduleKey, unitKey);
  }
  return getModuleTitle(state, siteId, courseId, moduleKey);
};

export const getAssessmentWork = (state, siteId, courseId, moduleKey, unitKey) => {
  const data = unitKey
    ? getUnit(state, siteId, courseId, moduleKey, unitKey)
    : getModule(state, siteId, courseId, moduleKey);
  return data.get('assessmentWork') || Map();
};

export const getAssessmentType = (module, unit) => {
  const moduleType = module.get('type_name');
  const unitType = unit.get('type');

  if (moduleType === 'exam') {
    return 'exam';
  }

  if (unitType === 'unit') {
    return 'quiz';
  }

  // pre-tests with unknown unitKey (from locked_quiz_id)
  if (!unitType) {
    return 'pre-test';
  }

  // tests and pre-tests
  return unitType;
};

export const getLessonWork = ({ data }, siteId, courseId, moduleKey, unitKey, pageSetKey) => (
  data.getIn([
    'sites',
    siteId,
    'courses',
    courseId,
    'modules',
    moduleKey,
    'units',
    unitKey,
    'pageSets',
    pageSetKey,
    'work',
  ]) || Map()
);

export const getSiteCourses = ({ data }, siteId) => data.getIn(['sites', siteId, 'courses']) || Map();

export const getSites = ({ data }) => (data.get('sites') || Map())
  .sort((a, b) => {
    const aName = a.get('name') || '';
    const bName = b.get('name') || '';
    return aName.localeCompare(bName, 'en', { sensitivity: 'base' });
  });

export const getCourses = ({ data }) => data.get('sites').reduce((acc, site) => (
  acc.merge(site.get('courses'))
), Map()).sort((a, b) => (
  a.get('course_title').localeCompare(b.get('course_title'), 'en', { sensitivity: 'base' })));

export const getAssessmentPages = ({ data }, assignmentId, assignmentEntryId) => (
  assignmentEntryId
    ? data.getIn(['assessments', assignmentId, assignmentEntryId, 'pageSet']) || Map()
    : data.getIn(['assessments', assignmentId, 'pageSet']) || Map()
);

export const getAssessmentResults = ({ data }, assignmentId, assignmentEntryId) => (
  assignmentEntryId
    ? data.getIn(['assessments', assignmentId, assignmentEntryId, 'results']) || Map()
    : data.getIn(['assessments', assignmentId, 'results']) || Map()
);

export const getAssessmentTtsData = ({ data }) => data.getIn(['assessments', 'tts']) || Map();

export const isAssessmentClosed = (state, siteId, courseId, moduleKey, unitKey) => {
  const includeOverrides = true;
  const module = getModule(state, siteId, courseId, moduleKey);
  const unit = getUnit(state, siteId, courseId, moduleKey, unitKey);
  const moduleClosed = getModuleIsClosed(module, includeOverrides);
  const unitClosed = getUnitIsClosed(unit, moduleClosed);
  const quizClosed = unit.get('quiz_status') === 'Closed';

  return moduleClosed || unitClosed || quizClosed;
};

export const getNextAssessmentRequestType = (state, siteId, courseId, moduleKey, unitKey) => {
  const course = getCourse(state, siteId, courseId);
  const module = getModule(state, siteId, courseId, moduleKey);
  const unit = getUnit(state, siteId, courseId, moduleKey, unitKey);
  const assessmentClosed = isAssessmentClosed(state, siteId, courseId, moduleKey, unitKey);
  const assessmentWork = getAssessmentWork(state, siteId, courseId, moduleKey, unitKey);
  const assessmentStatus = assessmentWork.get('status');
  const assessmentComplete = assessmentStatus > 1;
  const assessmentGrading = assessmentStatus > 2;
  // if an assessment is already in-progress, go ahead and request it, even if it's closed
  const assessmentAllowed = !assessmentComplete && (
    !assessmentClosed || course.has('locked_quiz_id')
  );
  const assignmentId = module.get('id');
  const assignmentEntryId = unit.get('id');
  const pageSet = getAssessmentPages(state, assignmentId, assignmentEntryId);
  const results = getAssessmentResults(state, assignmentId, assignmentEntryId);
  const viewAsStudent = isViewAsStudent(state);

  if (module.isEmpty() || assessmentGrading) {
    return 'wait';
  }

  if (unitKey && assessmentWork.isEmpty()) {
    return 'lesson';
  }

  if (assessmentComplete && results.isEmpty()) {
    if (viewAsStudent) {
      return 'done';
    }
    return 'results';
  }

  if (assessmentAllowed && pageSet.isEmpty()) {
    return 'assessment';
  }

  return 'done';
};

export const getChallengeData = ({ data }, siteId, courseId) => (
  data.getIn(['sites', siteId, 'courses', courseId, 'challenge']) || Map()
);

export const getKeyboardingResults = ({ data }, siteId, courseId) => (
  data.getIn(['sites', siteId, 'courses', courseId, 'keyboarding']) || Map()
);

export const isKeyboardingActive = (state, siteId, courseId) => {
  const keyboardingModule = getModule(state, siteId, courseId, 'BCKEYBOARD');
  const moduleOpen = keyboardingModule.get('open');
  const completed = keyboardingModule.get('completed_count');
  return !!moduleOpen && completed > 0;
};

export const isErrorCountExceeded = ({ data }) => (
  data.get('errorCount') > 2
);

export const getModuleIsBlocked = (units) => {
  const testIsClosedByPacing = units.find((unit) => unit.get('reason') === 'Incomplete Units');
  if (testIsClosedByPacing) {
    return !!units.find((unit) => (
      unit.get('type') === 'unit'
      && unit.get('status') === 'Closed'
      && unit.getIn(['work', 'status']) !== 2
    ));
  }
  return false;
};

export const getClosedReasons = (modules) => {
  const blockedByPacing = 'BLOCKED_BY_PACING';
  const blockedAndClosed = 'BLOCKED_AND_CLOSED';

  const moduleClosedByPacing = modules.find((m) => m.get('reason') === 'Previous module incomplete');
  const moduleIsBlocked = modules.find((m) => getModuleIsBlocked(m.get('units')));
  const moduleBlockedByClosed = modules.find((m) => m.get('reason') === 'Blocked by closed module');
  const openModules = modules.filter((m) => m.get('open') === true);
  const completedModules = openModules.filter((m) => m.get('completed_count') === m.get('completed_max'));
  const allOpenModulesCompleted = completedModules.size === openModules.size;

  if (moduleClosedByPacing && moduleIsBlocked) {
    return blockedByPacing;
  }

  if (moduleBlockedByClosed && (!openModules || allOpenModulesCompleted)) {
    return blockedAndClosed;
  }

  return '';
};

export const getCompetencyData = (data) => data.get('competencies') || Map();

export const getSiteName = ({ data }, siteId) => (
  data.getIn(['sites', siteId, 'name']) || ''
);

// unit.getIn(['work', 'status']) !== 2
