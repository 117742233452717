import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import './MigrateSiteList.scss';
import MigrateCourseItemList from '../containers/MigrateCourseItemList';

const MigrateSiteList = ({ sites, allSelected }) => (
  <div className="migrate-site-list">
    {sites.map((siteData) => {
      const teacherCourses = siteData.get('teacherCourses');
      const name = siteData.get('name');

      return teacherCourses?.size > 0
        ? (
          <MigrateCourseItemList
            key={name}
            siteName={name}
            courses={teacherCourses}
            allSelected={allSelected}
          />
        ) : null;
    })
      .toList()}
  </div>
);

MigrateSiteList.propTypes = {
  sites: PropTypes.instanceOf(Map).isRequired,
  allSelected: PropTypes.bool,
};

MigrateSiteList.defaultProps = { allSelected: false };

export default MigrateSiteList;
