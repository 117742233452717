import React from 'react';
import PropTypes from 'prop-types';
import { Route, Routes, useLocation } from 'react-router-dom';
import Patterns from './Patterns';
import StudentHelp from './StudenHelp';
import Student from '../containers/Student';
import Teacher from '../containers/Teacher';
import EnrollmentSingleSignOn from '../containers/EnrollmentSingleSignOn';
import TeacherInvite from '../containers/TeacherInvite';
import PasswordReset from '../containers/PasswordReset';
import TeacherHelp from '../containers/TeacherHelp';
import RegisterNewStudent from '../containers/RegisterNewStudent';
import Course from '../containers/Course';
import StudentCourses from '../containers/StudentCourses';
import TeacherSites from '../containers/TeacherSites';
import StudentProfile from '../containers/StudentProfile';
import Landing from '../containers/Landing';
import EnrollExistingStudent from '../containers/EnrollExistingStudent';
import ChallengeResultsPattern from './ChallengeResultsPattern';
import Keyboarding from '../containers/Keyboarding';
import NetworkErrorsMessage from '../containers/NetworkErrorsMessage';
import ModuleHeader from '../containers/ModuleHeader';
import MigrateSignInForm from './MigrateSignInForm';
import MigrateCourses from '../containers/MigrateCourses';
import MigrationProgress from '../containers/MigrationProgress';
import MigrationConfirmAccount from '../containers/MigrationConfirmAccount';
import MigrationAccountCreation from '../containers/MigrationAccountCreation';
import MigrationEmailReceived from '../containers/MigrationEmailReceived';

const Main = ({
  authToken,
  ssoEnroll,
  inviteCode,
  passwordReset,
  errorsExceeded,
}) => {
  const { pathname } = useLocation();
  const classes = pathname.replace(/\//g, ' ');

  if (!authToken && ssoEnroll) {
    return (<EnrollmentSingleSignOn ssoEnroll={ssoEnroll} />);
  }
  if (inviteCode) {
    return (<TeacherInvite inviteCode={inviteCode} />
    );
  }
  if (passwordReset) {
    return (<PasswordReset resetCode={passwordReset} />);
  }

  if (errorsExceeded) {
    return (<NetworkErrorsMessage />);
  }

  return (
    <>
      <ModuleHeader />
      <div className={`content ${classes}`}>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="teacher" element={<Teacher />} />
          <Route path="teacher/help" element={<TeacherHelp />} />
          <Route path="teacher/sites" element={<TeacherSites />} />
          <Route path="migrate" element={<MigrateSignInForm />} />
          <Route path="migrate/create-account/" element={<MigrationAccountCreation />} />
          <Route path="migrate/sites/" element={<MigrateCourses />} />
          <Route path="migrate/sites/progress/" element={<MigrationProgress />} />
          <Route path="migrate/confirm-account/" element={<MigrationConfirmAccount />} />
          <Route path="migrate/sites/complete/" element={<MigrationEmailReceived />} />
          <Route path="student" element={<Student />} />
          <Route path="student/help" element={<StudentHelp />} />
          <Route path="settings" element={<StudentProfile />} />
          <Route path="courses" element={<StudentCourses />} />
          <Route path="site/:siteId/course/:courseId/keyboard-game/results/" element={<Keyboarding />} />
          <Route path="site/:siteId/course/:courseId/*" element={<Course />} />
          <Route path="patterns" element={<Patterns />} />
          <Route path="challenge-results-pattern" element={<ChallengeResultsPattern />} />
          <Route path="enroll/login" element={<EnrollExistingStudent />} />
          <Route path="enroll/*" element={<RegisterNewStudent />} />
        </Routes>
      </div>
    </>
  );
};

Main.propTypes = {
  authToken: PropTypes.string.isRequired,
  errorsExceeded: PropTypes.bool.isRequired,
  ssoEnroll: PropTypes.string,
  inviteCode: PropTypes.string,
  passwordReset: PropTypes.string,
};

Main.defaultProps = {
  ssoEnroll: null,
  inviteCode: null,
  passwordReset: null,
};

export default Main;
