import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import './input.scss';
import './Checkbox.scss';

const Checkbox = ({
  id,
  name,
  checked,
  onChange,
  label,
  disableCheckbox,
  migrated,
  examsOutstanding,
  notSupported,
}) => (
  <>
    <label htmlFor={id || name} className={disableCheckbox ? 'checkbox disabled' : 'checkbox'}>
      <input type="checkbox" id={id || name} name={name} onChange={onChange} disabled={disableCheckbox} />
      <Icon text={(checked || (disableCheckbox && !notSupported)) ? 'checked-checkbox' : 'empty-checkbox'} />
      <p>{label}</p>
      {migrated && (
        <div className="badge migrated">Migrated</div>
      )}
      {examsOutstanding && (
        <div className="badge exams-outstanding">Exams Outstanding</div>
      )}
      {notSupported && (
        <div className="badge not-supported">Not Supported</div>
      )}
    </label>
  </>
);

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  disableCheckbox: PropTypes.bool,
  migrated: PropTypes.bool,
  examsOutstanding: PropTypes.bool,
  notSupported: PropTypes.bool,
};

Checkbox.defaultProps = {
  checked: false,
  disableCheckbox: false,
  migrated: false,
  examsOutstanding: false,
  notSupported: false,
};

export default Checkbox;
