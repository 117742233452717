import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { formatDueDate, isPastDue, parseDueDate } from '../utils/date';
import {
  getTipsColor,
  getClosedType,
  getModuleCompletion,
  formatClosedReason,
} from '../utils/pacing';
import { getUnits, getTests } from '../redux/Data/selectors';
import ModuleListItemRow from './ModuleListItemRow';
import ModuleListItemCard from './ModuleListItemCard';
import './ModuleListItem.scss';
import { formatGrade } from '../utils/formatting';

const ModuleListItem = ({
  baseUrl,
  closeReason,
  data,
  isClosed,
  moduleKey,
  rowFormat,
}) => {
  const moduleTitle = data.get('title');
  const units = getUnits(data);
  const due = data.get('due');
  const dueDate = parseDueDate(due);
  const pastDue = isPastDue(due);
  const type = data.get('type_name');
  const tipColor = getTipsColor(closeReason);
  const { icon, statusClass } = getClosedType(closeReason, isClosed);
  const { completedCount, completedMax } = getModuleCompletion(data);
  // code below flags modules without units, tests and/or quizzes
  const noUnitsInModule = completedMax === 0;
  const noTestsInModule = getTests(data).size === 0;
  const noQuizInModule = units
    ?.filter((unit) => unit.get('quiz_status') !== 'No Quiz').size === 0;
  const noAssessmentsInModule = noQuizInModule && noTestsInModule;
  // noGradesInModule flags units that don't have any units, tests or quizzes
  const noGradesInModule = noUnitsInModule || noAssessmentsInModule;
  const completed = noGradesInModule
    ? 'N/A' : `${completedCount} of ${completedMax}`;
  const gradeInProgress = noGradesInModule
    ? 'N/A' : formatGrade(type === 'exam' ? null : data.get('grade'));
  const gradeFinal = noGradesInModule
    ? 'N/A' : formatGrade(data.get('grade_final'));
  const closeReasonFormatted = formatClosedReason(closeReason);
  const dueDateFormatted = formatDueDate(dueDate);

  return rowFormat
    ? (
      <ModuleListItemRow
        type={type}
        baseUrl={baseUrl}
        closeReason={closeReasonFormatted}
        completedCount={completedCount}
        completedMax={completedMax}
        completed={completed}
        due={due}
        dueDate={dueDateFormatted}
        gradeFinal={gradeFinal}
        gradeInProgress={gradeInProgress}
        icon={icon}
        isClosed={isClosed}
        moduleTitle={moduleTitle}
        moduleKey={moduleKey}
        pastDue={pastDue}
        statusClass={statusClass}
        tipColor={tipColor}
      />
    )
    : (
      <ModuleListItemCard
        type={type}
        baseUrl={baseUrl}
        closeReason={closeReasonFormatted}
        completed={completed}
        due={due}
        dueDate={dueDateFormatted}
        gradeFinal={gradeFinal}
        gradeInProgress={gradeInProgress}
        icon={icon}
        isClosed={isClosed}
        moduleTitle={moduleTitle}
        moduleKey={moduleKey}
        pastDue={pastDue}
        statusClass={statusClass}
        tipColor={tipColor}
      />
    );
};

ModuleListItem.propTypes = {
  baseUrl: PropTypes.string,
  closeReason: PropTypes.string,
  data: PropTypes.instanceOf(Map).isRequired,
  isClosed: PropTypes.bool,
  moduleKey: PropTypes.string.isRequired,
  rowFormat: PropTypes.bool,
};

ModuleListItem.defaultProps = {
  baseUrl: '.',
  closeReason: '',
  isClosed: false,
  rowFormat: false,
};

export default ModuleListItem;
