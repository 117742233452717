import React, { useState } from 'react';
import PropTypes from 'prop-types';

const RegisterAgeCheck = ({ onConfirm }) => {
  const [underage, setUnderage] = useState(false);
  if (underage) {
    return (
      <div className="age-check-message">
        <h1>Important Notice</h1>
        <h2>If you are under 13 years old, do not fill out this form.</h2>
        <h2>Talk to your teacher about enrollment.</h2>
        <div className="content-box">
          <h3>Why is this?</h3>
          <p>
            We are not allowed to collect personal information from children under 13
            {' in line with the '}
            <a
              href="https://www.ftc.gov/enforcement/rules/rulemaking-regulatory-reform-proceedings/childrens-online-privacy-protection-rule"
              target="_blank"
              rel="noopener noreferrer"
            >
              {'Children\'s Online Privacy Protection Rule ("COPPA")'}
            </a>
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="age-check">
      <h3 className="enrollment">How old are you?</h3>
      <button className="secondary" type="button" onClick={() => setUnderage(true)}>
        I am under 13
      </button>
      <button className="secondary" type="button" onClick={onConfirm}>
        I am 13 or Over
      </button>
    </div>
  );
};

RegisterAgeCheck.propTypes = { onConfirm: PropTypes.func.isRequired };

export default RegisterAgeCheck;
