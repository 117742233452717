/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const FocusTrap = ({ children, updateElements }) => {
  const topTabTrap = useRef(null);
  const bottomTabTrap = useRef(null);
  const container = useRef(null);

  useEffect(() => {
    const elements = container.current.querySelectorAll('a, button, input:not(#hide)');
    let trapFocus = () => {};

    if (container.current && elements.length > 0) {
      const firstEl = elements[0];
      const lastEl = elements[elements.length - 1];

      // auto focus first focusable element
      firstEl.focus();

      trapFocus = (event) => {
        if (event.target === topTabTrap.current) {
          lastEl.focus();
        }

        if (event.target === bottomTabTrap.current) {
          firstEl.focus();
        }
      };
    }

    container.current.addEventListener('focusin', trapFocus);

    return () => container.current && container.current.removeEventListener('focusin', trapFocus);
  }, [updateElements, container]);

  return (
    <div ref={container}>
      <span ref={topTabTrap} tabIndex="0" />
      {children}
      <span ref={bottomTabTrap} tabIndex="0" />
    </div>
  );
};

FocusTrap.propTypes = {
  children: PropTypes.node.isRequired,
  updateElements: PropTypes.bool,
};

FocusTrap.defaultProps = {
  updateElements: false,
};

export default FocusTrap;
