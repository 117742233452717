import { delay, put, takeEvery } from 'redux-saga/effects';
import { addHours, parseJSON } from 'date-fns';
import {
  requestEventData,
  requestEventDataSuccess,
  STATUS_EVENTS_REQUEST,
} from '../PlatformStatus/actions';
import { getEventData } from '../../services/API';

function* doRequestEvents() {
  const eventData = yield getEventData();
  if (eventData.size > 0) {
    const events = eventData.map((event) => {
      const startDateTime = event.get('startDateTime');
      const endDateTime = event.get('endDateTime');
      const logoutDateTime = event.get('logoutDateTime');
      const startDate = startDateTime ? parseJSON(startDateTime) : new Date();
      const endDate = endDateTime ? parseJSON(endDateTime) : addHours(startDate, 1);
      const logoutDate = logoutDateTime && parseJSON(logoutDateTime);

      return event.merge({ startDate, endDate, logoutDate });
    });
    yield put(requestEventDataSuccess(events));
    // re-trigger self every 15 min to keep event data up-to-date;
    yield delay(900000);
    yield put(requestEventData());
  }
}

// eslint-disable-next-line import/prefer-default-export
export function* watchEventsRequest() {
  yield takeEvery(STATUS_EVENTS_REQUEST, doRequestEvents);
}
