import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Alert from './Alert';
import Input from './Input';
import Logos from '../images/migration-logo.png';
import GoogleSignIn from './GoogleSignIn';
import './MigrateSignInForm.scss';
import {
  authenticate,
  logout,
  setTeacherCourseMigration,
} from '../redux/Authentication/actions';
import { findError } from '../utils/errors';
import MigrationRedirect from '../containers/MigrationRedirect';

const adjustUsernameError = (error) => (error.replace(' or username', ''));

const MigrateSignInForm = ({
  error,
  message,
  dispatchAuthenticate,
  dispatchAuthenticateProvider,
  dispatchSetTeacherCourseMigration,
  authToken,
  cevOrgName,
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    dispatchSetTeacherCourseMigration();
  }, []);

  const signIn = (event) => {
    event.preventDefault();
    dispatchAuthenticate(email, password);
  };

  const signInGoogle = (googleResponse) => {
    const { credential } = googleResponse;
    dispatchAuthenticateProvider('google', credential);
  };

  return (
    authToken
      ? <MigrationRedirect />
      : (
        <>
          {message
            ? <Alert message={message} />
            : null}
          <div className="logos">
            <img className="migration-logos" src={Logos} alt="Migrate from AES to iCEV" />
          </div>
          <div className="sign-in-form">
            <form onSubmit={(event) => signIn(event)}>
              <>
                <h1>Sign In to Migrate your Courses</h1>
                <p>
                  You need to sign into your AES account to specify
                  {' '}
                  which courses you want to migrate to
                  {' '}
                  {cevOrgName}
                  .
                  {' '}
                  Once you have authorized the migration you will be redirected to iCEV.
                </p>
              </>
              <Input
                id="username"
                name="username"
                autoComplete="username"
                placeholder="Email"
                value={email}
                error={error.field === 'email' ? adjustUsernameError(error.message, 'teacher') : ''}
                onChange={({ target: { value } }) => setEmail(value)}
              />
              <Input
                id="password"
                type="password"
                name="password"
                autoComplete="password"
                placeholder="Password"
                value={password}
                error={error.field === 'password' ? error.message : ''}
                onChange={({ target: { value } }) => setPassword(value)}
              />
              <button id="sign-in" className="primary" type="submit">Sign In</button>
              <p className="center">
                <Link to="/teacher/help">
                  Forgot Password?
                </Link>
              </p>
              <div className="separator">OR</div>
              <GoogleSignIn signInGoogle={signInGoogle} />
              {error.field === 'sso'
                ? <Alert id="error_sso" type="error" message={error.message} />
                : null}
            </form>
          </div>
        </>
      )
  );
};

MigrateSignInForm.propTypes = {
  authToken: PropTypes.string,
  message: PropTypes.string,
  cevOrgName: PropTypes.string,
  error: PropTypes.shape({ field: PropTypes.string, message: PropTypes.string }),
  dispatchAuthenticate: PropTypes.func.isRequired,
  dispatchAuthenticateProvider: PropTypes.func.isRequired,
  dispatchSetTeacherCourseMigration: PropTypes.func.isRequired,
};

MigrateSignInForm.defaultProps = {
  authToken: '',
  message: '',
  cevOrgName: '',
  error: {},
};

const mapStateToProps = ({ authentication }) => ({
  ltiVerify: authentication.get('ltiVerify'),
  ltiEmail: authentication.get('ltiEmail'),
  ltiProvider: authentication.get('ltiProvider'),
  error: findError(authentication.get('error')),
  message: authentication.get('message'),
  authToken: authentication.get('authToken'),
  cevOrgName: authentication.get('cevOrgName'),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchAuthenticate: (email, password) => (
    dispatch(authenticate({
      email, password, userRole: 'teacher', courseMigration: true,
    }))
  ),
  dispatchAuthenticateProvider: (provider, providerToken) => (
    dispatch(authenticate({
      provider, providerToken, userRole: 'teacher', courseMigration: true,
    }))
  ),
  dispatchLogout: () => dispatch(logout()),
  dispatchSetTeacherCourseMigration: () => dispatch(setTeacherCourseMigration()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MigrateSignInForm);
