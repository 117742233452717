import React from 'react';
import PropTypes from 'prop-types';

class GoogleSignIn extends React.Component {
  componentDidMount() {
    const { signInGoogle } = this.props;
    const script = document.createElement('script');
    script.id = 'GOOGLE_SCRIPT';
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;

    window.AES_GOOGLE_SIGNIN = signInGoogle;
    document.body.appendChild(script);
  }

  componentWillUnmount() {
    const script = document.getElementById('GOOGLE_SCRIPT');

    document.body.removeChild(script);
  }

  render() {
    const { REACT_APP_GOOGLE_CLIENT_ID } = process.env;

    return (
      <div>
        <div
          id="g_id_onload"
          data-client_id={REACT_APP_GOOGLE_CLIENT_ID}
          data-callback="AES_GOOGLE_SIGNIN"
          data-auto_prompt="false"
        />
        <div
          className="g_id_signin"
          data-type="standard"
          data-size="large"
          data-theme="outline"
          data-text="sign_in_with"
          data-shape="rectangular"
          data-width="325"
          data-logo_alignment="left"
        />
      </div>
    );
  }
}

GoogleSignIn.propTypes = { signInGoogle: PropTypes.func.isRequired };

export default (GoogleSignIn);
