import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import './ItemLists.scss';
import './WorksheetTable.scss';

const WorksheetTable = ({ url, status, type }) => (
  <div className="itemContainer worksheets">
    <table className="worksheet">
      <thead>
        <tr>
          <th>
            <h3>
              Worksheet
            </h3>
          </th>
          { type !== 'pdf'
            ? (
              <th>
                <h3>
                  Progress
                </h3>
              </th>
            )
            : null }
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="worksheet lessonTitle">
            <h3>
              <a href={url} target="aes_worksheet">
                Unit Worksheet
              </a>
            </h3>
          </td>
          <td className="status">
            { status && status !== 'not-started'
              ? <Icon text={status === 'completed' ? 'check' : 'progress'} />
              : null }
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);

WorksheetTable.propTypes = {
  url: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default WorksheetTable;
