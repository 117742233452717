import {
  call,
  delay,
  put,
  select,
  takeEvery,
  takeLatest,
  takeLeading,
  throttle,
} from 'redux-saga/effects';
import { List, Map } from 'immutable';
import { decode } from 'he';
import {
  DATA_ASSESSMENT_FINISH,
  DATA_ASSESSMENT_FINISH_JOB,
  DATA_ASSESSMENT_LOCKED_ASSESSMENT_REQUEST,
  DATA_ASSESSMENT_REQUEST,
  DATA_ASSESSMENT_REQUEST_TTS,
  DATA_ASSESSMENT_REQUEST_TTS_JOB,
  DATA_ASSESSMENT_RESULTS_REQUEST,
  DATA_ASSESSMENT_SAVE,
  DATA_ASSESSMENT_TIMER_UPDATED,
  DATA_ASSESSMENT_WORK_UPDATED,
  requestAssessmentDataFailed,
  requestAssessmentDataSucceeded,
  requestAssessmentResultsFailed,
  requestAssessmentResultsSucceeded,
  requestCourseData,
  requestLockedAssessmentDataFailed,
  requestFinishAssessmentJob,
  requestLessonData,
  requestQuestionTts,
  requestQuestionTtsFailed,
  requestQuestionTtsJob,
  requestQuestionTtsSucceeded,
  updateAssessmentWork,
  requestAssessmentResults,
  clearAssessmentResults,
  updateQuizRetry,
} from '../Data/actions';
import {
  setAssessmentMetadata,
  setAssessmentComplete,
} from '../View/actions';
import { getSiteToken, isViewAsStudent } from '../Authentication/selectors';
import {
  getAssessmentWork,
  getAssessmentType,
  getCourseModules,
  getModule,
  getUnit,
} from '../Data/selectors';
import {
  finishAssessment,
  getAssessmentData,
  getAssessmentDataViewAsStudent,
  getAssessmentResults,
  getAssessmentStatus,
  getJob,
  getLockedAssessmentData,
  getQuestionTts,
} from '../../services/API';
import { getAssessmentMetadata } from '../View/selectors';
import { doRequestModuleData } from './lessons';
import { logout } from '../Authentication/actions';

export function* doRequestAssessmentData({
  siteId,
  courseId,
  moduleKey,
  unitKey,
}) {
  const siteToken = yield select(getSiteToken, siteId);
  const moduleData = yield select(getModule, siteId, courseId, moduleKey);
  const unitData = yield select(getUnit, siteId, courseId, moduleKey, unitKey);
  const assessmentType = getAssessmentType(moduleData, unitData);
  const isPreTest = assessmentType === 'pre-test';
  const assignmentId = moduleData.get('id');
  const assignmentEntryId = unitData.get('id');

  let title;
  if (assessmentType === 'quiz') {
    title = `${unitData.get('title')} Quiz`;
  } else if (assessmentType === 'test') {
    title = `${moduleData.get('title')} Test`;
  } else if (assessmentType === 'pre-test') {
    title = `${moduleData.get('title')} Pre-Test`;
  } else {
    title = moduleData.get('title');
  }

  // assignmentEntryId will be undefined for exams
  if (siteToken && assignmentId) {
    const viewAsStudent = yield select(isViewAsStudent);
    const apiMethod = viewAsStudent
      ? getAssessmentDataViewAsStudent
      : getAssessmentData;

    try {
      const result = yield call(
        apiMethod, courseId, assignmentId, assignmentEntryId, isPreTest, siteToken,
      );
      const error = result.get('error');
      if (error) {
        yield put(requestAssessmentDataFailed(assignmentId, assignmentEntryId, error, result.get('code')));
      } else {
        const lessonWorkId = result.getIn(['lesson_work', 'id']);
        const assessmentMetadata = {
          assessmentType,
          siteId,
          courseId,
          assignmentId,
          assignmentEntryId,
          lessonWorkId,
          moduleKey,
          unitKey,
        };

        const assessmentWork = result.get('lesson_work');
        const learnosityRequest = result.get('learnosity_request');

        const pageSet = Map({
          assessmentType,
          unit_title: title,
          learnosityRequest,
        });

        // NB order of yields matters, updating work before request causes old session to init
        yield put(requestAssessmentDataSucceeded(assignmentId, assignmentEntryId, pageSet));
        yield put(setAssessmentMetadata(assessmentMetadata));
        yield put(updateAssessmentWork(siteId, courseId, moduleKey, unitKey, assessmentWork));
        yield put(clearAssessmentResults(assignmentId, assignmentEntryId));
      }
    } catch (error) {
      yield put(requestAssessmentDataFailed(
        assignmentId,
        assignmentEntryId,
        'Error contacting API server',
      ));
    }
  }
}

export function* doRequestLockedAssessmentMetadata({ siteId, lessonWorkId }) {
  const siteToken = yield select(getSiteToken, siteId);
  try {
    const response = yield call(getLockedAssessmentData, lessonWorkId, siteToken);
    const error = response.get('error');

    if (error) {
      yield put(requestLockedAssessmentDataFailed(siteId, lessonWorkId, error, response.get('code')));
    } else {
      const courseId = `${response.get('course_id')}`;
      const assignmentId = response.get('assignment_id');
      const assignmentEntryId = response.get('assignment_entry_id');
      const modules = yield select(getCourseModules, siteId, courseId);

      // On first pass modules will likely be empty, course data success will re-put metadata action
      if (!modules) {
        yield put(requestCourseData(siteId, courseId));
      } else {
        const moduleData = modules.find((module) => module.get('id') === assignmentId);
        const moduleKey = moduleData.get('module_key');
        const units = moduleData.get('units') || Map();
        const unitData = units.find((unit) => unit.get('id') === assignmentEntryId) || Map();
        const assessmentType = getAssessmentType(moduleData, unitData);
        const unitKey = assessmentType === 'pre-test'
          ? `PREMT_${moduleKey}`
          : unitData.get('unit_key');

        const assessmentMetadata = {
          assessmentType,
          siteId,
          courseId,
          assignmentId,
          assignmentEntryId,
          lessonWorkId,
          moduleKey,
          unitKey,
          locked: true,
        };

        yield put(setAssessmentMetadata(assessmentMetadata));
      }
    }
  } catch (error) {
    yield put(requestLockedAssessmentDataFailed(siteId, lessonWorkId, error));
  }
}

export function* doRequestAssessmentResults({
  siteId,
  courseId,
  moduleKey,
  unitKey,
}) {
  const siteToken = yield select(getSiteToken, siteId);
  const moduleData = yield select(getModule, siteId, courseId, moduleKey);
  const unitData = yield select(getUnit, siteId, courseId, moduleKey, unitKey);
  const assessmentWork = yield select(getAssessmentWork, siteId, courseId, moduleKey, unitKey);
  const lessonWorkId = assessmentWork.get('id');
  const assignmentId = moduleData.get('id');
  const assignmentEntryId = unitData.get('assignment_entry_id');
  try {
    const response = yield call(getAssessmentResults, courseId, lessonWorkId, siteToken);
    const error = response.get('error');
    if (error) {
      yield put(requestAssessmentResultsFailed(assignmentId, assignmentEntryId, error, response.get('code')));
    } else {
      const answers = response.get('answers')?.map((answer) => (
        answer.updateIn(['question', 'paragraphs'], (text) => decode(text).replace('<P>', '\n\n'))
      ));
      const history = response.get('quiz_history') || List();
      const learnosityRequest = response.get('learnosity_request');
      const results = Map({ answers, history, learnosityRequest });
      const newAssessmentWork = assessmentWork.merge(history.first());
      yield put(requestAssessmentResultsSucceeded(assignmentId, assignmentEntryId, results));
      yield put(updateAssessmentWork(siteId, courseId, moduleKey, unitKey, newAssessmentWork));
    }
  } catch (error) {
    yield put(requestAssessmentResultsFailed(assignmentId, assignmentEntryId, error));
  }
}

export function* doRequestTts({ siteId, question }) {
  const { key } = question;
  const siteToken = yield select(getSiteToken, siteId);

  try {
    const response = yield call(getQuestionTts, question, siteToken);
    const error = response.get('error');
    const ttsUrl = response.get('tts_file_url');
    const ttsJobId = response.get('job_id');

    if (error) {
      yield put(requestQuestionTtsFailed(key, error, response.get('code')));
    } else if (ttsJobId) {
      yield put(requestQuestionTtsJob(siteId, question, ttsJobId));
    } else if (ttsUrl) {
      yield put(requestQuestionTtsSucceeded(key, ttsUrl));
    }
  } catch (error) {
    yield put(requestQuestionTtsFailed(key, error));
  }
}

export function* doRequestTtsJob({ siteId, question, ttsJobId }) {
  const { key } = question;
  const siteToken = yield select(getSiteToken, siteId);

  try {
    const response = yield call(getJob, ttsJobId, siteToken);
    const error = response.get('error');
    const status = response.get('status');

    if (error) {
      yield put(requestQuestionTtsFailed(key, error, response.get('code')));
    } else if (status === 'completed') {
      yield put(requestQuestionTts(siteId, question));
    } else if (status === 'queued') {
      yield put(requestQuestionTtsJob(siteId, question, ttsJobId));
    }
  } catch (error) {
    yield put(requestQuestionTtsFailed(key, error));
  }
}

export function* doRequestFinishAssessmentJob({ jobId }) {
  const assessmentMeta = yield select(getAssessmentMetadata);
  const siteId = assessmentMeta.get('siteId');
  const courseId = assessmentMeta.get('courseId');
  const moduleKey = assessmentMeta.get('moduleKey');
  const unitKey = assessmentMeta.get('unitKey');
  const assignmentEntryId = assessmentMeta.get('assignmentEntryId');
  const siteToken = yield select(getSiteToken, siteId);

  yield delay(1000);
  const response = yield call(getJob, jobId, siteToken);
  const status = response.get('status');

  if (status === 'completed') {
    if (assignmentEntryId) { // quiz or test, reload full lesson data
      yield put(requestLessonData(siteId, courseId, moduleKey, unitKey));
    } else { // exam or pre-test, jump to requesting results
      yield put(requestAssessmentResults(siteId, courseId, moduleKey, unitKey));
    }
  } else {
    yield put(requestFinishAssessmentJob(jobId));
  }
}

export function* doFinishAssessment({ learnositySession }) {
  const assessmentMeta = yield select(getAssessmentMetadata);
  const assignmentId = assessmentMeta.get('assignmentId');
  const assignmentEntryId = assessmentMeta.get('assignmentEntryId');
  const siteId = assessmentMeta.get('siteId');
  const courseId = assessmentMeta.get('courseId');
  const moduleKey = assessmentMeta.get('moduleKey');
  const unitKey = assessmentMeta.get('unitKey');
  const lessonWorkId = assessmentMeta.get('lessonWorkId');
  const assessmentWork = yield select(getAssessmentWork, siteId, courseId, moduleKey, unitKey);
  const siteToken = yield select(getSiteToken, siteId);
  const viewAsStudent = yield select(isViewAsStudent);

  if (viewAsStudent) {
    yield put(setAssessmentComplete());
    yield put(
      updateAssessmentWork(siteId, courseId, moduleKey, unitKey, assessmentWork.set('status', 2)),
    );
  } else {
    yield delay(1000);
    const response = yield call(
      finishAssessment,
      courseId,
      lessonWorkId,
      learnositySession,
      siteToken,
    );
    const jobId = response.get('job_id');
    if (jobId) {
      yield put(setAssessmentComplete());
      yield put(
        updateAssessmentWork(siteId, courseId, moduleKey, unitKey, assessmentWork.set('status', 3)),
      );
      yield put(requestFinishAssessmentJob(jobId));
    } else {
      yield put(requestAssessmentDataFailed(
        assignmentId,
        assignmentEntryId,
        'Error finishing assessment',
      ));
    }
  }
}

export function* doUpdateQuizRetry({
  siteId,
  courseId,
  moduleKey,
  unitKey,
}) {
  const assessmentWork = yield select(getAssessmentWork, siteId, courseId, moduleKey, unitKey);
  const attemptsLeft = assessmentWork.get('attempts_left');
  const attemptTimer = assessmentWork.get('attempt_timer');

  // decrement attempt_timer after one minute if it is set and there are remaining attempts.
  if (attemptsLeft > 0 && attemptTimer > 0) {
    yield delay(60000);
    yield put(updateQuizRetry(siteId, courseId, moduleKey, unitKey, attemptTimer - 1));
  }
}

export function* doHandleAssessmentWorkChange(action) {
  const {
    siteId,
    courseId,
    moduleKey,
    unitKey,
    assessmentWork = Map(),
  } = action;
  const status = assessmentWork.get('status');
  const isCompleted = status === 2;

  if (isCompleted) {
    yield call(doRequestModuleData, { siteId, courseId, moduleKey });
    yield call(doUpdateQuizRetry, {
      siteId, courseId, moduleKey, unitKey,
    });
  }
}

export function* doCheckAsessmentSaveable(action) {
  const {
    siteId,
    courseId,
    moduleKey,
    unitKey,
  } = action;

  const siteToken = yield select(getSiteToken, siteId);
  const assessmentWork = yield select(getAssessmentWork, siteId, courseId, moduleKey, unitKey);
  const lessonWorkId = assessmentWork.get('id');

  try {
    const response = yield call(getAssessmentStatus, courseId, lessonWorkId, siteToken);
    const error = response.get('error');
    const status = response.get('status');

    if (error) {
      yield put(logout('Logged out due to inactivity.'));
    } else if (status > 1) {
      yield put(updateAssessmentWork(
        siteId, courseId, moduleKey, unitKey, assessmentWork.set('status', status),
      ));
    } else {
      yield put(updateAssessmentWork(
        siteId, courseId, moduleKey, unitKey, assessmentWork.set('pendingSave', true),
      ));
    }
  } catch (error) {
    yield put(logout('Assessment error. Please log in again.'));
  }
}

export function* watchAssessmentDataRequest() {
  yield takeLeading(DATA_ASSESSMENT_REQUEST, doRequestAssessmentData);
}

export function* watchAssessmentResultsRequest() {
  yield takeLatest(DATA_ASSESSMENT_RESULTS_REQUEST, doRequestAssessmentResults);
}

export function* watchFinishAssessment() {
  yield takeLeading(DATA_ASSESSMENT_FINISH, doFinishAssessment);
}

export function* watchFinishAssessmentJob() {
  yield takeLeading(DATA_ASSESSMENT_FINISH_JOB, doRequestFinishAssessmentJob);
}

export function* watchAssessmentTtsRequest() {
  yield takeLatest(DATA_ASSESSMENT_REQUEST_TTS, doRequestTts);
}

export function* watchAssessmentTtsJobRequest() {
  yield throttle(500, DATA_ASSESSMENT_REQUEST_TTS_JOB, doRequestTtsJob);
}

export function* watchAssessmentUpdate() {
  yield takeEvery(DATA_ASSESSMENT_WORK_UPDATED, doHandleAssessmentWorkChange);
}

export function* watchAssessmentTimerUpdate() {
  yield takeLatest(DATA_ASSESSMENT_TIMER_UPDATED, doUpdateQuizRetry);
}

export function* watchLockedAssessmentRequest() {
  yield takeLatest(DATA_ASSESSMENT_LOCKED_ASSESSMENT_REQUEST, doRequestLockedAssessmentMetadata);
}

export function* watchAssessmentSave() {
  yield takeLatest(DATA_ASSESSMENT_SAVE, doCheckAsessmentSaveable);
}
