import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Alert from './Alert';
import Icon from './Icon';
import './input.scss';

const Input = ({
  autoComplete,
  name,
  type,
  value,
  label,
  placeholder,
  error,
  disabled,
  onChange,
  currentRef,
}) => {
  const isPassword = type === 'password';
  const [showPassword, setShowPassword] = useState(false);
  const finalType = (isPassword && showPassword) ? 'text' : type;

  return (
    <>
      <label htmlFor={name}>
        <span className={!label ? 'sr-only' : 'label'}>{label || placeholder}</span>
        <input
          autoComplete={autoComplete}
          type={finalType}
          placeholder={placeholder || label}
          name={name}
          value={value}
          disabled={disabled}
          aria-describedby={error ? `error_${name}` : null}
          id={name}
          className={`${error ? 'error' : ''} ${isPassword ? 'password' : ''}`}
          onChange={onChange}
          ref={currentRef}
        />
        {(isPassword) ? (
          <button
            type="button"
            className={`icon onlyIcon ${value ? '' : 'hide'}`}
            onClick={() => setShowPassword(!showPassword)}
          >
            <Icon text={showPassword ? 'eye-off' : 'eye'} />
            <span className="tooltip right">{showPassword ? 'Hide Password' : 'Show Password'}</span>
          </button>
        ) : null}
      </label>
      {error ? <Alert id={`error_${name}`} type="error" message={error} /> : null}
    </>
  );
};

Input.propTypes = {
  autoComplete: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  currentRef: PropTypes.any,
};

Input.defaultProps = {
  autoComplete: 'off',
  type: 'text',
  value: '',
  label: '',
  placeholder: '',
  error: '',
  disabled: false,
  onChange: () => {},
  currentRef: null,
};

export default Input;
