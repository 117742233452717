import { createLogger } from 'redux-logger';

const immutableTransformer = (target) => {
  const result = {};
  const object = target.toObject ? target.toObject() : target;

  Object.keys(object).forEach((key) => {
    if (object[key] && object[key].toJS) {
      result[key] = object[key].toJS();
    } else {
      result[key] = object[key];
    }
  });

  return result;
};

export default createLogger({
  collapsed: true,
  actionTransformer: immutableTransformer,
  stateTransformer: immutableTransformer,
});
