import React from 'react';
import './ConfirmICEVAccount.scss';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { icevOrgExists } from '../redux/Authentication/selectors';

const { REACT_APP_ICEV_LOGIN } = process.env;

const MigrationConfirmAccount = () => {
  const hasIcevOrg = useSelector(icevOrgExists);

  return (
    <div className="confirm-icev-account">
      <div className="account-confirmation">
        <h1>Do you have an iCEV account?</h1>
        <p>
          If you already have an iCEV account, you can continue with the migration.
          {' '}
          If you dont, no worries, we will create one for you and then migrate your courses.
        </p>
        <div className="links">
          <a href={`${REACT_APP_ICEV_LOGIN}/mycourses/aes-migration`} className="button primary">
            Yes, Continue
          </a>
          { hasIcevOrg
            ? (
              <Link to="/migrate/create-account/" className="button secondary">
                No, Create my iCEV Account
              </Link>
            )
            : (
              <a href="https://www.icevonline.com/contact-us" className="button secondary">
                No, contact Support for help
              </a>
            ) }
        </div>
      </div>
    </div>
  );
};

export default MigrationConfirmAccount;
