import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import StudentProfileForm from '../components/StudentProfileForm';
import './StudentProfile.scss';
import {
  updateStudent,
  updateStudentClear,
} from '../redux/Authentication/actions';

const StudentProfile = ({
  data, dispatchUpdateStudent, errors, updateSuccess, dispatchClearUpdate,
}) => {
  const [editPassword, setEditPassword] = useState(false);
  const [editableData, setEditableData] = useState(data);
  const editProfileData = useCallback(({ target: { name, value } }) => {
    setEditableData((prevState) => prevState.set(name, value));
  });
  const resetPasswordState = useCallback(() => {
    setEditPassword(!editPassword);
    setEditableData(data);
    dispatchClearUpdate();
  });
  const updateStudentProfile = useCallback((event) => {
    event.preventDefault();
    dispatchUpdateStudent(editableData.toJSON());
  });

  useEffect(() => {
    if (updateSuccess) setEditPassword(false);
    setEditableData(data);
  }, [updateSuccess, data]);

  return (
    <div className="profileContainer">
      <StudentProfileForm
        toggleEditPassword={() => setEditPassword(!editPassword)}
        resetPasswordState={() => resetPasswordState()}
        editPassword={editPassword}
        data={editableData}
        editData={editProfileData}
        submit={updateStudentProfile}
        errors={errors}
        updateSuccess={updateSuccess}
      />
    </div>
  );
};

const mapStateToProps = ({ authentication }) => ({
  data: authentication.get('user'),
  errors: authentication.get('updateStudentErrors') || Map(),
  updateSuccess: authentication.get('updateSuccess'),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchUpdateStudent: (data) => dispatch(updateStudent(data)),
  dispatchClearUpdate: () => dispatch(updateStudentClear()),
});

StudentProfile.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired,
  errors: PropTypes.instanceOf(Map).isRequired,
  updateSuccess: PropTypes.bool,
  dispatchUpdateStudent: PropTypes.func.isRequired,
  dispatchClearUpdate: PropTypes.func.isRequired,
};

StudentProfile.defaultProps = {
  updateSuccess: false,
};
export default connect(mapStateToProps, mapDispatchToProps)(StudentProfile);
