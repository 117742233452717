/**
 * Accepts a grade from 0 - 1 and returns a string representation percentage.
 * @param gradeNumber
 * @returns {string}
 */
export const formatGrade = (gradeNumber) => (
  gradeNumber == null || Number.isNaN(gradeNumber)
    ? '-'
    : `${Math.round(gradeNumber * 100)}%`
);

const converterElement = document.createElement('span');
export const htmlToText = (html) => {
  converterElement.innerHTML = html;
  return converterElement.textContent;
};

export const snakeToCamel = (string) => string.replace(/([-_]\w)/g, (match) => match[1].toUpperCase());

export const titlize = (string) => string.replace(/(?:^|\s)\S/g, (match) => match.toUpperCase());

export const tranformKeyForApi = (string) => {
  switch (string) {
    case 'modTiles':
      return 'mod-tiles';
    case 'warnOrient':
      return 'warn-orient';
    default:
      return string;
  }
};

/* eslint-disable no-template-curly-in-string */
export const replaceEventTokens = (body, startDateTime, endDateTime, logoutDateTime) => (
  body
    .replace('${logoutDateTime}', logoutDateTime || '')
    .replace('${startDateTime}', startDateTime || '')
    .replace('${endDateTime}', endDateTime || '')
);
/* eslint-enable no-template-curly-in-string */
