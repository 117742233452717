import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { Link } from 'react-router-dom';
import Icon from './Icon';
import './LessonListItem.scss';

const LessonListItem = ({ data, setShowLessonPrompt }) => {
  const lessonKey = data.get('page_set_key');
  const lessonTitle = data.get('title');
  const work = data.get('work') || Map();
  const statusCode = work.get('status');

  let status;
  let statusIcon;

  switch (statusCode) {
    case 1:
      status = 'In progress';
      statusIcon = 'progress';
      break;
    case 2:
      status = 'Completed';
      statusIcon = 'check';
      break;
    default:
      status = 'Not started';
      break;
  }

  return (
    <tr className="lesson">
      <td className="lessonTitle">
        <div className="icon">
          <Icon text="" />
        </div>
        <h3><Link to={`./lesson/${lessonKey}/`}>{lessonTitle}</Link></h3>
      </td>
      <td className="status">
        {status === 'Not started' ? '-' : (
          <Icon text={statusIcon} />
        )}
      </td>
      <td />
      <td className="reset">
        {
          statusCode // if the status is started or complete, show reset button
            ? (
              <button type="button" className="icon" onClick={() => setShowLessonPrompt(lessonKey)}>
                <Icon text="reset" />
                <span className="tooltip right">
                  Reset lesson
                </span>
              </button>
            )
            : null
        }
      </td>
    </tr>
  );
};

LessonListItem.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired,
  setShowLessonPrompt: PropTypes.func.isRequired,
};

export default LessonListItem;
