import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { OrderedMap } from 'immutable';
import { getKeyboardingResults } from '../redux/Data/selectors';
import { requestKeyboardingResults } from '../redux/Data/actions';
import KeyboardGameResults from '../components/KeyboardGameResults';
import { getSiteToken } from '../redux/Authentication/selectors';
import connectWithRouterMatch from '../redux/connectWithRouterMatch';

const Keyboarding = ({ requestResults, results, isAuthenticated }) => {
  // effect runs once, same as componentDidMount
  useEffect(() => {
    if (isAuthenticated) {
      requestResults();
    }
  }, [isAuthenticated]);

  return <KeyboardGameResults results={results} />;
};

Keyboarding.propTypes = {
  requestResults: PropTypes.func.isRequired,
  results: PropTypes.instanceOf(OrderedMap).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, { match: { params: { courseId, siteId } } }) => ({
  results: getKeyboardingResults(state, siteId, courseId),
  isAuthenticated: !!getSiteToken(state, siteId),
});

const mapDispatchToProps = (dispatch, { match: { params: { courseId, siteId } } }) => ({
  requestResults: () => dispatch(requestKeyboardingResults(siteId, courseId)),
});

export default connectWithRouterMatch(mapStateToProps, mapDispatchToProps)(Keyboarding);
