import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import { getCourses } from '../redux/Data/selectors';
import CourseList from '../components/CourseList';

import './Course.scss';
import { isRequestActive } from '../redux/View/selectors';
import LoadingSection from '../components/LoadingSection';

const StudentCourses = ({ courses, loading }) => (
  loading
    ? <LoadingSection />
    : <CourseList courses={courses} />
);

StudentCourses.propTypes = {
  courses: PropTypes.instanceOf(Map).isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  courses: getCourses(state),
  loading: isRequestActive(state, 'authenticate'),
});

export default connect(mapStateToProps)(StudentCourses);
