import React from 'react';
import { useSelector } from 'react-redux';
import useRouteMatch from '../hooks/useRouteMatch';
import { getModuleHeaderTitle } from '../redux/Data/selectors';
import Header from '../components/Header';
import { isCourseLoading } from '../redux/View/selectors';
import { getSiteToken } from '../redux/Authentication/selectors';
import './Module.scss';

const shouldHide = ({ params: { lessonOrQuiz, moduleOrExam } }) => (
  lessonOrQuiz === 'lesson'
  || moduleOrExam === 'challenge'
  || moduleOrExam === 'keyboard-game'
);

const isActiveAssessment = ({
  params: {
    unitOrTest,
    moduleOrExam,
    lessonOrQuiz,
    page,
  },
}) => ((
  page !== 'results'
  && unitOrTest !== 'results'
  && lessonOrQuiz !== 'results'
) && (
  moduleOrExam === 'exam'
  || lessonOrQuiz === 'quiz'
  || unitOrTest === 'test'
));

const ModuleHeader = () => {
  const match = useRouteMatch('/site/:siteId/course/:courseId/:moduleOrExam/:moduleKey/:unitOrTest?/:unitKey?/:lessonOrQuiz?/:pageSetKey?/:page?');
  const params = match?.params || {};
  const {
    courseId = '',
    moduleKey = '',
    siteId = '',
    unitKey = '',
    pageSetKey = '',
  } = params;
  const title = useSelector((state) => (
    getModuleHeaderTitle(state, siteId, courseId, moduleKey, unitKey, pageSetKey)
  ));
  const siteToken = useSelector((state) => getSiteToken(state, siteId));
  const courseLoading = useSelector(isCourseLoading);
  const isLoading = !siteToken || courseLoading;

  if (isLoading) {
    return null;
  }

  if (match) {
    if (shouldHide(match)) {
      return null;
    }

    if (isActiveAssessment(match)) {
      return <Header title={title} moduleKey={moduleKey} noControls />;
    }

    return (
      <Header title={title} moduleKey={moduleKey} />
    );
  }

  return null;
};

export default ModuleHeader;
