/* eslint-disable import/prefer-default-export */

// fallback for IE
const getUrlParameterByRegex = (search, name) => {
  const safeName = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${safeName}=([^&#]*)`);
  const results = regex.exec(search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export const getUrlParameter = (name) => {
  const { location: { search } } = window;

  if (!URLSearchParams) {
    return getUrlParameterByRegex(search, name);
  }

  const params = new URLSearchParams(search);
  return params.get(name);
};

export const getMediaUrl = (path) => (process.env.REACT_APP_MEDIA_BASE_URL + path);
