import React from 'react';
import PropTypes from 'prop-types';
import { OrderedMap } from 'immutable';
import ModuleListItem from './ModuleListItem';
import './ModuleList.scss';
import './ItemLists.scss';
import { getModuleCloseReason, getModuleIsClosed } from '../utils/pacing';

const includeOverrides = true;

const ModuleList = ({ baseUrl, modules, tileView }) => {
  const moduleListItems = modules.map((data, key) => (
    <ModuleListItem
      /* eslint-disable-next-line react/no-array-index-key */
      key={`module_${key}`}
      moduleKey={key}
      baseUrl={baseUrl}
      closeReason={getModuleCloseReason(data)}
      data={data}
      isClosed={getModuleIsClosed(data, includeOverrides)}
      rowFormat={!tileView}
    />
  )).toList();
  return (
    <>
      { tileView
        ? (
          <div className="modules">
            { moduleListItems }
          </div>
        )
        : (
          <div className="itemContainer moduleList">
            <table className="itemContainer moduleList">
              <thead>
                <tr className="header">
                  <th><h3>Modules</h3></th>
                  <th><h3>Completed</h3></th>
                  <th><h3>Due Date</h3></th>
                  <th><h3>In Progress Grade</h3></th>
                  <th><h3>Final Grade</h3></th>
                </tr>
              </thead>
              <tbody>
                { moduleListItems }
              </tbody>
            </table>
          </div>
        )}
    </>
  );
};

ModuleList.propTypes = {
  baseUrl: PropTypes.string,
  modules: PropTypes.instanceOf(OrderedMap).isRequired,
  tileView: PropTypes.bool,
};

ModuleList.defaultProps = {
  baseUrl: '',
  tileView: true,
};

export default ModuleList;
