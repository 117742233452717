import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { acceptTeacherInvite } from '../redux/Authentication/actions';
import PasswordForm from '../components/PasswordForm';

class TeacherInvite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      passwordConfirmation: '',
    };
  }

  submit(e) {
    const { code, acceptInvite } = this.props;
    const { password, passwordConfirmation } = this.state;
    e.preventDefault();
    acceptInvite(code, password, passwordConfirmation);
  }

  render() {
    const { password, passwordConfirmation } = this.state;
    const { errors } = this.props;
    return (
      <div className="content invite">
        <PasswordForm
          instructions="You have been invited to join Applied Educational Systems. Create a new password to get started."
          password={password}
          confirmation={passwordConfirmation}
          setPassword={(p) => this.setState({ password: p })}
          setConfirmation={(c) => this.setState({ passwordConfirmation: c })}
          submit={(e) => this.submit(e)}
          errors={errors}
        />
      </div>
    );
  }
}

TeacherInvite.propTypes = {
  acceptInvite: PropTypes.func.isRequired,
  code: PropTypes.string.isRequired,
  errors: PropTypes.instanceOf(Map),
};

TeacherInvite.defaultProps = {
  errors: Map(),
};

const mapDispatchToProps = (dispatch) => ({
  acceptInvite: (code, password, confirm) => dispatch(acceptTeacherInvite(code, password, confirm)),
});

const mapStateToProps = ({ authentication }) => ({
  errors: authentication.get('inviteError'),
  code: authentication.get('teacherInvite'),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeacherInvite);
