import React from 'react';
import PropTypes from 'prop-types';
import { getMediaUrl } from '../utils/url';

import ExamImage from '../images/exam.png';
import CustomImage from '../images/custom-module.png';

const urlFor = (moduleKey) => {
  const slug = moduleKey.toLowerCase();
  if (slug.startsWith('exam')) {
    return ExamImage;
  }
  if (slug.startsWith('zt')) {
    return CustomImage;
  }
  return getMediaUrl(`/modules/${slug}/media/${slug}.png`);
};

const ModuleIcon = ({ moduleKey }) => <img src={urlFor(moduleKey)} alt="" role="presentation" />;

ModuleIcon.propTypes = { moduleKey: PropTypes.string.isRequired };

export default ModuleIcon;
