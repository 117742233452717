import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import './MessageSection.scss';

const ErrorMessage = ({ title, children }) => (
  <section className="message-section">
    <div className="message-inner">
      <Icon text="network error" size="48px" />
      <h1>{title}</h1>
      {children}
    </div>
  </section>
);

ErrorMessage.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default ErrorMessage;
