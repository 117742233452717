import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { OrderedMap } from 'immutable';
import {
  Route,
  Routes,
  Link,
  Navigate,
} from 'react-router-dom';
import {
  getSiteError,
  isStudent,
  isTileView,
  isViewAsStudent,
} from '../redux/Authentication/selectors';
import { getCourse, isKeyboardingActive } from '../redux/Data/selectors';
import ModuleList from '../components/ModuleList';
import Module from './Module';
import Unit from './Unit';
import Lesson from './Lesson';
import Assessment from './Assessment';
import CustomModule from './CustomModule';
import Challenge from './Challenge';
import ChallengePrompt from '../components/ChallengePrompt';
import {
  requestChallengeData,
  requestCourseData,
  setChallengeSkipped,
} from '../redux/Data/actions';
import { setActiveCourse, updateStudentPreferences } from '../redux/Authentication/actions';
import Icon from '../components/Icon';
import { getErrorText } from '../utils/errors';
import { isCourseLoading, isSiteLoading, isVasAlertDismissed } from '../redux/View/selectors';
import LoadingSection from '../components/LoadingSection';
import SidebarBlock from '../components/SidebarBlock';
import './Course.scss';
import Alert from '../components/Alert';
import { dismissVAS } from '../redux/View/actions';
import GettingStartedVideo from '../components/GettingStartedVideo';
import connectWithRouterMatch from '../redux/connectWithRouterMatch';

class Course extends React.Component {
  componentDidMount() {
    const { isLoggedIn } = this.props;
    if (isLoggedIn) {
      this.getCourseData(true);
    }
  }

  componentDidUpdate({ courseId: prevCourseId, isLoggedIn: prevIsLoggedIn }) {
    const {
      courseId,
      isLoggedIn,
      isLoading,
      siteError,
    } = this.props;
    const loggedInChanged = isLoggedIn !== prevIsLoggedIn;
    const courseChanged = courseId !== prevCourseId;
    if (isLoggedIn && !isLoading && !siteError) {
      if (loggedInChanged || courseChanged) {
        this.getCourseData(courseChanged);
      }
    }
  }

  getCourseData(courseChanged) {
    const { modules, dispatch } = this.props;
    if (courseChanged) {
      dispatch.setActiveCourse();
    }
    if (modules.isEmpty()) {
      dispatch.requestCourseData();
    }
  }

  changeModuleView() {
    const { dispatch, tileView } = this.props;
    const modTiles = !tileView;
    const preference = {
      key: 'mod_tiles',
      value: modTiles,
    };
    dispatch.updateStudentPreferences({ preference });
  }

  renderCourseSection() {
    const {
      courseId,
      modules,
      siteId,
      title,
      tileView,
      showChallengeBlock,
      dispatch,
      showKeyboardingBlock,
    } = this.props;
    const baseUrl = `/site/${siteId}/course/${courseId}`;

    return (
      <>
        <section className="course">
          <div className="overview">
            <h1>{title}</h1>
            <button type="button" onClick={() => this.changeModuleView()} className="icon secondary">
              <Icon text={tileView ? 'list-bulleted' : 'list-apps'} />
            </button>
          </div>
          <div className="flex">
            { modules.isEmpty()
              ? null
              : (
                <ModuleList
                  baseUrl={baseUrl}
                  modules={modules}
                  tileView={tileView}
                />
              ) }
            <div className="sidebar">
              <SidebarBlock title="Getting Started">
                <GettingStartedVideo />
              </SidebarBlock>
              { showChallengeBlock
                ? (
                  <SidebarBlock
                    title="Daily Challenge"
                  >
                    <button type="button" className="link" onClick={dispatch.restartChallenge}>
                      Start Challenge
                    </button>
                    <Link to="./challenge/results/">
                      Score Progress
                    </Link>
                  </SidebarBlock>
                )
                : null }
              { showKeyboardingBlock
                ? (
                  <SidebarBlock
                    title="Keyboard Practice"
                  >
                    <Link to="./module/BCKEYBOARD/unit/BCKEYBOARD01/lesson/U_BCKEYBOARD01_G9/">
                      Keyboarding Game
                    </Link>
                    <Link to="./keyboard-game/results/">
                      Scores & Leaderboard
                    </Link>
                  </SidebarBlock>
                )
                : null }
            </div>
          </div>
        </section>
        {this.renderChallengePrompt()}
      </>
    );
  }

  renderChallengePrompt() {
    const {
      hasChallenge,
      challengeComplete,
      challengeSkipped,
      challengeStarted,
      dispatch: { skipChallenge },
    } = this.props;

    const showChallenge = hasChallenge && !challengeComplete;

    if (showChallenge) {
      if (challengeStarted) {
        return <Navigate to="./challenge/" />;
      }
      return (
        <ChallengePrompt
          challengeSkipped={challengeSkipped}
          skipChallenge={skipChallenge}
        />
      );
    }
    return null;
  }

  render() {
    const {
      siteToken,
      siteError,
      isLoading,
      showAlert,
      dispatch: { onClickCloseAlert },
    } = this.props;

    if (isLoading) {
      return <LoadingSection />;
    }

    if (!siteToken && siteError) {
      return (
        <div className="license-error">
          <h2>{siteError}</h2>
          <p>Please contact your teacher for assistance.</p>
        </div>
      );
    }

    return (
      <Routes>
        <Route
          path="/*"
          element={(
            <>
              {showAlert
                ? (
                  <div className="container vas-alert">
                    <Alert
                      type="info"
                      className="container"
                      onClickCloseAlert={onClickCloseAlert}
                      showAlert={showAlert}
                    >
                      You are previewing the student experience as a teacher
                      {' '}
                      and some settings may not be visible.
                      {' '}
                      <a
                        href="https://help.aeseducation.com/en/articles/2483954-how-to-preview-the-student-experience"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        Learn more
                      </a>
                      .
                    </Alert>
                  </div>
                ) : null }
              <div className="course container">
                <Routes>
                  <Route index element={this.renderCourseSection()} />
                  <Route
                    path="teacher_module/:moduleKey/*"
                    element={<CustomModule />}
                  />
                  <Route
                    path="module/:moduleKey/*"
                    element={<Module />}
                  />
                  <Route
                    path="module/:moduleKey/unit/:unitKey"
                    element={<Unit />}
                  />
                </Routes>
              </div>
            </>
          )}
        />
        <Route
          path="challenge/*"
          element={<Challenge />}
        />
        <Route
          exact
          path="module/:moduleKey/unit/:unitKey/lesson/:pageSetKey"
          element={<Lesson />}
        />
        <Route
          exact
          path="module/:moduleKey/unit/:unitKey/quiz/:pageSetKey/*"
          element={<Assessment />}
        />
        <Route
          path="module/:moduleKey/test/:unitKey/*"
          element={<Assessment />}
        />
        <Route
          path="exam/:moduleKey/*"
          element={<Assessment />}
        />
      </Routes>
    );
  }
}

Course.propTypes = {
  courseId: PropTypes.string.isRequired,
  dispatch: PropTypes.object.isRequired,
  modules: PropTypes.instanceOf(OrderedMap).isRequired,
  siteId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  tileView: PropTypes.bool,
  hasChallenge: PropTypes.bool,
  challengeComplete: PropTypes.bool,
  challengeSkipped: PropTypes.bool,
  challengeStarted: PropTypes.bool,
  siteToken: PropTypes.string,
  siteError: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  showChallengeBlock: PropTypes.bool.isRequired,
  showKeyboardingBlock: PropTypes.bool.isRequired,
  showAlert: PropTypes.bool,
};

Course.defaultProps = {
  hasChallenge: false,
  challengeComplete: false,
  challengeSkipped: false,
  challengeStarted: false,
  tileView: true,
  siteToken: '',
  siteError: '',
  showAlert: false,
};

const mapStateToProps = (state, props) => {
  const {
    match: { params },
  } = props;

  const { courseId, siteId } = params;
  const siteError = getErrorText('siteToken', getSiteError(state, siteId), '');
  const courseData = getCourse(state, siteId, courseId);
  const modules = courseData.get('modules') || OrderedMap();
  const title = courseData.get('course_title') || '';
  const challenge = courseData.get('challenge');
  const hasChallenge = !!challenge;
  const challengeStarted = hasChallenge && challenge.get('questions')?.size > 0;
  const challengeSkipped = hasChallenge && challenge.get('skipped');
  const challengeComplete = hasChallenge && challenge.get('complete');
  const showChallengeBlock = hasChallenge;
  const tileView = isTileView(state);
  const isLoggedIn = isStudent(state) || isViewAsStudent(state);
  const isLoading = !isLoggedIn || isSiteLoading(state) || isCourseLoading(state);
  const showKeyboardingBlock = isKeyboardingActive(state, siteId, courseId);
  const hasClosedVASAlert = isVasAlertDismissed(state);
  const showAlert = isViewAsStudent(state) && !hasClosedVASAlert;

  return ({
    courseId,
    modules,
    siteId,
    title,
    hasChallenge,
    showChallengeBlock,
    challengeComplete,
    challengeStarted,
    challengeSkipped,
    siteError,
    tileView,
    isLoading,
    isLoggedIn,
    showKeyboardingBlock,
    showAlert,
  });
};

const mapDispatchToProps = (dispatch, { match: { params } }) => {
  const { courseId, siteId } = params;
  return ({
    dispatch: {
      requestCourseData: () => dispatch(requestCourseData(siteId, courseId)),
      setActiveCourse: () => dispatch(setActiveCourse(siteId, courseId)),
      updateStudentPreferences: (data) => dispatch(updateStudentPreferences(data)),
      restartChallenge: () => dispatch(requestChallengeData(siteId, courseId, true)),
      skipChallenge: () => dispatch(setChallengeSkipped(siteId, courseId)),
      onClickCloseAlert: () => dispatch(dismissVAS()),
    },
  });
};

export default connectWithRouterMatch(mapStateToProps, mapDispatchToProps)(Course);
