import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
import { Map } from 'immutable';
import { getSiteToken } from '../Authentication/selectors';
import { getChallengeData as selectChallengeData } from '../Data/selectors';
import { getChallengeData, getChallengeResults, setChallengeAttempt } from '../../services/API';
import {
  DATA_CHALLENGE_ANSWER_REQUEST,
  DATA_CHALLENGE_REQUEST,
  DATA_CHALLENGE_RESULTS_REQUEST,
  requestChallengeDataFailed,
  requestChallengeDataSucceeded,
  requestChallengeResultsSucceeded,
  setChallengeAnswerFailed,
} from '../Data/actions';
import { redirectTo } from '../View/actions';

const processChallengeData = (challengeData, reset = false) => {
  const questionCount = challengeData.get('daily_questions');
  const questions = challengeData.get('questions');
  const score = challengeData.get('score');
  const possible = challengeData.get('possible');
  const complete = questions.size >= questionCount;
  // if this is a reset, we set the skipped prop to true, so that the nag prompt doesn't display
  const skipped = reset;

  return Map({
    complete,
    possible,
    score,
    questions,
    questionCount,
    skipped,
  });
};

export function* doRequestChallengeData({ siteId, courseId, reset }) {
  const siteToken = yield select(getSiteToken, siteId);
  try {
    const result = yield call(getChallengeData, courseId, reset, siteToken);
    const error = result.get('error');
    if (error) {
      yield put(requestChallengeDataFailed(siteId, courseId, error, result.get('code')));
    } else {
      const challengeData = result.get('challenge_data');
      const data = processChallengeData(challengeData, reset)
        .set('learnosityRequest', result.get('learnosity_request'));

      yield put(requestChallengeDataSucceeded(siteId, courseId, data));
      if (reset) {
        yield put(redirectTo(`/site/${siteId}/course/${courseId}/challenge/`));
      }
    }
  } catch (error) {
    yield put(requestChallengeDataFailed(siteId, courseId, error));
  }
}

export function* doRequestChallengeResults({ siteId, courseId }) {
  const siteToken = yield select(getSiteToken, siteId);
  if (siteToken) {
    try {
      const result = yield call(getChallengeResults, courseId, siteToken);
      const error = result.get('error');
      if (error) {
        yield put(requestChallengeDataFailed(siteId, courseId, error, result.get('code')));
      } else {
        yield put(requestChallengeResultsSucceeded(siteId, courseId, result));
      }
    } catch (error) {
      yield put(requestChallengeDataFailed(siteId, courseId, error));
    }
  }
}

export function* doSetChallengeAttempt({
  siteId, courseId, itemKey, correct, attempts,
}) {
  const siteToken = yield select(getSiteToken, siteId);
  try {
    const attempt = { correct, attempts };
    const result = yield call(setChallengeAttempt, courseId, itemKey, attempt, siteToken);
    const error = result.get('error');

    if (error) {
      yield put(setChallengeAnswerFailed(siteId, courseId, error, result.get('code')));
    } else {
      const game = result.get('game');
      const challengeData = yield select(selectChallengeData, siteId, courseId);
      const newChallengeData = processChallengeData(game);

      yield put(
        requestChallengeDataSucceeded(siteId, courseId, challengeData.merge(newChallengeData)),
      );
    }
  } catch (error) {
    yield put(setChallengeAnswerFailed(siteId, courseId, error));
  }
}

export function* watchChallengeRequest() {
  yield takeLatest(DATA_CHALLENGE_REQUEST, doRequestChallengeData);
}

export function* watchChallengeAnswerRequest() {
  yield takeLatest(DATA_CHALLENGE_ANSWER_REQUEST, doSetChallengeAttempt);
}

export function* watchChallengeResultsRequest() {
  yield takeLatest(DATA_CHALLENGE_RESULTS_REQUEST, doRequestChallengeResults);
}
