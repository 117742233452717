import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
import {
  DATA_KEYBOARDING_RESULTS_REQUEST,
  requestKeyboardingResultsSucceeded,
  requestKeyboardingResultsFailed,
} from '../Data/actions';
import { getSiteToken } from '../Authentication/selectors';
import { getKeyboardingResults } from '../../services/API';

export function* doRequestKeyboardingResults({
  siteId,
  courseId,
}) {
  const siteToken = yield select(getSiteToken, siteId);
  try {
    const response = yield call(getKeyboardingResults, courseId, siteToken);
    const error = response.get('error');
    if (error) {
      yield put(requestKeyboardingResultsFailed(courseId, error, response.get('code')));
    } else {
      const results = response;
      const myScores = response.get('my_scores');
      const courseLeaders = response.get('course_leaders');
      const globalLeaders = response.get('global_leaders');
      yield put(requestKeyboardingResultsSucceeded(
        siteId,
        courseId,
        results,
        myScores,
        courseLeaders,
        globalLeaders,
      ));
    }
  } catch (error) {
    yield put(requestKeyboardingResultsFailed(courseId, error));
  }
}

export function* watchKeyboardingResultsRequest() {
  yield takeLatest(DATA_KEYBOARDING_RESULTS_REQUEST, doRequestKeyboardingResults);
}
