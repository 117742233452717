import { all, put, takeLatest } from 'redux-saga/effects';
import { setRequestActive, setRequestFinished } from '../View/actions';
import {
  AUTHENTICATION_AUTHENTICATE_ERROR,
  AUTHENTICATION_AUTHENTICATE_REQUEST,
  AUTHENTICATION_AUTHENTICATE_SUCCESS, AUTHENTICATION_ENROLL_ERROR,
  AUTHENTICATION_ENROLL_STUDENT,
  AUTHENTICATION_ENROLL_STUDENT_LOGIN, AUTHENTICATION_ENROLL_SUCCESS,
  AUTHENTICATION_SITE_DATA_ERROR,
  AUTHENTICATION_SITE_DATA_REQUEST,
  AUTHENTICATION_SITE_DATA_SUCCESS, AUTHENTICATION_VERIFY_EMAIL_ERROR,
  AUTHENTICATION_VERIFY_EMAIL_REQUEST,
  AUTHENTICATION_VERIFY_EMAIL_SUCCESS,
  AUTHENTICATION_VERIFY_ENROLLMENT_ERROR,
  AUTHENTICATION_VERIFY_ENROLLMENT_REQUEST,
  AUTHENTICATION_VERIFY_ENROLLMENT_SUCCESS,
} from '../Authentication/actions';
import {
  DATA_ASSESSMENT_ERROR,
  DATA_ASSESSMENT_REQUEST,
  DATA_ASSESSMENT_RESULTS_ERROR,
  DATA_ASSESSMENT_RESULTS_REQUEST,
  DATA_ASSESSMENT_RESULTS_SUCCESS,
  DATA_ASSESSMENT_SUCCESS,
  DATA_COURSE_ERROR,
  DATA_COURSE_REQUEST,
  DATA_COURSE_SUCCESS,
  DATA_LESSON_ERROR,
  DATA_LESSON_REQUEST,
  DATA_LESSON_SUCCESS,
} from '../Data/actions';

function* doSetRequestActive(key) {
  yield put(setRequestActive(key));
}

function* doSetRequestFinished(key) {
  yield put(setRequestFinished(key));
}

export function* watchRequestStarts() {
  yield all([
    takeLatest(AUTHENTICATION_AUTHENTICATE_REQUEST, doSetRequestActive, 'authenticate'),
    takeLatest(AUTHENTICATION_SITE_DATA_REQUEST, doSetRequestActive, 'site'),
    takeLatest(DATA_COURSE_REQUEST, doSetRequestActive, 'course'),
    takeLatest(DATA_LESSON_REQUEST, doSetRequestActive, 'lesson'),
    takeLatest(
      [DATA_ASSESSMENT_REQUEST, DATA_ASSESSMENT_RESULTS_REQUEST],
      doSetRequestActive,
      'assessment',
    ),
  ]);
}

export function* watchRequestEnds() {
  yield all([
    takeLatest(
      [AUTHENTICATION_AUTHENTICATE_SUCCESS, AUTHENTICATION_AUTHENTICATE_ERROR],
      doSetRequestFinished,
      'authenticate',
    ),
    takeLatest(
      [AUTHENTICATION_SITE_DATA_SUCCESS, AUTHENTICATION_SITE_DATA_ERROR],
      doSetRequestFinished,
      'site',
    ),
    takeLatest(
      [
        AUTHENTICATION_VERIFY_ENROLLMENT_REQUEST,
        AUTHENTICATION_VERIFY_EMAIL_REQUEST,
        AUTHENTICATION_ENROLL_STUDENT,
        AUTHENTICATION_ENROLL_STUDENT_LOGIN,
      ],
      doSetRequestActive,
      'enrollment',
    ),
    takeLatest(
      [
        AUTHENTICATION_VERIFY_ENROLLMENT_SUCCESS,
        AUTHENTICATION_VERIFY_ENROLLMENT_ERROR,
        AUTHENTICATION_VERIFY_EMAIL_SUCCESS,
        AUTHENTICATION_VERIFY_EMAIL_ERROR,
        AUTHENTICATION_ENROLL_SUCCESS,
        AUTHENTICATION_ENROLL_ERROR,
      ],
      doSetRequestFinished,
      'enrollment',
    ),
    takeLatest([
      DATA_COURSE_SUCCESS,
      DATA_COURSE_ERROR,
      AUTHENTICATION_SITE_DATA_ERROR,
    ], doSetRequestFinished, 'course'),
    takeLatest([DATA_LESSON_SUCCESS, DATA_LESSON_ERROR], doSetRequestFinished, 'lesson'),
    takeLatest(
      [
        DATA_ASSESSMENT_SUCCESS,
        DATA_ASSESSMENT_ERROR,
        DATA_ASSESSMENT_RESULTS_SUCCESS,
        DATA_ASSESSMENT_RESULTS_ERROR,
      ],
      doSetRequestFinished,
      'assessment',
    ),
  ]);
}
