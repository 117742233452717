import React, { useCallback, useState } from 'react';
import { fromJS } from 'immutable';
import Icon from './Icon';
import './Patterns.scss';
import './ItemLists.scss';
import Prompt from './Prompt';
import Menu from './Menu';
import UnitList from './UnitList';
import TestList from './TestList';
import ModuleList from './ModuleList';
import LessonList from './LessonList';

const units = fromJS({
  test: {
    unitKey: 'test',
    title: 'test unit',
    type: '',
    status: 'Open',
    reason: '',
    work: {
      quiz_grade: 0.35,
      status: 1,
    },
  },
  test2: {
    unitKey: 'test2',
    title: 'test unit 2',
    type: '',
    open: false,
    status: 'Closed',
    reason: 'Closed by Instructor',
    work: {
      quiz_grade: 0.35,
      status: 2,
    },
  },
});

const tests = fromJS({
  test: {
    unitKey: 'test',
    title: 'Unit Test',
    type: '',
    status: 'Open',
    reason: '',
    work: {
      quiz_grade: 0.35,
      status: 1,
    },
  },
  test2: {
    unitKey: 'test2',
    title: 'Unit test 2',
    type: '',
    open: false,
    status: 'Closed',
    reason: 'Closed by Instructor',
    work: {
      quiz_grade: 0.23,
      status: 2,
    },
  },
  test3: {
    unitKey: 'test2',
    title: 'test unit 2',
    type: '',
    open: false,
    status: 'Blocked',
    reason: 'Incomplete Units',
    work: {
      quiz_grade: 0,
      status: 3,
    },
  },
});

const modules = fromJS({
  test: {
    completed_count: 0,
    completed_max: 1,
    due: null,
    grade: null,
    id: 13039,
    module_key: 'BCACECLASS',
    open: false,
    points_earned: null,
    points_possible: null,
    reason: 'Normal',
    sequence: 5,
    status: 'Open',
    teacher_module_resources: null,
    title: 'Class 1',
    total_possible: null,
  },
  test2: {
    completed_count: 0,
    completed_max: 1,
    due: null,
    grade: null,
    id: 13039,
    module_key: 'BCACECLASS',
    open: false,
    points_earned: null,
    points_possible: null,
    reason: 'Closed by Instructor',
    sequence: 5,
    status: 'Closed',
    teacher_module_resources: null,
    title: 'Class 2',
    total_possible: null,
  },
  test3: {
    completed_count: 0,
    completed_max: 1,
    due: '2020-10-19',
    grade: null,
    id: 13039,
    module_key: 'BCACECLASS',
    open: false,
    points_earned: null,
    points_possible: null,
    reason: 'Due Date Passed',
    sequence: 5,
    status: 'Closed',
    teacher_module_resources: null,
    title: 'Class 2',
    total_possible: null,
  },
  test4: {
    completed_count: 0,
    completed_max: 1,
    due: null,
    grade: null,
    id: 13039,
    module_key: 'BCACECLASS',
    open: false,
    points_earned: null,
    points_possible: null,
    reason: 'Previous module incomplete',
    sequence: 5,
    status: 'Blocked',
    teacher_module_resources: null,
    title: 'Class 2',
    total_possible: null,
  },
});

const lessons = fromJS({
  test2: {
    assignment_entry_id: 106466,
    id: 106466,
    quiz_reason: 'Lessons incomplete',
    quiz_status: 'Closed',
    reason: 'Normal',
    sequence: 0,
    status: 'Open',
    title: 'How to Ace this Class',
    type: 'unit',
    unit_key: 'BCACECLASS1',
    work: {
      id: 203196,
      quiz_grade: null,
      status: 0,
      unit_key: 'BCACECLASS1',
    },
  },
});

const Patterns = () => {
  const [modal, setModal] = useState(false);
  const handleModal = useCallback(() => setModal(!modal));

  return (
    <div className="patterns container">
      <Menu />
      <button type="button" className="icon">
        <Icon text="close" />
        <span className="tooltip">Close</span>
      </button>
      <button type="button" className="icon completed">
        <Icon text="check" />
        <span className="tooltip">Completed</span>
      </button>
      <button type="button" disabled className="icon">
        <Icon text="check" />
        <span className="tooltip">Completed</span>
      </button>
      <button type="button" className="icon">
        <Icon text="worksheet" />
        <span className="tooltip">WorkSheet</span>
      </button>
      <button type="button" className="icon" disabled>
        <Icon text="worksheet" />
        <span className="tooltip">WorkSheet</span>
      </button>
      <button type="button" className="icon bg">
        <Icon text="list-bulleted" />
        <span className="tooltip">List</span>
      </button>
      <button type="button" className="icon bg">
        <Icon text="list-apps" />
        <span className="tooltip">List</span>
      </button>
      <button type="button" className="icon">
        <Icon text="back" />
        <span className="tooltip">Back</span>
      </button>
      <button type="button" className="icon">
        <Icon text="reset" />
        <span className="tooltip">Reload</span>
      </button>
      <button type="button" className="icon secondary">
        <Icon text="previous" />
        <span className="tooltip">Previous Question</span>
      </button>
      <button type="button" className="icon secondary">
        <Icon text="next" />
        <span className="tooltip">Next Question</span>
      </button>
      <button type="button" className="icon primary">
        <Icon text="next" />
        <span className="tooltip">Start</span>
      </button>
      <button type="button" disabled className="icon quiz">
        <Icon text="previous" />
        <span className="tooltip">Previous Question</span>
      </button>
      <button type="button" disabled className="icon quiz">
        <Icon text="next" />
        <span className="tooltip">Next Question</span>
      </button>
      {modal && (
        <Prompt
          title="Heads Up"
          onClickClose={handleModal}
          primaryBtnText="Yes"
          primaryBtnCB={handleModal}
          secondaryBtnText="Cancel"
          secondaryBtnCB={handleModal}
        >
          <p>Are you sure that you want to hand in the quiz?</p>
        </Prompt>
      )}
      <button type="button" className="default submit" onClick={handleModal}>
        Show Modal
      </button>
      <button type="button" className="default" onClick={handleModal}>
        Show Modal
      </button>
      <button type="button">
        Reload the lesson
        <Icon text="reset" />
        <span className="tooltip">Reload</span>
      </button>
      <p className="tip">CLOSED BY TEACHER</p>
      <p className="tip wine">DUE DATE REACHED</p>
      <p className="tip green">COMPLETED</p>
      <ModuleList modules={modules} baseUrl="/" type="tile" />
      <ModuleList modules={modules} baseUrl="/" type="condensed" />
      <UnitList units={units} baseUrl="/" />
      <TestList tests={tests} baseUrl="/" />
      <LessonList lessons={lessons} baseUrl="/" />
      <LessonList lessons={lessons} baseUrl="/" quizCloseReason="Lessons incomplete" quizIsClosed />
    </div>
  );
};

export default Patterns;
