import React from 'react';
import PropTypes from 'prop-types';
import { OrderedMap } from 'immutable';
import UnitListItem from './UnitListItem';
import './ItemLists.scss';
import { getUnitCloseReason, getUnitIsClosed } from '../utils/pacing';

const TestList = ({
  baseUrl,
  tests,
  moduleIsClosed,
}) => (
  <div className="itemContainer tests">
    <table className="tests">
      <thead>
        <tr className="header">
          <th><h3>Assessments</h3></th>
          <th><h3>Progress</h3></th>
          <th><h3>Grade</h3></th>
        </tr>
      </thead>
      <tbody>
        {tests.map((data, key) => {
          const status = data.getIn(['work', 'status']);
          const isComplete = status > 1;
          const isClosed = !isComplete && getUnitIsClosed(data, moduleIsClosed);
          const closeReason = getUnitCloseReason(data);
          return (
            <UnitListItem
                /* eslint-disable-next-line react/no-array-index-key */
              key={`module_${key}`}
              baseUrl={baseUrl}
              closeReason={closeReason}
              data={data}
              isClosed={isClosed}
            />
          );
        }).toList()}
      </tbody>
    </table>
  </div>
);

TestList.propTypes = {
  baseUrl: PropTypes.string,
  tests: PropTypes.instanceOf(OrderedMap).isRequired,
  moduleIsClosed: PropTypes.bool.isRequired,
};

TestList.defaultProps = {
  baseUrl: '',
};

export default TestList;
