import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { titlize } from '../utils/formatting';
import Alert from './Alert';
import Input from './Input';
import AESLogo from './AESLogo';
import GoogleSignIn from './GoogleSignIn';

const adjustUsernameError = (error, userRole) => (
  userRole === 'teacher'
    ? error.replace(' or username', '')
    : error
);

const SignInForm = ({
  email,
  password,
  message,
  setEmail,
  setPassword,
  signInGoogle,
  signIn,
  error,
  userRole,
}) => (
  <>
    { message
      ? <Alert message={message} />
      : null}
    <AESLogo />
    <div className="sign-in-form">
      <form onSubmit={(event) => signIn(event)}>
        <>
          <h1>{titlize(`${userRole} Sign In`)}</h1>
        </>
        <Input
          id="username"
          name="username"
          autoComplete="username"
          placeholder={userRole === 'student' ? 'Email or Username' : 'Email'}
          value={email}
          error={error.field === 'email' ? adjustUsernameError(error.message, userRole) : ''}
          onChange={({ target: { value } }) => setEmail(value)}
        />
        <Input
          id="password"
          type="password"
          name="password"
          autoComplete="password"
          placeholder="Password"
          value={password}
          error={error.field === 'password' ? error.message : ''}
          onChange={({ target: { value } }) => setPassword(value)}
        />
        <button id="sign-in" className="primary" type="submit">Sign In</button>
        <p className="center">
          <Link to={`/${userRole}/help`}>
            Forgot Password?
          </Link>
        </p>
        <div className="separator">OR</div>
        <GoogleSignIn signInGoogle={signInGoogle} />
        { error.field === 'sso'
          ? <Alert id="error_sso" type="error" message={error.message} />
          : null}
        {userRole === 'student'
          ? (
            <>
              <div className="breakLine" />
              <h2>Have an Enrollment Code?</h2>
              <Link id="create-account" to="/enroll" className="button secondary">
                Enroll into a Class
              </Link>
            </>
          )
          : null}
      </form>
    </div>
  </>
);

SignInForm.propTypes = {
  email: PropTypes.string,
  password: PropTypes.string,
  message: PropTypes.string,
  setEmail: PropTypes.func.isRequired,
  setPassword: PropTypes.func.isRequired,
  signIn: PropTypes.func.isRequired,
  signInGoogle: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
  error: PropTypes.shape({ field: PropTypes.string, message: PropTypes.string }),
};

SignInForm.defaultProps = {
  email: '',
  password: '',
  message: '',
  error: {},
};

export default SignInForm;
