import React from 'react';
import { ReactComponent as Teacher } from '../images/teacher.svg';
import './TeacherAvatar.scss';

const getNum = (size = 3) => Math.floor(Math.random() * size);

const getClasses = () => `teacher-avatar style-${getNum(5) + 1} hair-${getNum()} face-${getNum()} shirt-${getNum()}`;

const TeacherAvatar = () => <Teacher className={getClasses()} />;

export default TeacherAvatar;
