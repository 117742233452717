import { Map } from 'immutable';
import {
  VIEW_SET_ASSESSMENT_META,
  VIEW_SET_ALL_ANSWERS,
  VIEW_SET_ASSESSMENT_COMPLETE,
  VIEW_SET_REDIRECT,
  VIEW_CLEAR,
  VIEW_SET_REQUEST_ACTIVE,
  VIEW_SET_REQUEST_FINISHED,
  VIEW_VAS_DISMISS,
  VIEW_EVENTS_DISMISS,
  VIEW_MIGRATION_COURSES_SELECTED,
  VIEW_MIGRATION_COMPLETED,
  VIEW_MIGRATION_ERROR,
  VIEW_SET_MIGRATION_REPORT,
} from './actions';

const initialState = Map({
  activeAssessmentMetadata: Map(),
  answers: Map(),
  activeRequests: Map(),
  migrationCompleted: false,
  migrationError: '',
  migrationReport: Map(),
});

const viewReducer = (state = initialState, action) => {
  switch (action.type) {
    case VIEW_SET_ASSESSMENT_COMPLETE:
      return state.setIn(['activeAssessmentMetadata', 'isComplete'], true);
    case VIEW_SET_ASSESSMENT_META:
      return state.set('activeAssessmentMetadata', Map(action.assessmentMetadata));
    case VIEW_SET_ALL_ANSWERS:
      return state.set('answers', action.answers);
    case VIEW_SET_REDIRECT:
      return state.set('redirect', action.path);
    case VIEW_SET_REQUEST_ACTIVE:
      return state.setIn(['activeRequests', action.key], true);
    case VIEW_SET_REQUEST_FINISHED:
      return state.setIn(['activeRequests', action.key], false);
    case VIEW_VAS_DISMISS:
      return state.set('vasAlertDismissed', true);
    case VIEW_EVENTS_DISMISS:
      return state.set('eventBannerDismissed', true);
    case VIEW_MIGRATION_COURSES_SELECTED:
      return state.set('migrationCoursesSelected', action.selectedIds);
    case VIEW_MIGRATION_COMPLETED:
      return state.set('migrationCompleted', true);
    case VIEW_MIGRATION_ERROR:
      return state.set('migrationError', action.error);
    case VIEW_SET_MIGRATION_REPORT:
      return state.set('migrationReport', action.errorReport);
    case VIEW_CLEAR:
      return initialState;
    default:
      return state;
  }
};

export default viewReducer;
