import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import '../containers/SignIn.scss';
import { formatAssessmentDate } from '../utils/date';

const SiteList = ({ sites, dispatchTeacherLogin }) => (
  <div className="site-list">
    <h1 className="header-title">My Sites</h1>
    {sites.map((siteData) => {
      const siteId = siteData.get('id');
      const name = siteData.get('name');
      const lastLogon = formatAssessmentDate(siteData.get('lastLogon'));
      const product = siteData.get('product');
      const trial = siteData.get('trial');

      return (
        <article key={`site_${siteId}`} aria-hidden="true" onClick={() => dispatchTeacherLogin(siteId)}>
          <div className="header">
            <h2 className="name">{name}</h2>
            {trial ? <span className="tip">Trial</span> : null}
          </div>
          <div className="content">
            <div className="title">
              <p>Product</p>
              <h3>{product}</h3>
            </div>
            <div className="logon">
              <p>Last Sign In</p>
              <h3>{lastLogon}</h3>
            </div>
          </div>
        </article>
      );
    }).toList()}
  </div>
);

SiteList.propTypes = {
  dispatchTeacherLogin: PropTypes.func.isRequired,
  sites: PropTypes.instanceOf(Map).isRequired,
};

export default SiteList;
