import React from 'react';
import { Link } from 'react-router-dom';

const StudentHelp = () => (
  <div className="sign-in-form recover-password">
    <h1>Forgot your Password?</h1>
    <h2>
      Don’t try to create a new account!
    </h2>
    <h2>
      Reach out to your teacher so they can reset your password.
    </h2>
    <div className="content-box">
      <h3>What happens next?</h3>
      <p>Your teacher will supply you with a new password.</p>
      <p>
        Use the new password when you
        {' '}
        <Link to="/student">sign in</Link>
      </p>
    </div>
    <Link className="link" to="/student">Got a new password? Sign in</Link>
  </div>
);

export default StudentHelp;
