import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SignIn from './SignIn';
import SignInRedirect from './SignInRedirect';

const Teacher = ({ authToken }) => (
  authToken
    ? <SignInRedirect />
    : <SignIn userRole="teacher" />
);

Teacher.propTypes = { authToken: PropTypes.string };
Teacher.defaultProps = { authToken: '' };

const mapStateToProps = ({ authentication }) => ({ authToken: authentication.get('authToken') });

export default connect(mapStateToProps)(Teacher);
