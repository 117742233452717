import React from 'react';
import { fromJS } from 'immutable';
import ChallengeComplete from './ChallengeComplete';

const challengeData = fromJS({
  score: 8,
});

const ChallengeResultsPattern = () => (
  <div className="quiz-challenge">
    <ChallengeComplete challengeData={challengeData} />
  </div>
);

export default ChallengeResultsPattern;
