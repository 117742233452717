import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import './SidebarBlock.scss';

const SidebarBlock = ({ children, title, onClickClose }) => (
  <div className="sidebar-block">
    <div className="header">
      <h3>{title}</h3>
      { onClickClose
        ? (
          <button
            type="button"
            className="icon close"
            onClick={onClickClose}
          >
            <Icon text="close" />
          </button>
        )
        : null }
    </div>
    <div className="content">
      { children }
    </div>
  </div>
);

SidebarBlock.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  onClickClose: PropTypes.func,
};

SidebarBlock.defaultProps = { onClickClose: null };

export default SidebarBlock;
