import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import './ChallengeResults.scss';

const returnAvg = (average) => {
  const rounded = Math.round(average);
  return `${rounded}%`;
};

const getScore = (earned, total) => {
  if (!(earned >= 0 || total >= 0)) { return null; }

  return `${earned / 10}/${total / 10}`;
};

const getAverage = (earned, total) => {
  if (earned === 0) { return '0%'; }
  if (!earned || !total) { return ''; }

  const average = Math.round((earned / total) * 100);

  return `${average}%`;
};

const CompetencyRows = ({
  moduleName,
  rowData,
  scoreData,
  isFirst,
}) => {
  const title = rowData?.get('unit_title');
  const course = rowData?.get('course') === null ? '-' : returnAvg(rowData?.get('course'));
  const national = rowData?.get('national') === null ? '-' : returnAvg(rowData?.get('national'));

  const earned = scoreData?.get('earned_points') === null ? '-' : scoreData?.get('earned_points');
  const available = scoreData?.get('avaiable_points') === null ? '-' : scoreData?.get('avaiable_points');

  const score = getScore(earned, available);
  const avg = getAverage(earned, available);

  return (
    <tr>
      <td className="moduleTitle">
        {isFirst
          ? moduleName
          : null }
      </td>
      <td className="unitTitle">{title}</td>
      <td className="data score">{score}</td>
      <td className="data avg">{avg}</td>
      <td className="data course">{course}</td>
      <td className="data national">{national}</td>
    </tr>
  );
};

CompetencyRows.propTypes = {
  moduleName: PropTypes.string.isRequired,
  rowData: PropTypes.instanceOf(Map),
  scoreData: PropTypes.instanceOf(Map),
  isFirst: PropTypes.bool.isRequired,
};

CompetencyRows.defaultProps = {
  rowData: Map(),
  scoreData: Map(),
};

export default CompetencyRows;
