import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import { getSites } from '../redux/Data/selectors';
import {
  authenticateTeacherSite,
} from '../redux/Authentication/actions';
import { isRequestActive } from '../redux/View/selectors';
import LoadingSection from '../components/LoadingSection';
import SiteList from '../components/SiteList';
import './SignIn.scss';

const TeacherSites = ({
  hasExpired,
  loading,
  sites,
  dispatchTeacherLogin,
}) => (
  loading
    ? <LoadingSection />
    : (
      <section className="site-container">
        { sites.size > 0
          ? <SiteList sites={sites} dispatchTeacherLogin={dispatchTeacherLogin} />
          : (
            <div className="license-error">
              <h2>{hasExpired ? 'Your site has expired.' : 'Your account has been disabled'}</h2>
              <p>Please contact customersupport@icevonline.com for assistance.</p>
            </div>
          )}
      </section>
    )
);

TeacherSites.propTypes = {
  dispatchTeacherLogin: PropTypes.func.isRequired,
  hasExpired: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  sites: PropTypes.instanceOf(Map).isRequired,
};

const mapStateToProps = (state) => ({
  sites: getSites(state) || Map(),
  hasExpired: state.data.has('expiredRoles'),
  loading: isRequestActive(state, 'authenticate'),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchTeacherLogin: (siteId) => dispatch(authenticateTeacherSite(siteId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeacherSites);
