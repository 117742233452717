import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import { Link } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import {
  Chart,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js';
import 'chartjs-adapter-date-fns'; // NB: this import must come AFTER chart.js to function
import { parseDueDate } from '../utils/date';
import Icon from './Icon';
import LoadingSection from './LoadingSection';
import { getCompetencyData } from '../redux/Data/selectors';
import './ChallengeResults.scss';
import CompetencyTable from './CompetencyTable';

Chart.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
);

const chartOptions = {
  responsive: true,
  plugins: {
    tooltip: {
      backgroundColor: '#FFFFFF',
      bodyColor: '#000000',
      borderColor: '#D9D9D9',
      borderWidth: 1,
      cornerRadius: 2,
      displayColors: false,
      titleColor: '#000000',
    },
  },
  scales: {
    x: {
      type: 'time',
      time: {
        unit: 'month',
        tooltipFormat: "'Week ending' MMMM dd, yyyy",
      },
    },
    y: {
      title: {
        display: true,
        text: 'Average Score',
      },
    },
  },
};

const formatChartData = (data = List()) => {
  const formattedData = data
    .map((item) => {
      const [dateString, score] = item;
      const date = parseDueDate(dateString);
      return { x: date, y: score.toFixed(0) };
    })
    .toJSON();
  const { length } = formattedData;
  return {
    length,
    datasets: [{
      label: 'Average Score',
      borderColor: '#146DB6',
      backgroundColor: '#146DB6',
      data: formattedData,
    }],
  };
};

const ChallengeResults = ({ data, requestResults }) => {
  const competencyData = getCompetencyData(data);
  const chartData = formatChartData(data.get('student_over_time'));

  useEffect(() => {
    if (data.isEmpty()) {
      requestResults();
    }
  }, [data]);

  return (
    <div className="container results">
      <div className="header">
        <h1>Daily Challenge</h1>
        <Link to="../../" relative="path" className="button icon">
          <Icon text="close" />
        </Link>
      </div>
      <div className="content">
        <h2>Score Progress</h2>
        { chartData.length
          ? <Line options={chartOptions} data={chartData} />
          : <LoadingSection /> }
      </div>
      {(competencyData.isEmpty()
        ? null
        : <CompetencyTable competencyData={competencyData} />
      )}
    </div>
  );
};

ChallengeResults.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired,
  requestResults: PropTypes.func.isRequired,
};

export default ChallengeResults;
