import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { Route, Routes, useNavigate } from 'react-router-dom';
import {
  requestChallengeResults,
  requestQuestionTts,
  setChallengeAttempt,
  setChallengeComplete,
} from '../redux/Data/actions';
import { getAssessmentTtsData, getChallengeData } from '../redux/Data/selectors';
import ChallengeComplete from '../components/ChallengeComplete';
import ChallengeQuestions from '../components/ChallengeQuestions';
import './Challenge.scss';
import ChallengeResults from '../components/ChallengeResults';
import connectWithRouterMatch from '../redux/connectWithRouterMatch';

const Challenge = (props) => {
  const {
    challengeData,
    complete,
    ttsData,
    onSetAnswer,
    completeChallenge,
    results,
    requestResults,
    requestTts,
  } = props;

  const navigate = useNavigate();

  const viewResults = () => {
    requestResults();
    navigate('./results/', { relative: 'path' });
  };

  return (
    <section className="quiz-challenge">
      <Routes>
        <Route
          path="results"
          element={<ChallengeResults data={results} requestResults={requestResults} />}
        />
        <Route
          index
          element={complete
            ? <ChallengeComplete challengeData={challengeData} viewResults={viewResults} />
            : (
              <ChallengeQuestions
                challengeData={challengeData}
                ttsData={ttsData}
                onSetAnswer={onSetAnswer}
                onComplete={completeChallenge}
                requestTts={requestTts}
              />
            )}
        />
      </Routes>
    </section>
  );
};

Challenge.propTypes = {
  challengeData: PropTypes.instanceOf(Map).isRequired,
  complete: PropTypes.bool,
  ttsData: PropTypes.instanceOf(Map).isRequired,
  onSetAnswer: PropTypes.func.isRequired,
  completeChallenge: PropTypes.func.isRequired,
  results: PropTypes.instanceOf(Map).isRequired,
  requestResults: PropTypes.func.isRequired,
  requestTts: PropTypes.func.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
};

Challenge.defaultProps = {
  complete: false,
};

const mapStateToProps = (state, { match: { params: { siteId, courseId } } }) => {
  const challengeData = getChallengeData(state, siteId, courseId);
  const ttsData = getAssessmentTtsData(state);
  const learnosityRequest = challengeData.get('learnosityRequest');
  const complete = challengeData.get('complete');
  const results = challengeData.get('results') || Map();
  return ({
    challengeData,
    complete,
    learnosityRequest,
    results,
    ttsData,
  });
};

const mapDispatchToProps = (dispatch, { match: { params: { siteId, courseId } } }) => ({
  onSetAnswer: (itemKey, attempts, correct) => dispatch(
    setChallengeAttempt(siteId, courseId, itemKey, attempts, correct),
  ),
  completeChallenge: () => dispatch(setChallengeComplete(siteId, courseId)),
  requestResults: () => dispatch(requestChallengeResults(siteId, courseId)),
  requestTts: (question) => dispatch(requestQuestionTts(siteId, question)),
});

export default connectWithRouterMatch(mapStateToProps, mapDispatchToProps)(Challenge);
