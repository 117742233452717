import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Icon from './Icon';
import ModuleIcon from './ModuleIcon';
import Tooltip from './Tooltip';
import './ModuleListItem.scss';

const ModuleListItemCard = ({
  baseUrl,
  closeReason,
  completed,
  due,
  dueDate,
  gradeInProgress,
  gradeFinal,
  icon,
  isClosed,
  moduleKey,
  moduleTitle,
  statusClass,
  tipColor,
  type,
}) => (
  <article className={`module ${statusClass}`}>
    {isClosed ? null : <Link to={`${baseUrl}/${type}/${moduleKey}/`}>{moduleTitle}</Link>}
    {isClosed && <Tooltip copy={closeReason} height="122px" />}
    <div className="picture">
      { isClosed
        ? <Icon text={icon} size="48px" />
        : <ModuleIcon moduleKey={moduleKey} />}
    </div>
    <div className="content">
      <h2>
        <span>
          {moduleTitle}
        </span>
        { isClosed || statusClass === 'due-passed'
          ? <span className={`tip ${tipColor}`}>{closeReason}</span>
          : null }
      </h2>
      <div className="details">
        <div className="completed">
          <span className="label">Completed</span>
          <br />
          <p>{completed}</p>
        </div>
        <div className="due-date">
          <span className="label">Due Date</span>
          <br />
          <p>{due ? dueDate : 'None'}</p>
        </div>
        { type !== 'exam'
          ? (
            <div className="in-progress">
              <span className="label">
                In-Progress Grade
              </span>
              <br />
              <p>{gradeInProgress}</p>
            </div>
          )
          : null }
        <div className="final">
          <span className="label">Final Grade</span>
          <br />
          <p>{gradeFinal}</p>
        </div>
      </div>
    </div>
  </article>
);

ModuleListItemCard.propTypes = {
  baseUrl: PropTypes.string,
  closeReason: PropTypes.string,
  completed: PropTypes.string,
  due: PropTypes.string,
  dueDate: PropTypes.string.isRequired,
  gradeInProgress: PropTypes.string.isRequired,
  gradeFinal: PropTypes.string.isRequired,
  icon: PropTypes.string,
  isClosed: PropTypes.bool,
  moduleKey: PropTypes.string.isRequired,
  moduleTitle: PropTypes.string.isRequired,
  statusClass: PropTypes.string,
  tipColor: PropTypes.string,
  type: PropTypes.string.isRequired,
};

ModuleListItemCard.defaultProps = {
  baseUrl: '.',
  closeReason: '',
  completed: '',
  due: '',
  icon: '',
  isClosed: false,
  statusClass: '',
  tipColor: '',
};

export default ModuleListItemCard;
