import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { differenceInMinutes, differenceInMilliseconds } from 'date-fns';
import { connect } from 'react-redux';
import Alert from '../components/Alert';
import { isEventBannerDismissed } from '../redux/View/selectors';
import { logout } from '../redux/Authentication/actions';
import { dismissEventBanner } from '../redux/View/actions';
import { getNextBannerEvent } from '../redux/PlatformStatus/selectors';
import { formatEventDate } from '../utils/date';
import { replaceEventTokens } from '../utils/formatting';
import { requestEventData } from '../redux/PlatformStatus/actions';

const AlertBanner = ({
  bannerBody,
  bannerDismissed,
  logoutBody,
  learnMoreUrl,
  startDate,
  endDate,
  logoutDate,
  isUnexpected,
  dispatch,
}) => {
  const eventDateFormatted = formatEventDate(startDate);
  const endDateFormatted = formatEventDate(endDate);
  const logoutDateFormatted = logoutDate ? formatEventDate(logoutDate) : '';
  const [logoutMinutesRemaining, setLogoutMinutesRemaining] = useState(720);
  const logoutMinutesRemainingFormatted = logoutMinutesRemaining > 0
    ? `${logoutMinutesRemaining}`.padStart(2, '0')
    : '00';
  const showLogout = logoutMinutesRemaining < 61 || isUnexpected;
  const hideBanner = bannerDismissed && !showLogout;

  const dismissButton = showLogout
    ? <button key="signout" type="button" className="danger" onClick={dispatch.logout}>Sign Out</button>
    : <button key="dismiss" type="button" className="primary" onClick={dispatch.dismiss}>Dismiss</button>;

  const actions = learnMoreUrl
    ? [<a key="learnMore" href={learnMoreUrl} target="_blank" rel="noopener noreferrer">Learn more</a>, dismissButton]
    : [dismissButton];

  const bodyHTML = showLogout
    ? replaceEventTokens(
      logoutBody,
      eventDateFormatted,
      endDateFormatted,
      `<span class="countdown">0:${logoutMinutesRemainingFormatted}</span>`,
    )
    : replaceEventTokens(bannerBody, eventDateFormatted, endDateFormatted, logoutDateFormatted);

  useEffect(() => {
    if (logoutMinutesRemaining < 1) {
      dispatch.logout();
    }
  }, [logoutMinutesRemaining]);

  useEffect(() => {
    if (logoutDate) {
      const updateMinutesRemaining = () => {
        setLogoutMinutesRemaining(differenceInMinutes(logoutDate, Date.now()));
      };
      const intervalId = setInterval(updateMinutesRemaining, 30000);
      // need to run once immediately to get start state;
      updateMinutesRemaining();
      return () => clearInterval(intervalId);
    }
    return () => {
      // do nothing if no logout date
    };
  }, [logoutDate]);

  useEffect(() => {
    const delta = differenceInMilliseconds(endDate, Date.now());
    if (delta > 0) {
      const timeoutId = setTimeout(dispatch.reloadEvents, delta);
      return () => clearTimeout(timeoutId);
    }
    return () => {
      // do nothing if no timeout
    };
  }, [endDate]);

  return bodyHTML && !hideBanner
    ? (
      <Alert type={showLogout ? 'banner danger' : 'banner warning'} actions={actions}>
        {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{ __html: bodyHTML }} />
      </Alert>
    )
    : null;
};

AlertBanner.propTypes = {
  bannerBody: PropTypes.string.isRequired,
  bannerDismissed: PropTypes.bool.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  logoutDate: PropTypes.instanceOf(Date),
  logoutBody: PropTypes.string.isRequired,
  learnMoreUrl: PropTypes.string.isRequired,
  isUnexpected: PropTypes.bool.isRequired,
  dispatch: PropTypes.shape({
    logout: PropTypes.func.isRequired,
    dismiss: PropTypes.func.isRequired,
    reloadEvents: PropTypes.func.isRequired,
  }).isRequired,
};

AlertBanner.defaultProps = { logoutDate: null };

const mapStateToProps = (state) => {
  const now = new Date();
  const bannerDismissed = isEventBannerDismissed(state);
  const bannerData = getNextBannerEvent(state) || Map();
  const bannerBody = bannerData.get('bannerBody', '');
  const logoutBody = bannerData.get('logoutBody', '');
  const learnMoreUrl = bannerData.get('learnMoreUrl', '');
  const startDate = bannerData.get('startDate', now);
  const endDate = bannerData.get('endDate', now);
  const logoutDate = bannerData.get('logoutDate');
  const isUnexpected = bannerData.get('isUnexpected', false);

  return ({
    bannerDismissed,
    bannerBody,
    logoutBody,
    learnMoreUrl,
    startDate,
    endDate,
    logoutDate,
    isUnexpected,
  });
};

const mapDispatchToProps = (dispatch) => ({
  dispatch: {
    logout: () => dispatch(logout()),
    dismiss: () => dispatch(dismissEventBanner()),
    reloadEvents: () => dispatch(requestEventData()),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertBanner);
