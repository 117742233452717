import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { Link } from 'react-router-dom';
import Icon from './Icon';
import { formatGrade } from '../utils/formatting';
import { getTipsColor, getClosedType } from '../utils/pacing';
import Tooltip from './Tooltip';

const UnitListItem = ({
  baseUrl,
  closeReason,
  data,
  isClosed,
  hasIndex,
}) => {
  const unitKey = data.get('unit_key');
  const unitTitle = data.get('title');
  const type = data.get('type');
  const work = data.get('work') || Map();
  const gradeString = formatGrade(work.get('quiz_grade'));
  const statusCode = work.get('status');
  const { statusClass, icon } = getClosedType(closeReason, isClosed);
  const tipColor = getTipsColor(closeReason);
  const unitIndex = hasIndex ? `${data.get('index')}. ` : null;
  let status;
  let statusIcon;

  switch (statusCode) {
    case 1:
      status = 'In progress';
      statusIcon = 'progress';
      break;
    case 2:
      status = 'Completed';
      statusIcon = 'check';
      break;
    default:
      status = 'Not started';
      break;
  }

  return (
    <>
      <tr className={`unitListItem ${statusClass}`}>
        <td className="unitTitle">
          { isClosed && <Tooltip copy={closeReason} /> }
          <div className="icon">
            <Icon text={icon} isButton tooltipText={closeReason} tooltipPosition="left" />
          </div>
          {isClosed
            ? (

              <>
                <span className="title">
                  {unitIndex || '' }
                  {unitTitle}
                </span>
                {closeReason !== 'Incomplete Units' ? <span className={`tip ${tipColor}`}>{closeReason}</span> : null}
              </>

            )
            : (
              <h3>
                {unitIndex || ''}
                <Link to={`${baseUrl}/${type}/${unitKey}/`}>{unitTitle}</Link>
              </h3>
            )}
        </td>
        <td className="status">
          {status === 'Not started' ? '-' : (
            <Icon text={statusIcon} />
          )}
        </td>
        <td className="details">
          {gradeString}
        </td>
      </tr>
    </>
  );
};

UnitListItem.propTypes = {
  baseUrl: PropTypes.string,
  closeReason: PropTypes.string,
  data: PropTypes.instanceOf(Map).isRequired,
  isClosed: PropTypes.bool,
  hasIndex: PropTypes.bool,
};

UnitListItem.defaultProps = {
  baseUrl: '.',
  closeReason: PropTypes.string,
  isClosed: false,
  hasIndex: false,
};

export default UnitListItem;
