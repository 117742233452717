import React from 'react';
import PropTypes from 'prop-types';
import GoogleSignIn from './GoogleSignIn';
import Input from './Input';

const RegisterEmailOrGoogle = ({
  email,
  onChangeValue,
  onSubmit,
  onGoogleLogin,
  error,
}) => (
  <>
    <h3 className="enrollment">Enroll with your Email or Username</h3>
    <form onSubmit={onSubmit} autoComplete="off">
      <Input
        name="email"
        placeholder="Email or Username"
        value={email}
        onChange={onChangeValue}
        error={error}
      />
      <button className="primary" type="submit">Next</button>
    </form>
    <div className="separator">OR</div>
    <GoogleSignIn signInGoogle={onGoogleLogin} />
  </>
);

RegisterEmailOrGoogle.propTypes = {
  email: PropTypes.string,
  onChangeValue: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onGoogleLogin: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
};

RegisterEmailOrGoogle.defaultProps = { email: '' };

export default RegisterEmailOrGoogle;
