import React from 'react';
import PropTypes from 'prop-types';
import './ButtonLink.scss';
import Icon from './Icon';

const renderIcon = (iconName) => {
  switch (iconName) {
    case 'paperclip':
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      return <button type="button" className="tooltipButoon"><Icon text="papperclip" /></button>;
    default:
      return null;
  }
};

const ButtonLink = ({
  href,
  icon,
  target,
  text,
}) => (
  <a href={href} target={target} className="button">
    {renderIcon(icon)}
    {text}
  </a>
);

ButtonLink.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.string,
  target: PropTypes.string,
};

ButtonLink.defaultProps = {
  icon: '',
  target: '',
};

export default ButtonLink;
