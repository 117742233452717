import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { authenticate, logout } from '../redux/Authentication/actions';
import './SignIn.scss';
import SignInForm from '../components/SignInForm';
import { findError } from '../utils/errors';

class SignIn extends Component {
  constructor(props) {
    super(props);
    const { ltiEmail } = props;
    this.state = {
      email: ltiEmail || '',
      password: '',
    };
  }

  componentDidMount() {
    const { dispatchLogout } = this.props;
    dispatchLogout();
  }

  setEmail(email) {
    this.setState({ email });
  }

  setPassword(password) {
    this.setState({ password });
  }

  signIn(event) {
    const { dispatchAuthenticate } = this.props;
    const { email, password } = this.state;
    event.preventDefault();
    dispatchAuthenticate(email, password);
  }

  signInGoogle(googleResponse) {
    const { dispatchAuthenticateProvider } = this.props;
    const { credential } = googleResponse;
    dispatchAuthenticateProvider('google', credential);
  }

  render() {
    const { error, message, userRole } = this.props;
    const { email, password } = this.state;

    return (
      <SignInForm
        email={email}
        password={password}
        setEmail={(e) => this.setEmail(e)}
        signIn={(e) => this.signIn(e)}
        setPassword={(p) => this.setPassword(p)}
        signInGoogle={(e) => this.signInGoogle(e)}
        error={error}
        message={message}
        userRole={userRole}
      />
    );
  }
}

SignIn.propTypes = {
  ltiEmail: PropTypes.string,
  error: PropTypes.shape({ field: PropTypes.string, message: PropTypes.string }),
  message: PropTypes.string,
  userRole: PropTypes.string.isRequired,
  dispatchAuthenticate: PropTypes.func.isRequired,
  dispatchAuthenticateProvider: PropTypes.func.isRequired,
  dispatchLogout: PropTypes.func.isRequired,
};

SignIn.defaultProps = {
  ltiEmail: '',
  error: {},
  message: '',
};

const mapStateToProps = ({ authentication }) => ({
  ltiVerify: authentication.get('ltiVerify'),
  ltiEmail: authentication.get('ltiEmail'),
  ltiProvider: authentication.get('ltiProvider'),
  error: findError(authentication.get('error')),
  message: authentication.get('message'),
});

const mapDispatchToProps = (dispatch, { userRole }) => ({
  dispatchAuthenticate: (email, password) => (
    dispatch(authenticate({
      email, password, userRole,
    }))
  ),
  dispatchAuthenticateProvider: (provider, providerToken) => (
    dispatch(authenticate({
      provider, providerToken, userRole,
    }))
  ),
  dispatchLogout: () => dispatch(logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
