import React, { useState } from 'react';
import PropType from 'prop-types';
import './Tooltip.scss';

const Tooltip = ({ copy, height }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const toggleTooltip = () => setTooltipVisible(!tooltipVisible);

  return (copy !== 'Normal' && copy !== 'Unit Closed') && (
    // sr-only class ensures that screen reader can read tooltip w/o mouse interaction
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className="tooltip-container"
      style={{ height }}
      onMouseEnter={() => setTooltipVisible(true)}
      onMouseLeave={() => setTooltipVisible(false)}
      onClick={toggleTooltip}
      onKeyPress={toggleTooltip}
    >
      <span className={`tooltip ${tooltipVisible ? 'show' : 'sr-only'}`}>{copy}</span>
    </div>
  );
};

Tooltip.propTypes = {
  copy: PropType.string.isRequired,
  height: PropType.string,
};

Tooltip.defaultProps = {
  height: '40px',
};

export default Tooltip;
