import React from 'react';
import ButtonLink from './ButtonLink';
import './Migration.scss';

const { REACT_APP_ICEV_LOGIN } = process.env;

const MigrationUnavailable = () => (
  <div className="migration-unavailable">
    <div className="migration-unavailable-message">
      <h1>You don&apos;t have any courses available for migration</h1>
      <p>
        This could be because you have completed your migration
        {' '}
        or you may not have any courses active in your account.
      </p>
      <ButtonLink
        classname="submit"
        href={REACT_APP_ICEV_LOGIN}
        text="Continue to iCEV"
      />
    </div>
  </div>
);

export default MigrationUnavailable;
