import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { getModuleIsClosed } from '../utils/pacing';
import { getModule } from '../redux/Data/selectors';
import ModuleResourceList from '../components/ModuleResourceList';
import connectWithRouterMatch from '../redux/connectWithRouterMatch';

const CustomModule = ({
  resources,
  moduleIsClosed,
}) => (
  <section className="module">
    { moduleIsClosed || resources.isEmpty()
      ? null
      : <ModuleResourceList resources={resources} />}
  </section>
);

CustomModule.propTypes = {
  resources: PropTypes.instanceOf(List).isRequired,
  moduleIsClosed: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, { match: { params } }) => {
  const { courseId, moduleKey, siteId } = params;
  const moduleData = getModule(state, siteId, courseId, moduleKey);
  const moduleIsClosed = getModuleIsClosed(moduleData);
  const resources = moduleData.get('teacher_module_resources') || List();

  return ({
    moduleKey,
    resources,
    moduleIsClosed,
  });
};

export default connectWithRouterMatch(mapStateToProps)(CustomModule);
