import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import { useLocation, useMatch } from 'react-router-dom';
import { authenticate, logout, setLastActivity } from '../redux/Authentication/actions';

const SessionHandler = ({
  authToken,
  userRole,
  message,
  user,
  authenticateWithToken,
  setNavigateActivity,
  signout,
}) => {
  const isSignout = useMatch('/signout');
  const isMigrate = !!useMatch('/migrate');
  const location = useLocation();

  useEffect(() => {
    if (isSignout) {
      signout(message);
    } else if (authToken && user.size < 1) {
      authenticateWithToken(authToken, userRole, isMigrate);
    }
  }, [isSignout, authToken]);

  useEffect(() => {
    setNavigateActivity(location.pathname);
  }, [location]);

  return null;
};

SessionHandler.propTypes = {
  authToken: PropTypes.string,
  userRole: PropTypes.string,
  message: PropTypes.string,
  user: PropTypes.instanceOf(Map),
  authenticateWithToken: PropTypes.func.isRequired,
  setNavigateActivity: PropTypes.func.isRequired,
  signout: PropTypes.func.isRequired,
};

SessionHandler.defaultProps = {
  authToken: '',
  userRole: '',
  message: '',
  user: Map(),
};

const mapStateToProps = ({ authentication }) => ({
  authToken: authentication.get('authToken'),
  userRole: authentication.get('userRole'),
  user: authentication.get('user'),
  message: authentication.get('message'),
});

const mapDispatchToProps = (dispatch) => ({
  authenticateWithToken: (authToken, userRole, courseMigration) => (
    dispatch(authenticate({ authToken, userRole, courseMigration }))
  ),
  setNavigateActivity: (pathname) => dispatch(setLastActivity({ type: 'navigate', pathname })),
  signout: (message) => dispatch(logout(message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SessionHandler);
