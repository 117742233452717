import { put, takeLatest } from 'redux-saga/effects';
import {
  AUTHENTICATION_AUTHENTICATE_ERROR,
  AUTHENTICATION_SITE_DATA_ERROR,
  logout,
} from '../Authentication/actions';
import {
  DATA_ASSESSMENT_ERROR,
  DATA_ASSESSMENT_SUCCESS,
  DATA_ASSESSMENT_RESULTS_ERROR,
  DATA_ASSESSMENT_RESULTS_SUCCESS,
  DATA_CHALLENGE_ANSWER_ERROR,
  DATA_CHALLENGE_ERROR,
  DATA_CHALLENGE_SUCCESS,
  DATA_COURSE_ERROR,
  DATA_COURSE_SUCCESS,
  DATA_LESSON_ERROR,
  DATA_LESSON_SUCCESS,
  DATA_LESSON_WORK_ERROR,
  DATA_LESSON_WORK_UPDATED,
  DATA_MIGRATION_REQUEST_ERROR,
  incrementErrorCount,
  clearErrors,
} from '../Data/actions';

export function* doHandleError({ error, code }) {
  const normalAuth = [
    'password',
    'e-mail',
    'Overages',
    'Warning Period',
    'single sign on',
  ];
  if (code === 403) { // if 403 and not normal login error, dispatch logout action
    if (!normalAuth.some((word) => error.includes(word))) {
      yield put(logout());
    }
  } else {
    window.Rollbar.error(error, { code });
    yield put(incrementErrorCount());
  }
}

export function* doClearErrors() {
  yield put(clearErrors());
}

export function* watchErrors() {
  yield takeLatest([
    AUTHENTICATION_AUTHENTICATE_ERROR,
    AUTHENTICATION_SITE_DATA_ERROR,
    DATA_COURSE_ERROR,
    DATA_LESSON_ERROR,
    DATA_ASSESSMENT_ERROR,
    DATA_ASSESSMENT_RESULTS_ERROR,
    DATA_CHALLENGE_ERROR,
    DATA_CHALLENGE_ANSWER_ERROR,
    DATA_LESSON_WORK_ERROR,
    DATA_MIGRATION_REQUEST_ERROR,
  ], doHandleError);
}

export function* watchSuccess() {
  yield takeLatest([
    DATA_COURSE_SUCCESS,
    DATA_LESSON_SUCCESS,
    DATA_ASSESSMENT_SUCCESS,
    DATA_ASSESSMENT_RESULTS_SUCCESS,
    DATA_CHALLENGE_SUCCESS,
    DATA_LESSON_WORK_UPDATED,
  ], doClearErrors);
}
