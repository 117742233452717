import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Alert from '../components/Alert';
import AESLogo from '../components/AESLogo';
import SignInRedirect from './SignInRedirect';
import TeacherAvatar from '../components/TeacherAvatar';
import StudentAvatar from '../components/StudentAvatar';
import './Landing.scss';

const Landing = ({ authToken, message }) => {
  if (authToken) {
    return <SignInRedirect />;
  }

  return (
    <>
      {message
        ? <Alert message={message} />
        : null}
      <AESLogo />
      <section className="landing">
        <div className="choices">
          <div className="student">
            <StudentAvatar />
            <Link to="/student" className="button primary">
              I am a Student
            </Link>
          </div>
          <div className="teacher">
            <TeacherAvatar />
            <Link to="/teacher" className="button primary">
              I am a Teacher
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

Landing.propTypes = {
  authToken: PropTypes.string,
  message: PropTypes.string,
};
Landing.defaultProps = {
  authToken: '',
  message: '',
};

const mapStateToProps = ({ authentication }) => ({
  message: authentication.get('message'),
  authToken: authentication.get('authToken'),
});

export default connect(mapStateToProps)(Landing);
