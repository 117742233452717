import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { Map } from 'immutable';
import { formatAssessmentDate } from '../utils/date';
import { formatGrade } from '../utils/formatting';
import './AssessmentResults.scss';
import Icon from './Icon';
import AssessmentLearnosity from './AssessmentLearnosity';
import Alert from './Alert';

const renderHistory = (history) => (
  history
    ? (
      <>
        <h3>History of Attempts</h3>
        <table className="attempts">
          <thead>
            <tr>
              <td>Completed</td>
              <td>Score</td>
              <td>Grade</td>
              <td>Date Reset</td>
              <td>Reset by</td>
            </tr>
          </thead>
          <tbody>
            {history.map((item) => {
              const completed = item.get('completed_at');
              const grade = item.get('lesson_grade');
              const correctCount = item.get('total_earned') / 10;
              const questionCount = item.get('total_possible') / 10;
              const reset = item.get('deleted_at');
              const retaken = item.get('retaken');

              return (
                <tr key={item.get('id')}>
                  <td>
                    { completed
                      ? formatAssessmentDate(completed)
                      : '-' }
                  </td>
                  <td>
                    {`${correctCount}/${questionCount}`}
                  </td>
                  <td>
                    { formatGrade(grade) }
                  </td>
                  <td>
                    { reset
                      ? formatAssessmentDate(reset)
                      : '-' }
                  </td>
                  <td>
                    { reset
                      ? `${retaken ? 'Student' : 'Teacher'}`
                      : '-' }
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    )
    : null
);

const renderAnswers = (answers) => (
  answers
    ? (
      <section className="answers">
        {answers.map((answer, index) => {
          const answerKey = `choice_${answer.get('answer')
            .toLowerCase()}`;
          const isCorrect = answer.get('status') > 1;
          const question = answer.get('question');
          const correctAnswerKey = question.has('answer')
            ? `choice_${question.get('answer')
              .toLowerCase()}`
            : null;
          const paragraphs = question.get('paragraphs');
          return (
            <article className="question" key={answer.get('question_key')}>
              <p className="bold">
                {`Question ${index + 1}`}
              </p>
              <div>
                <ReactMarkdown>
                  {paragraphs}
                </ReactMarkdown>
              </div>
              <div>
                {isCorrect
                  ? (
                    <div className="correct">
                      <div className="result">
                        <Icon text="check" />
                        <p>Correct</p>
                      </div>
                    </div>
                  )
                  : (
                    <div className="error">
                      {correctAnswerKey
                        ? (
                          <>
                            <div className="result">
                              <Icon text="close" />
                              <p>Incorrect</p>
                            </div>
                            <p className="answer">{`${question.get(correctAnswerKey)}`}</p>
                          </>
                        )
                        : (
                          <>
                            <div className="result">
                              <Icon text="close" />
                              <p>Incorrect</p>
                            </div>
                          </>
                        )}
                    </div>
                  )}
                <p>{question.get(answerKey) || 'Not Answered'}</p>
              </div>
            </article>
          );
        })}
      </section>
    )
    : null
);

const AssessmentResults = ({
  assessmentClosed, results, retake, type, work, quizOverride, overrideEarned,
}) => {
  const answers = results.get('answers');
  const learnosityRequest = results.get('learnosityRequest');
  const canRetake = !assessmentClosed && work.get('attempts_left') > 0;
  const quizRetryWait = work.get('attempt_timer');
  const history = results.get('history');
  const correctCount = work.get('total_earned') / 10;
  const grade = formatGrade(work.get('lesson_grade'));
  const questionCount = work.get('total_possible') / 10;

  return (
    <div className="container assessment-results">
      <div className="results-header">
        { type === 'quiz' && !quizOverride && !canRetake
          ? <Alert message={assessmentClosed ? 'Closed' : 'Attempt limit reached'} />
          : null }
        { quizOverride
          ? <Alert type="info" message={`Grade changed by teacher.${type === 'quiz' ? ' No retakes allowed.' : ''}`} />
          : null }
        <h3 className="grade">
          { !quizOverride
            ? `Grade: ${grade} (${correctCount} out of ${questionCount} correct)`
            : `Grade: ${grade} (${overrideEarned} out of ${questionCount} correct)`}
        </h3>
        {canRetake && !quizOverride
          ? (
            <button type="button" onClick={() => retake()} disabled={quizRetryWait > 0}>
              {quizRetryWait > 0
                ? `Retake Quiz in ${quizRetryWait} min.`
                : 'Retake Quiz'}
            </button>
          )
          : type === 'quiz' && <button type="button" disabled>Retake Quiz</button> }
      </div>
      {renderHistory(history)}
      <h3>Results</h3>
      {learnosityRequest
        ? <AssessmentLearnosity requestString={learnosityRequest} reviewMode />
        : renderAnswers(answers)}
    </div>
  );
};

AssessmentResults.propTypes = {
  assessmentClosed: PropTypes.bool.isRequired,
  results: PropTypes.instanceOf(Map).isRequired,
  retake: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  work: PropTypes.instanceOf(Map).isRequired,
  quizOverride: PropTypes.bool.isRequired,
  overrideEarned: PropTypes.number.isRequired,
};

export default AssessmentResults;
