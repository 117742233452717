import { fromJS, Map } from 'immutable';

const { REACT_APP_API_BASE_URL } = process.env;
const { location: { hostname: host, origin } } = window;
const AUTH_URL = `${REACT_APP_API_BASE_URL}/authenticate`;
const ENROLL_URL = `${REACT_APP_API_BASE_URL}/enrollments`;
const LEARNER_URL = `${REACT_APP_API_BASE_URL}/learner`;
const COURSES_URL = `${LEARNER_URL}/courses`;
const STUDENT_URL = `${LEARNER_URL}/profile`;
const TEACHER_COURSES_URL = `${REACT_APP_API_BASE_URL}/teacher/courses`;
const RESET_PASSWORD_URL = `${REACT_APP_API_BASE_URL}/password_recovery`;
const INVITE_URL = `${REACT_APP_API_BASE_URL}/invitation`;
const PROVIDERS_URL = `${REACT_APP_API_BASE_URL}/providers`;
const JOB_URL = `${REACT_APP_API_BASE_URL}/jobs`;
const V2_URL = REACT_APP_API_BASE_URL.replace('v1', 'v2');
const jsonHeaders = { 'Content-Type': 'application/json' };

const authHeaders = (token) => ({
  'Content-Type': 'application/json',
  'X-Auth-Token': token,
});

const fetchEmpty = (resource, init) => fetch(resource, init)
  .then((response) => response.text()
    .then((body) => (
      response.ok
        ? Map({ code: response.status })
        : Map({ code: response.status, error: body })
    )));

const fetchJson = (resource, init, skipRollbar = false) => fetch(resource, init)
  .then((response) => response.text()
    .then((body) => {
      const code = response.status;
      try {
        const json = JSON.parse(body);
        return response.ok
          ? json
          : { ...json, code };
      } catch (error) {
        if (!skipRollbar) {
          window.Rollbar.error(error, { body, code });
        }
        throw error;
      }
    }))
  .then((json) => fromJS(json));

export const authenticate = (
  email,
  password,
  role,
  provider,
  providerToken,
  token,
) => fetchJson(AUTH_URL, {
  method: 'POST',
  body: JSON.stringify({
    email,
    password,
    role,
    provider,
    code: providerToken,
    token,
  }),
  headers: jsonHeaders,
});

export const authenticateSite = (siteId, authToken) => fetchJson(AUTH_URL, {
  method: 'POST',
  body: JSON.stringify({
    signin: 'student',
    site: siteId,
    token: authToken,
    logon: 'STUDENT',
  }),
  headers: jsonHeaders,
});

export const authenticateSiteTeacher = (siteId, authToken) => fetchJson(AUTH_URL, {
  method: 'POST',
  body: JSON.stringify({
    signin: 'teacher',
    site: siteId,
    token: authToken,
    logon: 'TEACHER_NEW',
  }),
  headers: jsonHeaders,
});

export const deleteToken = (token) => fetchEmpty(AUTH_URL, {
  method: 'DELETE',
  headers: authHeaders(token),
});

export const loadTeacherCoursesData = (siteToken) => fetchJson(
  TEACHER_COURSES_URL,
  { headers: authHeaders(siteToken) },
);

export const refreshToken = (token, refreshAssessment) => fetchJson(
  `${AUTH_URL}?refresh=true${refreshAssessment ? `&locked_quiz_id=${refreshAssessment}` : ''}`,
  { headers: authHeaders(token) },
);

export const resetPassword = (email) => fetchJson(
  RESET_PASSWORD_URL,
  {
    method: 'POST',
    body: JSON.stringify({ email }),
    headers: jsonHeaders,
  },
);

export const setPassword = (code, email, password, passwordConfirmation) => fetchJson(
  RESET_PASSWORD_URL,
  {
    method: 'POST',
    body: JSON.stringify({
      code,
      email,
      password,
      password_confirmation: passwordConfirmation,
    }),
    headers: jsonHeaders,
  },
);

export const acceptInvite = (code, password, confirmation) => fetchJson(
  INVITE_URL,
  {
    method: 'POST',
    body: JSON.stringify({
      code,
      password,
      password_confirmation: confirmation,
    }),
    headers: jsonHeaders,
  },
);

export const checkEmailAvailable = (email) => fetchJson(`${PROVIDERS_URL}?email=${email}`)
  .then((providers) => providers.size < 1);

export const enrollVerify = (enrollmentCode) => fetchJson(
  `${ENROLL_URL}/${enrollmentCode}`, {
    method: 'GET',
    headers: jsonHeaders,
  },
);

export const enrollStudent = ({ enrollmentCode, ssoEnroll, authToken }) => fetchJson(ENROLL_URL, {
  method: 'POST',
  body: JSON.stringify({
    token: authToken,
    sso_enroll: ssoEnroll,
    enrollment_code: enrollmentCode,
  }),
  headers: jsonHeaders,
});

export const createNewStudent = ({
  firstName,
  lastName,
  email,
  password,
  passwordConfirmation,
  enrollmentCode,
  provider,
  providerToken,
}) => fetchJson(ENROLL_URL, {
  method: 'POST',
  body: JSON.stringify({
    enrollment_code: enrollmentCode,
    first_name: firstName,
    last_name: lastName,
    email,
    password,
    password_confirmation: passwordConfirmation,
    code: providerToken,
    provider,
  }),
  headers: jsonHeaders,
});

export const updateStudent = ({
  firstName,
  lastName,
  email,
  password,
  passwordConfirmation,
  token,
}) => fetchJson(STUDENT_URL, {
  method: 'PUT',
  body: JSON.stringify({
    student: {
      first_name: firstName,
      last_name: lastName,
      email,
      password,
      password_confirmation: passwordConfirmation,
    },
  }),
  headers: authHeaders(token),
});

export const changeStudentPreferences = ({
  preference: { key, value },
}, token) => fetchJson(STUDENT_URL, {
  method: 'PUT',
  body: JSON.stringify({
    student: {
      preferences: {
        [key]: value,
      },
    },
  }),
  headers: authHeaders(token),
});

export const getCourseData = (courseId, siteToken) => fetchJson(
  `${COURSES_URL}/${courseId}`,
  { headers: authHeaders(siteToken) },
);

export const getModuleData = (courseId, assignmentId, siteToken) => fetchJson(
  `${COURSES_URL}/${courseId}?assignment_id=${assignmentId}`,
  { headers: authHeaders(siteToken) },
);

export const getCourseDataViewAsStudent = (courseId, siteToken) => fetchJson(
  `${V2_URL}/teacher/courses_as_student/${courseId}`,
  { headers: authHeaders(siteToken) },
);

export const getLessonData = (courseId, assignmentEntryId, siteToken) => fetchJson(
  `${COURSES_URL}/${courseId}/lessons/${assignmentEntryId}`,
  { headers: authHeaders(siteToken) },
);

export const getLessonDataViewAsStudent = (courseId, assigmentEntryId, siteToken) => fetchJson(
  `${V2_URL}/teacher/courses/${courseId}/lessons_as_student/${assigmentEntryId}`,
  { headers: authHeaders(siteToken) },
);

export const getWorksheetData = (courseId, unitWorkId, siteToken) => fetchJson(
  `${COURSES_URL}/${courseId}/unit_works/${unitWorkId}/worksheets`,
  { headers: authHeaders(siteToken) },
);

export const getAssessmentData = (
  courseId,
  assignmentId,
  assignmentEntryId,
  preTest,
  siteToken,
) => fetchJson(
  `${COURSES_URL}/${courseId}/assessments`,
  {
    method: 'POST',
    body: JSON.stringify({
      assignment_id: assignmentId,
      assignment_entry_id: assignmentEntryId,
      pre_test: preTest,
      host,
    }),
    headers: authHeaders(siteToken),
  },
);

export const getAssessmentDataViewAsStudent = (
  courseId,
  assignmentId,
  assignmentEntryId,
  preTest,
  siteToken,
) => fetchJson(
  `${V2_URL}/teacher/courses/${courseId}/assessments_as_student`,
  {
    method: 'POST',
    body: JSON.stringify({
      assignment_id: assignmentId,
      assignment_entry_id: assignmentEntryId,
      host,
    }),
    headers: authHeaders(siteToken),
  },
).then((data) => data.set('lesson_work', Map({ loaded: true })));

export const getAssessmentResults = (courseId, lessonWorkId, siteToken) => fetchJson(
  `${COURSES_URL}/${courseId}/assessments/${lessonWorkId}?host=${host}`,
  { headers: authHeaders(siteToken) },
);

export const getAssessmentStatus = (courseId, lessonWorkId, siteToken) => fetchJson(
  `${COURSES_URL}/${courseId}/assessments/status/${lessonWorkId}`,
  { headers: authHeaders(siteToken) },
);

export const getLockedAssessmentData = (lessonWorkId, siteToken) => fetchJson(
  `${LEARNER_URL}/lookups/${lessonWorkId}`,
  { headers: authHeaders(siteToken) },
);

export const getChallengeData = (courseId, reset, siteToken) => fetchJson(
  `${COURSES_URL}/${courseId}/challenges?host=${host}${reset ? '&reset=1' : ''}`,
  { headers: authHeaders(siteToken) },
);

export const getChallengeResults = (courseId, siteToken) => fetchJson(
  `${COURSES_URL}/${courseId}/challenges/results`,
  { headers: authHeaders(siteToken) },
);

export const setAssessmentAnswer = (courseId, answerId, answer, siteToken) => fetch(
  `${COURSES_URL}/${courseId}/assessments/${answerId}`,
  {
    method: 'PUT',
    body: JSON.stringify({ answer }),
    headers: authHeaders(siteToken),
  },
);

export const setChallengeAttempt = (courseId, itemKey, attempt, siteToken) => fetchJson(
  `${COURSES_URL}/${courseId}/challenges/${itemKey}`,
  {
    method: 'PUT',
    body: JSON.stringify(attempt),
    headers: authHeaders(siteToken),
  },
);

export const getQuestionTts = (question, siteToken) => fetchJson(
  `${LEARNER_URL}/tts`,
  {
    method: 'POST',
    body: JSON.stringify({ data: question }),
    headers: authHeaders(siteToken),
  },
);

export const getJob = (jobId, siteToken) => fetchJson(
  `${JOB_URL}/${jobId}`,
  { headers: authHeaders(siteToken) },
);

export const finishAssessment = (courseId, lessonWorkId, learnositySession, siteToken) => fetchJson(
  `${COURSES_URL}/${courseId}/assessments/finish`,
  {
    method: 'PUT',
    body: JSON.stringify({
      lesson_work_id: lessonWorkId,
      session_id: learnositySession,
    }),
    headers: authHeaders(siteToken),
  },
);

export const resetLesson = (courseId, lessonWorkId, siteToken) => fetchJson(
  `${COURSES_URL}/${courseId}/lessons/${lessonWorkId}`,
  {
    method: 'DELETE',
    headers: { 'X-Auth-Token': siteToken },
  },
);

export const resetLessonViewAsStudent = (courseId, lessonWorkId, siteToken) => fetchJson(
  `${V2_URL}/teacher/courses/${courseId}/lessons_as_student/${lessonWorkId}`,
  {
    method: 'DELETE',
    headers: { 'X-Auth-Token': siteToken },
  },
);

export const logEvent = (enrollmentId, type, params = {}, siteToken) => fetchEmpty(
  `${LEARNER_URL}/events`,
  {
    method: 'POST',
    body: JSON.stringify({
      enrollment_id: enrollmentId,
      action_type: type,
      ...params,
    }),
    headers: authHeaders(siteToken),
  },
);

export const getKeyboardingResults = (courseId, siteToken) => fetchJson(
  `${REACT_APP_API_BASE_URL}/gaming/leaderboard/${courseId}/summary?game_type=KEYBOARDING`,
  { headers: authHeaders(siteToken) },
);

export const getEventData = () => fetchJson(
  `${origin}/status/eventData.json?t=${Date.now()}`,
  { headers: jsonHeaders },
  true,
).catch(() => []);

export const startMigration = (courseIds, siteToken, teacherContext) => fetchJson(
  `${V2_URL}/teacher/courses/migrate`,
  {
    method: 'POST',
    headers: authHeaders(siteToken),
    body: JSON.stringify({
      course_ids: courseIds,
      teacher_context: teacherContext,
    }),
  },
);

export const createIcevTeacher = (siteToken) => fetchJson(
  `${REACT_APP_API_BASE_URL}/teacher/profile/icev_migrate`,
  { method: 'POST', headers: authHeaders(siteToken) },
);
