import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import MigrationErrorCourses from './MigrationErrorCourses';

const MigrationErrorSites = ({ errors }) => (
  <div>
    {errors.map((error) => (
      <div key={error.get('siteName')}>
        <div className="error-site">{error.get('siteName')}</div>
        <MigrationErrorCourses courses={error.get('courses')} />
      </div>
    )).toList()}
    <div className="cs-email">
      Should you have any further queries, please contact
      {' '}
      <a
        href="https://www.icevonline.com/contact-us"
        className="customer-support-link"
        target="_blank"
        rel="noreferrer noopener"
      >
        Customer Support
      </a>
    </div>

  </div>
);

MigrationErrorSites.propTypes = {
  errors: PropTypes.instanceOf(Map).isRequired,
};

export default MigrationErrorSites;
