import React from 'react';
import PropTypes from 'prop-types';
import Prompt from './Prompt';
import Input from './Input';
import Alert from './Alert';
import Progress from './Progress';
import './EnrollmentPrompt.scss';

const EnrollmentPrompt = ({
  enrollmentError,
  enrollCode,
  setEnrollCode,
  resetEnroll,
  showModal,
  onSubmitEnrollment,
  enrollSuccess,
}) => (showModal && (
  <Prompt
    type="enroll-modal"
    title={enrollSuccess ? 'Enrolled' : 'Please Enter an Enrollment Code'}
  >
    { enrollSuccess
      ? (
        <div className="success-message">
          <Alert type="success" message="Successfully enrolled! Loading your class." />
          <Progress />
        </div>
      )
      : (
        <form onSubmit={(e) => onSubmitEnrollment(e)}>
          <Input
            error={enrollmentError}
            value={enrollCode}
            onChange={(e) => setEnrollCode(e.target.value)}
            type="text"
            name="code"
            placeholder="Enter Code"
          />
          <div className="actions">
            <button
              type="button"
              className="cancel"
              onClick={() => resetEnroll()}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="submit"
            >
              Next
            </button>
          </div>
        </form>
      )}
  </Prompt>
));

EnrollmentPrompt.propTypes = {
  enrollmentError: PropTypes.string.isRequired,
  enrollCode: PropTypes.string.isRequired,
  setEnrollCode: PropTypes.func.isRequired,
  resetEnroll: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  onSubmitEnrollment: PropTypes.func.isRequired,
  enrollSuccess: PropTypes.bool.isRequired,
};

export default EnrollmentPrompt;
