export const VIEW_SET_ASSESSMENT_COMPLETE = 'VIEW_SET_ASSESSMENT_COMPLETE';
export const VIEW_SET_ASSESSMENT_META = 'VIEW_SET_ASSESSMENT_META';
export const VIEW_SET_ALL_ANSWERS = 'VIEW_SET_ALL_ANSWERS';
export const VIEW_SET_REDIRECT = 'VIEW_SET_REDIRECT';
export const VIEW_CLEAR = 'VIEW_CLEAR';
export const VIEW_SET_REQUEST_ACTIVE = 'VIEW_SET_REQUEST_ACTIVE';
export const VIEW_SET_REQUEST_FINISHED = 'VIEW_SET_REQUEST_FINISHED';
export const VIEW_VAS_DISMISS = 'VIEW_VAS_DISMISS';
export const VIEW_EVENTS_DISMISS = 'VIEW_ALERTS_DISMISS';
export const VIEW_MIGRATION_COURSES_SELECTED = 'VIEW_MIGRATION_COURSES_SELECTED';
export const VIEW_MIGRATION_COMPLETED = 'VIEW_MIGRATION_COMPLETED';
export const VIEW_MIGRATION_ERROR = 'VIEW_MIGRATION_ERROR';
export const VIEW_SET_MIGRATION_REPORT = 'VIEW_SET_MIGRATION_REPORT';

export const setAssessmentComplete = () => ({ type: VIEW_SET_ASSESSMENT_COMPLETE });

export const setAssessmentMetadata = (assessmentMetadata) => ({
  type: VIEW_SET_ASSESSMENT_META,
  assessmentMetadata,
});

export const redirectTo = (path) => ({ type: VIEW_SET_REDIRECT, path });

export const clearView = () => ({ type: VIEW_CLEAR });

export const setRequestActive = (key) => ({ type: VIEW_SET_REQUEST_ACTIVE, key });

export const setRequestFinished = (key) => ({ type: VIEW_SET_REQUEST_FINISHED, key });

export const dismissVAS = () => ({ type: VIEW_VAS_DISMISS });

export const dismissEventBanner = () => ({ type: VIEW_EVENTS_DISMISS });

export const updateMigrationCoursesSelected = (selectedIds) => ({
  type: VIEW_MIGRATION_COURSES_SELECTED,
  selectedIds,
});

export const setMigrationCompleted = () => ({ type: VIEW_MIGRATION_COMPLETED });

export const setMigrationError = (error) => ({ type: VIEW_MIGRATION_ERROR, error });

export const setMigrationReport = (errorReport) => ({
  type: VIEW_SET_MIGRATION_REPORT,
  errorReport,
});
