import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { isLinkedToICEV, isTeacher } from '../redux/Authentication/selectors';
import { authenticateEnrollStudent } from '../redux/Authentication/actions';
import './SignIn.scss';

const MigrationRedirect = (props) => {
  const {
    teacher,
    linkedToICEV,
  } = props;

  if (teacher) {
    if (linkedToICEV) {
      return <Navigate to="/migrate/sites/" />;
    }
    return <Navigate to="/migrate/confirm-account/" />;
  }

  return null;
};

MigrationRedirect.propTypes = {
  teacher: PropTypes.bool.isRequired,
  linkedToICEV: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const { authentication } = state;
  const siteId = authentication.get('enrollmentSiteId');
  const courseId = authentication.get('enrollmentCourseId');
  const teacher = isTeacher(state);
  const linkedToICEV = isLinkedToICEV(state);

  return {
    siteId,
    courseId,
    teacher,
    linkedToICEV,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchEnrollStudent: (enrollmentCode) => dispatch(
    authenticateEnrollStudent({ enrollmentCode }),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(MigrationRedirect);
