import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import {
  logEvent,
  requestLessonData,
  updateLessonWork,
  requestModuleData,
  lessonWorkFailed,
} from '../redux/Data/actions';
import { getCourse, getUnit } from '../redux/Data/selectors';
import { getSiteToken, isViewAsStudent } from '../redux/Authentication/selectors';
import ScormProvider from '../services/scorm';
import { setLastActivity } from '../redux/Authentication/actions';
import { redirectTo } from '../redux/View/actions';
import connectWithRouterMatch from '../redux/connectWithRouterMatch';
import './Lesson.scss';

class Lesson extends React.Component {
  constructor(props) {
    super(props);
    this.iframe = createRef();
    this.state = {};
  }

  componentDidMount() {
    const { dispatch } = this.props;
    this.requestLessonData();
    this.setupScorm();
    this.beforeUnloadListener = () => {
      dispatch.requestModuleData();
      dispatch.logLessonExit();
    };
    window.addEventListener('beforeunload', this.beforeUnloadListener);
  }

  componentDidUpdate(prevProps) {
    const { loaded: prevLoaded } = prevProps;
    const { loaded } = this.props;

    this.requestLessonData();

    if (loaded && !prevLoaded) {
      this.setupScorm();
    }
    this.iframe.current?.focus();
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    window.removeEventListener('beforeunload', this.beforeUnloadListener);
    dispatch.requestModuleData();
    dispatch.logLessonExit();
  }

  onTerminate() {
    const {
      dispatch,
      unitPath,
      pageSetType,
    } = this.props;
    if (pageSetType === 'SCRIPT') {
      // Get status from the back end for pre-cbuilder lessons
      dispatch.requestLessonData();
    }
    dispatch.redirect(unitPath);
  }

  onSetData(lessonWorkData) {
    const { dispatch } = this.props;
    dispatch.updateLessonWork(Map(lessonWorkData));
    dispatch.setLessonActivity();
  }

  onDataError({ error, code }) {
    const { dispatch } = this.props;
    dispatch.lessonWorkFailed(error, code);
  }

  setupScorm() {
    const {
      siteId,
      courseId,
      enrollmentId,
      lessonWorkId,
      pageSetExtra,
      scormData,
      token,
      unitVersion,
      moduleKey,
      pageSetKey,
      pageSetType,
      viewAsStudent,
      loaded,
      dispatch,
    } = this.props;
    const onTerminate = () => this.onTerminate();
    const onPostData = (lessonWorkData) => this.onSetData(lessonWorkData);
    const onPostError = (errorData) => this.onDataError(errorData);
    const onContentChange = (contentUrl) => this.setState({ contentUrl });

    if (loaded) {
      const scormProvider = new ScormProvider({
        siteId,
        courseId,
        enrollmentId,
        lessonWorkId,
        onPostData,
        onPostError,
        onTerminate,
        onContentChange,
        pageSetExtra,
        scormData,
        token,
        unitVersion,
        moduleKey,
        pageSetKey,
        pageSetType,
        viewAsStudent,
      });
      const { contentUrl } = scormProvider.GetContentData();
      this.setState({ contentUrl });
      dispatch.logLessonEnter(lessonWorkId);
    }
  }

  requestLessonData() {
    const { loaded, token, dispatch } = this.props;
    if (!loaded && token) {
      dispatch.requestLessonData();
    }
  }

  render() {
    const { contentUrl } = this.state;

    if (contentUrl) {
      // detect "Jeopardy game" to add an exit button, since they don't implement one;
      const isGame = contentUrl.includes('game.html');
      return (
        <>
          { isGame
            ? (
              <div className="exit-button container">
                <button type="button" className="secondary" onClick={() => this.onTerminate()}>
                  Exit
                </button>
              </div>
            )
            : null }

          <iframe
            ref={this.iframe}
            frameBorder={0}
            src={contentUrl}
            title="lesson content"
            className={isGame ? 'game' : 'lesson'}
          />
        </>
      );
    }
    return (
      <div>{/* placeholder */}</div>
    );
  }
}

Lesson.propTypes = {
  dispatch: PropTypes.object.isRequired,
  courseId: PropTypes.string.isRequired,
  siteId: PropTypes.string.isRequired,
  enrollmentId: PropTypes.number,
  lessonWorkId: PropTypes.number,
  moduleKey: PropTypes.string.isRequired,
  pageSetKey: PropTypes.string.isRequired,
  pageSetExtra: PropTypes.string,
  pageSetType: PropTypes.string,
  scormData: PropTypes.string,
  token: PropTypes.string.isRequired,
  unitPath: PropTypes.string.isRequired,
  unitVersion: PropTypes.number.isRequired,
  viewAsStudent: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
};

Lesson.defaultProps = {
  pageSetExtra: '',
  pageSetType: undefined,
  scormData: '',
  enrollmentId: 0,
  lessonWorkId: 0,
};

const mapStateToProps = (state, { match: { params } }) => {
  const {
    siteId,
    courseId,
    moduleKey,
    unitKey,
    pageSetKey,
  } = params;
  const courseData = getCourse(state, siteId, courseId);
  const enrollmentId = courseData.get('enrollment_id');
  const unitData = getUnit(state, siteId, courseId, moduleKey, unitKey);
  const unitVersion = unitData.get('unit_version') || 0;
  const pageSet = unitData.getIn(['pageSets', pageSetKey]) || Map();
  const pageSetExtra = pageSet.get('page_set_extra');
  const pageSetType = pageSet.get('page_set_type');
  const loaded = !!pageSetExtra || pageSetType === 'SCRIPT';
  const lessonWork = pageSet.get('work') || Map();
  const lessonWorkId = lessonWork.get('id');
  const scormData = lessonWork.get('scormdata');
  const viewAsStudent = isViewAsStudent(state);
  const token = getSiteToken(state, siteId);
  const unitPath = `/site/${siteId}/course/${courseId}/module/${moduleKey}/unit/${unitKey}/`;
  const title = pageSet.get('title');

  return ({
    siteId,
    courseId,
    enrollmentId,
    lessonWorkId,
    pageSetExtra,
    scormData,
    token,
    moduleKey,
    pageSetKey,
    unitPath,
    title,
    unitVersion,
    pageSetType,
    viewAsStudent,
    loaded,
  });
};

const mapDispatchToProps = (dispatch, { match: { params } }) => {
  const {
    siteId,
    courseId,
    moduleKey,
    pageSetKey,
    unitKey,
  } = params;
  return ({
    dispatch: {
      redirect: (path) => dispatch(redirectTo(path)),
      requestModuleData: () => dispatch(requestModuleData(siteId, courseId, moduleKey)),
      requestLessonData: () => dispatch(requestLessonData(siteId, courseId, moduleKey, unitKey)),
      updateLessonWork: (lessonWorkData) => dispatch(
        updateLessonWork(siteId, courseId, moduleKey, unitKey, pageSetKey, lessonWorkData),
      ),
      lessonWorkFailed: (error, code) => dispatch(lessonWorkFailed(error, code)),
      logLessonExit: () => dispatch(logEvent(siteId, courseId, 'lesson_exit')),
      logLessonEnter: (lessonWorkId) => dispatch(
        logEvent(siteId, courseId, 'lesson_enter', { lesson_work_id: lessonWorkId }),
      ),
      setLessonActivity: () => dispatch(setLastActivity({
        type: 'lesson',
        moduleKey,
        unitKey,
        pageSetKey,
      })),
    },
  });
};

export default connectWithRouterMatch(mapStateToProps, mapDispatchToProps)(Lesson);
