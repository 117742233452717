import React from 'react';
import PropTypes from 'prop-types';
import { OrderedMap } from 'immutable';
import { useNavigate } from 'react-router-dom';
import {
  getModule,
  getUnits,
  getTests,
  isPretestRequired,
  getCourse,
} from '../redux/Data/selectors';
import {
  getModuleIsClosed,
  getModuleHasOverrides,
  getModuleCloseReason,
} from '../utils/pacing';
import UnitList from '../components/UnitList';
import TestList from '../components/TestList';
import ClosedMessage from '../components/ClosedMessage';
import './Module.scss';
import Prompt from '../components/Prompt';
import connectWithRouterMatch from '../redux/connectWithRouterMatch';

const Module = ({
  baseUrl,
  moduleKey,
  moduleIsClosed,
  displayAsClosed,
  units,
  tests,
  pretestRequired,
  closedModuleReason,
  courseTitle,
}) => {
  const navigate = useNavigate();
  return (
    <section>
      { displayAsClosed
        ? (
          <ClosedMessage
            classInfo={courseTitle}
            reasonCopy={closedModuleReason}
            messageType="module"
          />
        )
        : (
          <UnitList
            baseUrl={baseUrl}
            units={units}
            moduleKey={moduleKey}
            moduleIsClosed={moduleIsClosed}
          />
        ) }
      { displayAsClosed || tests.isEmpty()
        ? null
        : (
          <TestList
            baseUrl={baseUrl}
            tests={tests}
            moduleIsClosed={moduleIsClosed}
          />
        ) }
      { pretestRequired
        ? (
          <Prompt
            title="Pre-Test Required"
            primaryBtnCB={() => navigate(`${baseUrl}/test/PREMT_${moduleKey}/`)}
            primaryBtnText="Start Pre-Test"
            secondaryBtnCB={() => navigate('../../', { relative: 'path' })}
            secondaryBtnText="Back to Module List"
          >
            <p>
              You must take the pre-test before working in this module.
            </p>
          </Prompt>
        )
        : null }
    </section>
  );
};

Module.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  displayAsClosed: PropTypes.bool.isRequired,
  moduleIsClosed: PropTypes.bool.isRequired,
  moduleKey: PropTypes.string.isRequired,
  units: PropTypes.instanceOf(OrderedMap).isRequired,
  tests: PropTypes.instanceOf(OrderedMap).isRequired,
  pretestRequired: PropTypes.bool.isRequired,
  closedModuleReason: PropTypes.string.isRequired,
  courseTitle: PropTypes.string.isRequired,
};

const mapStateToProps = (state, { match: { params } }) => {
  const { courseId, moduleKey, siteId } = params;
  const baseUrl = `/site/${siteId}/course/${courseId}/module/${moduleKey}`;
  const courseData = getCourse(state, siteId, courseId);
  const courseTitle = courseData.get('course_title') || '';
  const moduleData = getModule(state, siteId, courseId, moduleKey);
  const moduleIsClosed = getModuleIsClosed(moduleData);
  const moduleHasOverrides = getModuleHasOverrides(moduleData);
  const displayAsClosed = moduleIsClosed && !moduleHasOverrides;
  const units = getUnits(moduleData);
  const tests = getTests(moduleData);
  const closedModuleReason = getModuleCloseReason(moduleData);
  const pretestRequired = !moduleIsClosed && isPretestRequired(state, siteId, courseId, moduleKey);

  return ({
    baseUrl,
    closedModuleReason,
    courseTitle,
    moduleKey,
    moduleHasOverrides,
    moduleIsClosed,
    displayAsClosed,
    units,
    tests,
    pretestRequired,
  });
};

export default connectWithRouterMatch(mapStateToProps)(Module);
