import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import './CourseList.scss';
import './ItemLists.scss';
import CourseListItem from './CourseListItem';
import { authenticateEnrollStudent, authenticateEnrollReset } from '../redux/Authentication/actions';
import EnrollmentPrompt from './EnrollmentPrompt';
import { findError } from '../utils/errors';

class CourseList extends Component {
  constructor(props) {
    super(props);
    const { enrollmentCode, enrollmentError } = props;

    this.state = {
      showModal: !!enrollmentCode || !!enrollmentError,
      enrollmentCode,
    };
  }

  componentDidUpdate({ enrollmentError: prevEnrollmentError }) {
    const { enrollmentError } = this.props;
    if (!!enrollmentError && enrollmentError !== prevEnrollmentError) {
      this.showModal();
    }
  }

  onSubmitEnrollment(event) {
    const { dispatchEnrollStudent } = this.props;
    const { enrollmentCode } = this.state;
    event.preventDefault();
    dispatchEnrollStudent(enrollmentCode);
  }

  showModal() {
    this.setState({ showModal: true });
  }

  resetEnroll() {
    const { dispatchEnrollStudentReset } = this.props;
    dispatchEnrollStudentReset();
    this.setState({ showModal: false, enrollmentCode: '' });
  }

  render() {
    const {
      courses,
      enrollmentError,
      enrollSuccess,
    } = this.props;
    const { showModal, enrollmentCode } = this.state;

    return (
      <div className="courses container">
        <div className="header">
          <h1 className="title">My Classes</h1>
          <button type="button" className="submit" onClick={() => this.showModal()}>
            Enroll into a New Class
          </button>
        </div>

        {courses.size
          ? (
            <section className="list">
              {courses.map((courseData) => (
                <CourseListItem key={courseData.get('course_id')} courseData={courseData} />
              ))
                .toList()}
            </section>
          )
          : (
            <div className="license-error">
              <h2>No Active Classes</h2>
              <p>
                Please use the
                <em> Enroll into a New Class </em>
                button above, or contact your teacher for assistance.
              </p>
            </div>
          )}

        <EnrollmentPrompt
          showModal={showModal}
          enrollSuccess={enrollSuccess}
          onSubmitEnrollment={(event) => this.onSubmitEnrollment(event)}
          resetEnroll={() => this.resetEnroll()}
          enrollCode={enrollmentCode}
          enrollmentError={enrollmentError}
          setEnrollCode={(code) => this.setState({ enrollmentCode: code })}
        />
      </div>
    );
  }
}

CourseList.propTypes = {
  courses: PropTypes.instanceOf(Map).isRequired,
  dispatchEnrollStudent: PropTypes.func.isRequired,
  dispatchEnrollStudentReset: PropTypes.func.isRequired,
  enrollmentCode: PropTypes.string.isRequired,
  enrollmentError: PropTypes.string.isRequired,
  enrollSuccess: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ authentication }) => {
  const enrollmentCode = authentication.get('enrollmentCode') || '';
  const enrollmentError = findError(authentication.get('enrollmentError') || '');
  const enrollSuccess = authentication.get('enrollSuccess') || false;
  return {
    enrollmentCode,
    enrollmentError: enrollmentError.message,
    enrollSuccess,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchEnrollStudent: (enrollmentCode) => dispatch(authenticateEnrollStudent({
    enrollmentCode,
  })),
  dispatchEnrollStudentReset: () => dispatch(authenticateEnrollReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseList);
