import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { OrderedMap, Map } from 'immutable';
import { Link } from 'react-router-dom';
import LessonListItem from './LessonListItem';
import './ItemLists.scss';
import './LessonList.scss';
import { formatGrade } from '../utils/formatting';
import Icon from './Icon';
import { getTipsColor, getClosedType } from '../utils/pacing';
import Prompt from './Prompt';
import Tooltip from './Tooltip';

const LessonList = ({
  lessons,
  quizExists,
  quizWork,
  quizIsClosed,
  quizCloseReason,
  quizIsOverridden,
  resetLesson,
  retakeQuiz,
}) => {
  const quizGrade = quizWork.get('lesson_grade');
  const quizStatus = quizWork.get('status');
  const quizAttemptsLeft = !quizIsClosed && quizWork.get('attempts_left') > 0;
  const quizLinkActive = !quizIsClosed || !!quizStatus;
  const quizRetryWait = quizWork.get('attempt_timer');
  const showRetake = quizStatus && quizAttemptsLeft && !quizIsOverridden;
  const tipColor = getTipsColor(quizCloseReason);
  const {
    statusClass,
    icon,
  } = getClosedType(quizCloseReason, !quizLinkActive);

  const [showQuizPrompt, setShowQuizPrompt] = useState(false);
  const [showLessonPrompt, setShowLessonPrompt] = useState(false);

  function resetQuiz() {
    setShowQuizPrompt(false);
    retakeQuiz();
  }

  function lessonReset() {
    setShowLessonPrompt(false);
    resetLesson(showLessonPrompt);
  }

  return (
    <div className="itemContainer lessons">
      <table className="lessonsList">
        <thead>
          <tr className="header">
            <th><h3>Lessons</h3></th>
            <th><h3>Progress</h3></th>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <th />
          </tr>
        </thead>
        <tbody>
          { lessons.map((data) => (
            <LessonListItem
              key={`lesson_${data.get('page_set_key')}`}
              data={data}
              resetLesson={resetLesson}
              setShowLessonPrompt={(lesson) => setShowLessonPrompt(lesson)}
            />
          )).toList() }
        </tbody>
      </table>
      { quizExists
        ? (
          <table className="quiz">
            <thead>
              <tr className="header">
                <th><h3>Assessments</h3></th>
                <th><h3>Progress</h3></th>
                <th><h3>Grade</h3></th>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <th className="reset" />
              </tr>
            </thead>
            <tbody>
              <tr className={`quiz ${statusClass}`}>
                { !quizLinkActive && <Tooltip copy={quizCloseReason} /> }
                <td className="quizTitle">
                  <div className="icon">
                    <Icon text={icon} />
                  </div>
                  <h3>
                    { !quizLinkActive
                      ? (
                        <>
                          <span>
                            Quiz
                          </span>
                          {quizCloseReason !== 'Lessons incomplete'
                            ? (<span className={`tip ${tipColor}`}>{quizCloseReason}</span>)
                            : null}
                        </>
                      )
                      : <Link to="./quiz/post-quiz/">Unit Quiz</Link> }
                  </h3>
                </td>
                <td className="status">
                  { quizStatus
                    ? <Icon text="check" />
                    : '-' }
                </td>
                <td className="details">
                  { quizStatus
                    ? formatGrade(quizGrade)
                    : '-' }
                </td>
                <td className="reset">

                  { showRetake
                    // if quiz started && more attempts allowed && teacher has not overridden grade
                    ? (
                      <button
                        type="button"
                        className="icon"
                        onClick={() => setShowQuizPrompt(true)}
                        disabled={quizRetryWait > 0}
                      >
                        <Icon text="reset" />
                        <span className="tooltip right">
                          {quizRetryWait > 0
                            ? `Retake in ${quizRetryWait} min.`
                            : 'Retake Quiz'}
                        </span>
                      </button>
                    )
                    : null }
                </td>
              </tr>
            </tbody>
          </table>
        )
        : null }

      { showLessonPrompt
        ? (
          <Prompt
            onClickClose={() => setShowLessonPrompt(false)}
            primaryBtnText="Yes"
            primaryBtnCB={() => lessonReset()}
            secondaryBtnText="Cancel"
            secondaryBtnCB={() => setShowLessonPrompt(false)}
          >
            <p>Are you sure that you want to reset your Lesson?</p>
          </Prompt>
        )
        : null }
      { showQuizPrompt
        ? (
          <Prompt
            onClickClose={() => setShowQuizPrompt(false)}
            primaryBtnText="Yes"
            primaryBtnCB={() => resetQuiz()}
            secondaryBtnText="Cancel"
            secondaryBtnCB={() => setShowQuizPrompt(false)}
          >
            <p>Are you sure that you want to retake your Quiz?</p>
          </Prompt>
        )
        : null }
    </div>
  );
};

LessonList.propTypes = {
  lessons: PropTypes.instanceOf(OrderedMap).isRequired,
  quizExists: PropTypes.bool,
  quizWork: PropTypes.instanceOf(Map),
  quizIsClosed: PropTypes.bool,
  quizCloseReason: PropTypes.string,
  quizIsOverridden: PropTypes.bool,
  resetLesson: PropTypes.func.isRequired,
  retakeQuiz: PropTypes.func.isRequired,
};

LessonList.defaultProps = {
  quizExists: true,
  quizWork: Map(),
  quizIsClosed: false,
  quizCloseReason: '',
  quizIsOverridden: false,
};

export default LessonList;
