import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import { getSites } from '../redux/Data/selectors';
import MigrateSiteList from '../components/MigrateSiteList';
import MigrationUnavailable from '../components/MigrationUnavailable';
import './MigrateCourses.scss';
import { logout } from '../redux/Authentication/actions';
import LoadingSection from '../components/LoadingSection';
import { requestMigration } from '../redux/Data/actions';
import Checkbox from '../components/Checkbox';
import { updateMigrationCoursesSelected } from '../redux/View/actions';

const MigrateCourses = ({
  sites,
  migrationCoursesSelected,
  dispatchUpdateCoursesSelected,
  dispatch,
}) => {
  const [selectAllChecked, updateSelectAllChecked] = useState(false);
  const navigate = useNavigate();
  const disableButton = !migrationCoursesSelected?.size;
  const selectedIds = migrationCoursesSelected || List();
  const doMigrate = () => {
    dispatch.requestMigration();
    navigate('/migrate/sites/progress/');
  };

  const teacherCoursesFound = sites.filter((siteData) => {
    const teacherCourses = siteData.get('teacherCourses');
    return teacherCourses?.size > 0;
  });

  /* migration status codes:
     null: Course was never migrated (no badge)
     0: Nothing valid to migrate (dark gray 'Not Supported' badge / checkbox disabled)
     1: Pending (no badge)
     2: Course Fully Migrated (red 'Migrated' badge / checkbox disabled)
     3: Course Partially Migrated w/ Exams Ready for Migration
        (blue 'Exams Outstanding' badge AND red 'Migrated' badge)
     4: Course only has exams (dark gray 'Not Supported' badge / checkbox disabled)
   */
  const hasCoursesForMigration = teacherCoursesFound.map((site) => (
    site.get('teacherCourses')
  ).filter((course) => {
    const status = course.get('icev_migration_status');
    return status === null || status === 3;
  }));

  const migrateableCourseIds = hasCoursesForMigration?.reduce((acc, courseList) => (
    acc.merge(courseList.map((course) => {
      const id = course.get('id');
      const siteId = course.get('site_id');
      return { siteId, id };
    }))
  ), List());

  useEffect(() => {
    if (selectAllChecked) {
      const allMigrateableCourses = migrateableCourseIds.map((obj) => {
        const {
          siteId,
          id,
        } = obj;
        const courseId = Number.parseInt(id, 10);
        const site = Number.parseInt(siteId, 10);

        return Map({
          id: courseId,
          site_id: site,
        });
      });
      dispatchUpdateCoursesSelected(allMigrateableCourses);
    } else if (selectedIds.size > 0) {
      dispatchUpdateCoursesSelected(List());
    }
  }, [selectAllChecked]);

  const handleOnClick = () => (updateSelectAllChecked(!selectAllChecked));

  const loadingDone = sites.reduce(
    (acc, siteData) => acc && siteData.has('teacherCourses'),
    true,
  );

  if (hasCoursesForMigration?.size) {
    return (
      <div className="course-migration">
        <div className="course-migration-checklist">
          <h1>Select the courses you wish to migrate to iCEV</h1>
          {sites.size > 0
            ? (
              <>
                <Checkbox
                  id="selectAll"
                  name="selectAllCheckbox"
                  label="Select all outstanding courses"
                  checked={selectAllChecked}
                  onChange={handleOnClick}
                />
                <MigrateSiteList
                  sites={sites}
                  key={sites.get('name')}
                  allSelected={selectAllChecked}
                />
              </>
            )
            : null}
          <div className="button-section">
            <button
              type="button"
              className="primary"
              disabled={disableButton}
              onClick={doMigrate}
            >
              Migrate my Courses
            </button>
            <button type="button" onClick={dispatch.logout}>Cancel</button>
          </div>
        </div>
      </div>
    );
  }

  if (loadingDone) { return <MigrationUnavailable />; }

  return <LoadingSection />;
};

MigrateCourses.propTypes = {
  sites: PropTypes.instanceOf(Map).isRequired,
  migrationCoursesSelected: PropTypes.instanceOf(List).isRequired,
  dispatch: PropTypes.shape({
    logout: PropTypes.func.isRequired,
    requestMigration: PropTypes.func.isRequired,
  }).isRequired,
  dispatchUpdateCoursesSelected: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const sites = getSites(state) || Map();
  const { view } = state;
  const migrationCoursesSelected = view.get('migrationCoursesSelected') || List();

  return {
    sites,
    migrationCoursesSelected,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch: {
    logout: () => dispatch(logout()),
    requestMigration: () => dispatch(requestMigration()),
  },
  dispatchUpdateCoursesSelected: (selectedIds) => (
    dispatch(updateMigrationCoursesSelected(selectedIds))
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(MigrateCourses);
